import get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import { actionTypes as types } from './constants';

const initialState = {
    orders: [],
    error: null,
    loading: true,
    errorUpdate: null,
    successUpdate: null,
    loadingUpdate: false,
};

export default (state = initialState, action) => {
    const updatedState = _cloneDeep(state);

    switch (action.type) {
        case types.GET_SQUEEZE_BOTTLE_ORDERS_START:
            updatedState.loading = true;
            break;
        case types.GET_SQUEEZE_BOTTLE_ORDERS_SUCCESS:
            updatedState.loading = false;
            updatedState.orders = get(action.payload.data, 'data', []);
            break;
        case types.GET_SQUEEZE_BOTTLE_ORDERS_FAILED:
            updatedState.loading = false;
            updatedState.error = action.error;
            break;
        case types.UPDATE_SQUEEZE_BOTTLE_ORDER_START:
            updatedState.loadingUpdate = true;
            break;
        case types.UPDATE_SQUEEZE_BOTTLE_ORDER_SUCCESS:
            updatedState.loadingUpdate = false;
            updatedState.errorUpdate = false;
            updatedState.successUpdate = true;
            updatedState.orders = updatedState.orders.map((order) => {
                if (order.id === action.payload.data.id) {
                    return action.payload.data;
                }
                return order;
            });
            console.log('updatedState.orders:');
            console.log(updatedState.orders);

            break;
        case types.UPDATE_SQUEEZE_BOTTLE_ORDER_FAILED:
            updatedState.loadingUpdate = false;
            updatedState.errorUpdate = action.error;
            break;
        case types.UPDATE_SQUEEZE_BOTTLE_ORDER_CLEAR:
            updatedState.successUpdate = false;
            break;

        default:
            break;
    }

    return updatedState;
};
