import React from 'react';
import { Route } from 'react-router';
import { get } from 'lodash';

import { WaitingComponent } from '../../../helpers/waitingComponent';
import lazy from '../../../helpers/lazy';
import AuthHelper from '../../../helpers/auth';

import './aramark.scss';

const AramarkSelector = lazy(() => import('./'));
const OrderSupplyResults = lazy(() => import('./order-supplies/Results'));
const ProfitCentersResults = lazy(() => import('./profit-centers/Results'));
const ProfitCenterDetails = lazy(() => import('./profit-centers/Details'));
const TrackDispenserOrdersResults = lazy(
    () => import('./track-dispenser-orders/Results')
);

const ADMIN_GROUP_ID = process.env.REACT_APP_ADMIN_GROUP_ID;
const ARAMARK_MANAGER_GROUP_ID = process.env.REACT_APP_ARAMARK_MANAGER_GROUP_ID;

const Router = ({ path }) => {
    const user = AuthHelper.getUser();
    const isAdmin =
        [ADMIN_GROUP_ID, ARAMARK_MANAGER_GROUP_ID].indexOf(
            get(user, 'group._id')
        ) > -1;

    return (
        <div className="aramark-page">
            <Route
                path={`${path}/aramark`}
                render={({ match: { url } }) => (
                    <>
                        {isAdmin && (
                            <>
                                <Route
                                    exact
                                    path={`${url}/`}
                                    component={WaitingComponent(
                                        AramarkSelector
                                    )}
                                />
                                <Route
                                    exact
                                    path={`${url}/profit-centers`}
                                    component={WaitingComponent(
                                        ProfitCentersResults
                                    )}
                                />
                                <Route
                                    exact
                                    key="profitCenterDetails"
                                    path={`${url}/profit-centers/:itemId`}
                                    component={WaitingComponent(
                                        ProfitCenterDetails
                                    )}
                                />
                                <Route
                                    exact
                                    path={`${url}/track-orders/dispenser-orders`}
                                    component={WaitingComponent(
                                        TrackDispenserOrdersResults
                                    )}
                                />
                            </>
                        )}
                        {/* public route */}
                        <Route
                            exact
                            path={`${url}/order-supplies/:supplyType`}
                            component={WaitingComponent(OrderSupplyResults)}
                        />
                    </>
                )}
            />
        </div>
    );
};

export default Router;
