import React from 'react';
import { Route } from 'react-router';
import { get } from 'lodash';

import { WaitingComponent } from '../../../helpers/waitingComponent';
import lazy from '../../../helpers/lazy';
import AuthHelper from '../../../helpers/auth';

const DashboardSelector = lazy(() => import('./'));
const DocumentsResults = lazy(() => import('./documents/Results'));
const CollectionsDetails = lazy(() => import('./collections/Details'));
const OrderSupplyResults = lazy(() => import('./order-supplies/Results'));
const AddressBookResults = lazy(() => import('./address-book/Results'));
const UsersResults = lazy(() => import('./users/Results'));
const UserDetails = lazy(() => import('./users/Details'));
const RecipesBuilderResults = lazy(
    () => import('./recipes-builder/recipes/Results')
);
const RecipesBuilderDetails = lazy(
    () => import('./recipes-builder/recipes/Details')
);
const RecipesBuilderCustomersResults = lazy(
    () => import('./recipes-builder/customers/Results')
);
const RecipesBuilderCustomerDetails = lazy(
    () => import('./recipes-builder/customers/Details')
);
const TrackDispenserOrdersResults = lazy(
    () => import('./track-dispenser-orders/Results')
);

const TrackSqueezeBottleOrdersResults = lazy(
    () => import('./track-squeeze-bottle-orders/Results')
);

const ADMIN_GROUP_ID = process.env.REACT_APP_ADMIN_GROUP_ID;
const KFS_SUPPORT_GROUP_ID = process.env.REACT_APP_KFS_SUPPORT_GROUP_ID;
const SALES_MANAGERS_GROUP_ID = process.env.REACT_APP_SALES_MANAGERS_GROUP_ID;

const Router = ({ path }) => {
    const user = AuthHelper.getUser();
    const isAdmin =
        get(user, 'group._id') === ADMIN_GROUP_ID ||
        get(user, 'group._id') === KFS_SUPPORT_GROUP_ID;
    const isSales =
        get(user, 'group._id') === ADMIN_GROUP_ID ||
        get(user, 'group._id') === SALES_MANAGERS_GROUP_ID;

    return (
        <Route
            path={`${path}/dashboard`}
            render={({ match: { url } }) => (
                <>
                    <Route
                        exact
                        path={`${url}/`}
                        component={WaitingComponent(DashboardSelector)}
                    />
                    <Route
                        exact
                        path={`${url}/:type(order-supplies|recipes-builder)`}
                        component={WaitingComponent(DashboardSelector)}
                    />
                    <Route
                        exact
                        path={`${url}/documents`}
                        component={WaitingComponent(DocumentsResults)}
                    />
                    <Route
                        exact
                        path={`${url}/collections/:collectionId`}
                        component={WaitingComponent(CollectionsDetails)}
                    />
                    <Route
                        exact
                        path={`${url}/collections/:collectionId/edit`}
                        component={WaitingComponent(CollectionsDetails)}
                    />
                    {user && (
                        <Route
                            exact
                            path={`${url}/order-supplies/:supplyType`}
                            component={WaitingComponent(OrderSupplyResults)}
                        />
                    )}
                    {user && (
                        <Route
                            exact
                            path={`${url}/address-book`}
                            component={WaitingComponent(AddressBookResults)}
                        />
                    )}
                    {isAdmin && (
                        <Route
                            exact
                            key="users"
                            path={`${url}/users`}
                            component={WaitingComponent(UsersResults)}
                        />
                    )}
                    {isAdmin && (
                        <Route
                            exact
                            key="userDetails"
                            path={`${url}/users/:userId`}
                            component={WaitingComponent(UserDetails)}
                        />
                    )}
                    {user && (
                        <Route
                            exact
                            key="trackDispenserOrders"
                            path={`${url}/track-dispenser-orders`}
                            component={WaitingComponent(
                                TrackDispenserOrdersResults
                            )}
                        />
                    )}
                    {user && (
                        <Route
                            exact
                            key="trackSqueezeBottleOrders"
                            path={`${url}/track-squeeze-bottle-orders`}
                            component={WaitingComponent(
                                TrackSqueezeBottleOrdersResults
                            )}
                        />
                    )}
                    {isSales && (
                        <Route
                            exact
                            key="recipesBuilder"
                            path={`${url}/recipes-builder/recipes`}
                            component={WaitingComponent(RecipesBuilderResults)}
                        />
                    )}
                    {isSales && (
                        <Route
                            exact
                            key="recipesBuilderDetails"
                            path={`${url}/recipes-builder/recipes/:itemId`}
                            component={WaitingComponent(RecipesBuilderDetails)}
                        />
                    )}
                    {isSales && (
                        <Route
                            exact
                            key="recipesBuilderCustomers"
                            path={`${url}/recipes-builder/customers`}
                            component={WaitingComponent(
                                RecipesBuilderCustomersResults
                            )}
                        />
                    )}
                    {isSales && (
                        <Route
                            exact
                            key="recipesBuilderCustomersDetails"
                            path={`${url}/recipes-builder/customers/:itemId`}
                            component={WaitingComponent(
                                RecipesBuilderCustomerDetails
                            )}
                        />
                    )}
                </>
            )}
        />
    );
};

export default Router;
