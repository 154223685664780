import React, { memo, useMemo, useCallback } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { logout, toggleLoginModal } from '../login/actions';
import { cleanCart } from '../cart/actions';
import AuthHelper from '../../helpers/auth';

import './navigation.scss';

const Navigation = ({
    user,
    logout,
    toggleLoginModal,
    cleanCart,
    closeCallback,
}) => {
    const history = useHistory();

    const uname = useMemo(
        () => (user && user.fullName ? user.fullName.split(' ')[0] : ''),
        [user]
    );

    const handleLoginClick = useCallback((e) => {
        e.preventDefault();
        toggleLoginModal();
    }, []);

    const handleLogoutClick = useCallback((e) => {
        e.preventDefault();
        cleanCart();
        logout();
        history.push('/');
        AuthHelper.removeUser();
    }, []);

    return (
        <nav>
            <ul onClick={closeCallback}>
                {!user && (
                    <li>
                        <NavLink to="#" onClick={handleLoginClick}>
                            Sign in
                        </NavLink>
                    </li>
                )}
                {user && (
                    <>
                        <li className="unactive">
                            <p>Hi, {uname}!</p>
                        </li>
                        <li>
                            <NavLink to="#" onClick={handleLogoutClick}>
                                Logout
                            </NavLink>
                        </li>
                    </>
                )}
            </ul>
        </nav>
    );
};

const mapStateToProps = (state) => ({
    user: state.login.user,
});

export default connect(mapStateToProps, {
    logout,
    cleanCart,
    toggleLoginModal,
})(memo(Navigation));
