import { isEmpty, cloneDeep, get } from 'lodash';
import {
    actionTypes as types,
    defaultFilters,
    defaultLeadFilters,
} from './constants';

const initialState = {
    data: {},
    error: null,
    loading: false,
    isVisible: false,
    productsError: false,
    productsLoading: false,
    disabledBrandNameFilter: false,
    hideBrandNameSelections: false,
    selected: { ...defaultFilters },
    leadData: {},
    selectedLeadFilter: cloneDeep(defaultLeadFilters),
    managersLoading: false,
    managers: [],
};

export default (state = initialState, action) => {
    const updatedState = { ...state };

    switch (action.type) {
        case types.GET_LOOKUP_DATA_START:
            updatedState.error = null;
            updatedState.loading = true;
            break;
        case types.GET_LOOKUP_DATA_SUCCESS:
            updatedState.loading = false;
            updatedState.data = action.payload;
            break;
        case types.GET_LOOKUP_DATA_FAILED:
            updatedState.error = action.error;
            updatedState.loading = false;
            break;
        case types.GET_LEAD_LOOKUP_DATA_START:
            updatedState.error = null;
            updatedState.loading = true;
            break;
        case types.GET_LEAD_LOOKUP_DATA_SUCCESS:
            updatedState.loading = false;
            updatedState.leadData = action.payload;
            break;
        case types.GET_LEAD_LOOKUP_DATA_FAILED:
            updatedState.error = action.error;
            updatedState.loading = false;
            break;
        case types.GET_PRODUCTS_START:
            updatedState.productsError = null;
            updatedState.productsLoading = true;
            break;
        case types.GET_PRODUCTS_SUCCESS:
            updatedState.productsLoading = false;
            updatedState.data = {
                ...updatedState.data,
                ...{ products: action.payload.data },
            };
            break;
        case types.GET_PRODUCTS_FAILED:
            updatedState.productsError = action.error;
            updatedState.productsLoading = false;
            break;
        case types.SET_SELECTED_FILTERS:
            const { filters } = action.payload;

            const updatedFilters = { ...updatedState.selected };
            Object.keys(filters).forEach((key) => {
                switch (key) {
                    case 'brandName':
                        if (isEmpty(filters[key])) {
                            delete updatedFilters.filters.Brand;
                            delete updatedFilters.assetFilters.brandName;
                        } else {
                            updatedFilters.filters.Brand = {
                                brandName: filters[key],
                                'identifiers.brandName': filters[key],
                            };
                            updatedFilters.assetFilters.brandName =
                                filters[key];
                        }
                        break;
                    case 'packaging':
                        if (isEmpty(filters[key])) {
                            delete updatedFilters.filters.type;
                            delete updatedFilters.assetFilters.packaging;
                        } else {
                            updatedFilters.filters.type = filters[key];
                            updatedFilters.assetFilters.packaging =
                                filters[key];
                        }
                        break;
                    case 'flavors':
                        if (isEmpty(filters[key])) {
                            delete updatedFilters.filters['Flavor Category'];
                            delete updatedFilters.assetFilters.flavors;
                        } else {
                            updatedFilters.filters['Flavor Category'] = {
                                flavors: filters[key],
                                'identifiers.flavors': filters[key],
                            };
                            updatedFilters.assetFilters.flavors = filters[key];
                        }
                        break;
                    case 'marketingClaims':
                        if (isEmpty(filters[key])) {
                            for (var filterKey in updatedFilters.filters) {
                                if (filterKey.match(/^claims/g)) {
                                    delete updatedFilters.filters[filterKey];
                                }
                            }

                            delete updatedFilters.assetFilters.marketingClaims;
                        } else {
                            const [claimsByKey, claimsByName] = [{}, []];

                            for (var claim of filters[key]) {
                                if (claim) {
                                    const [filterKey, name, value] =
                                        claim.split('::');
                                    claimsByName.push(name);
                                    claimsByKey[
                                        `claims.marketing.${filterKey}`
                                    ] = value;
                                }
                            }

                            updatedFilters.assetFilters.marketingClaims =
                                claimsByName;
                            updatedFilters.filters = Object.assign(
                                {},
                                updatedFilters.filters,
                                claimsByKey
                            );
                        }
                        break;
                    case 'productLine':
                        if (isEmpty(filters[key])) {
                            delete updatedFilters.assetFilters.productLine;
                            delete updatedFilters.filters[
                                'identifiers.productLine'
                            ];
                        } else {
                            updatedFilters.assetFilters.productLine =
                                filters[key];
                            updatedFilters.filters['identifiers.productLine'] =
                                filters[key];
                        }
                        break;
                    case 'products':
                        if (isEmpty(filters[key])) {
                            delete updatedFilters.assetFilters.products;
                            delete updatedFilters.filters['Product'];
                        } else {
                            updatedFilters.assetFilters.products = filters[key];
                            updatedFilters.filters['Product'] = {
                                _id: filters[key],
                                relatedProducts: filters[key],
                            };

                            // search on plus ones as well
                            updatedFilters.filters[
                                'Product'
                            ].plusOneRelatedProducts =
                                filters.payloadFull.products.map((p) => p.name);
                        }
                        break;
                    case 'productStorage':
                        if (isEmpty(filters[key])) {
                            delete updatedFilters.assetFilters.productStorage;
                            delete updatedFilters.filters['Storage'];
                        } else {
                            const payload = [
                                ...get(
                                    updatedFilters.filters,
                                    'Storage.storage.storage',
                                    []
                                ),
                                ...filters[key],
                            ];
                            updatedFilters.assetFilters.productStorage =
                                payload;
                            updatedFilters.filters['Storage'] = {
                                'storage.storage': payload,
                            };
                        }
                        break;
                    default:
                        break;
                }
            });
            updatedState.selected = updatedFilters;

            break;
        case types.CLEAR_SELECTED_FILTERS:
            const {
                defaultBrands = [],
                defaultSalesPortalStatus = ['Active'],
                isMovingToAnotherApp,
            } = action.payload || {};
            const preparedFilters = { assetFilters: {}, filters: {} };
            if (!isEmpty(defaultSalesPortalStatus)) {
                preparedFilters.assetFilters.salesPortalStatus =
                    defaultSalesPortalStatus;
            }
            if (!isEmpty(defaultBrands)) {
                preparedFilters.assetFilters.brandName = defaultBrands;
                preparedFilters.filters.Brand = {
                    brandName: defaultBrands,
                    'identifiers.brandName': defaultBrands,
                };
            }
            updatedState.selected = preparedFilters;
            // TODO: temp functionality, need to hide brand from filter selection when go to another app
            const { data, loading, error } = updatedState;
            const isSelectionsVisible =
                !!Object.keys(data).length && !loading && !error;
            if (isSelectionsVisible && isMovingToAnotherApp) {
                updatedState.hideBrandNameSelections = true;
            }
            break;

        case types.SET_SELECTED_LEAD_FILTERS:
            const { leadFilters } = action.payload;
            const updatedLeadFilters = { ...updatedState.selectedLeadFilter };
            Object.keys(leadFilters).forEach((key) => {
                switch (key) {
                    case 'brandName':
                        if (isEmpty(leadFilters[key])) {
                            delete updatedLeadFilters.assetFilters['brandName'];
                            Object.keys(updatedLeadFilters.filters).forEach(
                                (key) => {
                                    if (key.indexOf('website.') > -1)
                                        delete updatedLeadFilters.filters[key];
                                }
                            );
                        } else {
                            updatedLeadFilters.assetFilters.brandName =
                                leadFilters[key];

                            Object.keys(updatedLeadFilters.filters).forEach(
                                (key) => {
                                    if (key.indexOf('website.') > -1)
                                        delete updatedLeadFilters.filters[key];
                                }
                            );

                            updatedLeadFilters.filters[
                                `website.${leadFilters[key]}`
                            ] = 'true';
                        }
                        break;
                    case 'phase':
                        if (isEmpty(leadFilters[key])) {
                            delete updatedLeadFilters.assetFilters['phase'];
                            delete updatedLeadFilters.filters['phaseStatus'];
                        } else {
                            updatedLeadFilters.assetFilters.phase =
                                leadFilters[key];
                            updatedLeadFilters.filters.phaseStatus =
                                leadFilters[key];
                        }
                        break;
                    case 'salesManager':
                        if (isEmpty(leadFilters[key])) {
                            delete updatedLeadFilters.assetFilters[
                                'salesManager'
                            ];
                            delete updatedLeadFilters.filters[
                                'salesManager.salesManagerId'
                            ];
                        } else {
                            updatedLeadFilters.assetFilters.salesManager =
                                leadFilters[key];
                            updatedLeadFilters.filters[
                                'salesManager.salesManagerId'
                            ] = leadFilters[key];
                        }
                        break;
                    case 'validInvalid':
                        if (isEmpty(leadFilters[key])) {
                            delete updatedLeadFilters.assetFilters[
                                'validInvalid'
                            ];
                            delete updatedLeadFilters.filters[
                                'phase4.soldInfo.account'
                            ];

                            delete updatedLeadFilters.filters[
                                'phase2.validFoodserviceOperator'
                            ];
                        } else {
                            updatedLeadFilters.assetFilters.validInvalid =
                                leadFilters[key];

                            if (
                                leadFilters[key] &&
                                leadFilters[key].length > 0 &&
                                leadFilters[key][0] === 'Invalid'
                            ) {
                                updatedLeadFilters.filters['phase4.comments'] =
                                    '';
                            } else {
                                delete updatedLeadFilters.assetFilters[
                                    'validInvalid'
                                ];
                            }
                        }
                        break;

                    case 'startDate':
                        if (!leadFilters[key]) {
                            delete updatedLeadFilters.assetFilters['startDate'];
                            delete updatedLeadFilters.filters['startDate'];
                        } else {
                            updatedLeadFilters.assetFilters['startDate'] =
                                leadFilters[key];

                            updatedLeadFilters.filters['startDate'] =
                                leadFilters[key];
                        }
                        break;

                    case 'endDate':
                        if (!leadFilters[key]) {
                            delete updatedLeadFilters.assetFilters['endDate'];
                            delete updatedLeadFilters.filters['endDate'];
                        } else {
                            updatedLeadFilters.assetFilters['endDate'] =
                                leadFilters[key];

                            updatedLeadFilters.filters['endDate'] =
                                leadFilters[key];
                        }
                        break;

                    default:
                        break;
                }
            });
            updatedState.selectedLeadFilter = updatedLeadFilters;

            break;
        case types.CLEAR_SELECTED_LEAD_FILTERS:
            const preparedLeadFilters = { assetFilters: {}, filters: {} };
            updatedState.selectedLeadFilter = preparedLeadFilters;
            break;

        case types.SET_DISABLE_BRAND_NAME:
            const { disabled } = action.payload;
            updatedState.disabledBrandNameFilter = disabled;
            updatedState.hideBrandNameSelections = disabled;
            break;
        default:
            break;
    }

    return updatedState;
};
