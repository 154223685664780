import { takeLatest, put, call } from 'redux-saga/effects';

import ApiClient from '../../../../../../helpers/api';
import { APP_PATH_KEY } from '../../config';
import { actionTypes as types } from './constants';

const getItems = function* (action) {
    try {
        const { user } = action.payload;

        const data = yield call(
            [ApiClient, ApiClient.post],
            `/api/${APP_PATH_KEY}/customers`,
            { user }
        );
        yield put({
            type: types.GET_RECIPES_BUILDERS_CUSTOMERS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        console.error('[getItems] error: ', error);
        yield put({ type: types.GET_RECIPES_BUILDERS_CUSTOMERS_FAILED, error });
    }
};

const getItem = function* (action) {
    try {
        const { itemId, user } = action.payload;
        const data = yield call(
            [ApiClient, ApiClient.post],
            `/api/${APP_PATH_KEY}/customers/${itemId}`,
            { user }
        );
        yield put({
            type: types.GET_RECIPES_BUILDERS_CUSTOMER_SUCCESS,
            payload: data,
        });
    } catch (error) {
        console.error('[getItem] error: ', error);
        yield put({ type: types.GET_RECIPES_BUILDERS_CUSTOMER_FAILED, error });
    }
};

const updateItem = function* (action) {
    try {
        const { itemId, fields } = action.payload;

        const apiResponse = yield call(
            [ApiClient, ApiClient.putFile],
            `/api/${APP_PATH_KEY}/customers/${itemId}`,
            fields
        );

        yield put({
            type: types.UPDATE_RECIPES_BUILDERS_CUSTOMER_SUCCESS,
            payload: apiResponse,
        });
    } catch (error) {
        console.error('[updateItem] error: ', error);
        yield put({
            type: types.UPDATE_RECIPES_BUILDERS_CUSTOMER_FAILED,
            error,
        });
    }
};

const removeItem = function* (action) {
    try {
        const { itemId } = action.payload;

        const data = yield call(
            [ApiClient, ApiClient.delete],
            `/api/${APP_PATH_KEY}/customers/${itemId}`
        );
        yield put({
            type: types.REMOVE_RECIPES_BUILDERS_CUSTOMER_SUCCESS,
            payload: { ...data, itemId },
        });
    } catch (error) {
        console.error('[removeItem] error: ', error);
        yield put({
            type: types.REMOVE_RECIPES_BUILDERS_CUSTOMER_FAILED,
            error,
        });
    }
};

export default [
    takeLatest(types.GET_RECIPES_BUILDERS_CUSTOMERS_START, getItems),
    takeLatest(types.GET_RECIPES_BUILDERS_CUSTOMER_START, getItem),
    takeLatest(types.UPDATE_RECIPES_BUILDERS_CUSTOMER_START, updateItem),
    takeLatest(types.REMOVE_RECIPES_BUILDERS_CUSTOMER_START, removeItem),
];
