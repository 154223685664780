import { takeLatest, put, call } from 'redux-saga/effects';

import ApiClient from '../../../../helpers/api';
import { actionTypes as types } from './constants';

const getDispenserOrders = function* (action) {
    try {
        const data = yield call(
            [ApiClient, ApiClient.post],
            '/api/dispenser-orders',
            {
                ...action.payload,
            }
        );

        yield put({
            type: types.GET_DISPENSER_ORDERS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        console.error('[getDispenserOrders] error: ', error);
        yield put({ type: types.GET_DISPENSER_ORDERS_FAILED, error });
    }
};

const updateDispenserOrder = function* (action) {
    try {
        const data = action.payload;

        const apiData = yield call(
            [ApiClient, ApiClient.put],
            `/api/dispenser-orders`,
            {
                ...data,
            }
        );
        yield put({
            type: types.UPDATE_DISPENSER_ORDER_SUCCESS,
            payload: apiData,
        });
    } catch (error) {
        console.error('[updateDispenserOrder] error: ', error);
        yield put({ type: types.UPDATE_DISPENSER_ORDER_FAILED, error });
    }
};

export default [
    takeLatest(types.GET_DISPENSER_ORDERS_START, getDispenserOrders),
    takeLatest(types.UPDATE_DISPENSER_ORDER_START, updateDispenserOrder),
];
