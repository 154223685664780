import React from 'react';
import { Route } from 'react-router';
import { get } from 'lodash';

import { WaitingComponent } from '../../../helpers/waitingComponent';

import AuthHelper from '../../../helpers/auth';
import lazy from '../../../helpers/lazy';
import ErrorBoundary from '../../../components/error-boundary/ErrorBoundary';

import './customer-complaint.scss';

const CustomerComplaintSelector = lazy(() => import('./'));
const FormPage = lazy(() => import('./Form'));

const ADMIN_GROUP_ID = process.env.REACT_APP_ADMIN_GROUP_ID;

const Router = ({ path }) => {
    const user = AuthHelper.getUser();
    const isAdmin = [ADMIN_GROUP_ID].indexOf(get(user, 'group._id')) > -1;

    return (
        <div className="customer-complaint-page">
            <ErrorBoundary context="Customer Complaint">
                <Route
                    path={`${path}/customer-complaint`}
                    render={({ match: { url } }) => (
                        <>
                            {isAdmin && (
                                <Route
                                    exact
                                    path={`${url}/`}
                                    component={WaitingComponent(
                                        CustomerComplaintSelector
                                    )}
                                />
                            )}
                            {/* public route */}
                            <Route
                                exact
                                path={`${url}/form`}
                                component={WaitingComponent(FormPage)}
                            />
                        </>
                    )}
                />
            </ErrorBoundary>
        </div>
    );
};

export default Router;
