import _Clone from 'lodash/clone';
import { actionTypes as types } from './constants';

const initialState = {
    user: null,
    error: null,
    loading: false,
    showModal: false,
    forgotError: null,
    forgotLoading: false,
    forgotSuccess: false,
    summary: [],
    loadingSummary: false,
};

export default (state = initialState, action) => {
    const updatedState = _Clone(state);

    switch (action.type) {
        case types.LOGIN_START:
            updatedState.loading = true;
            break;
        case types.LOGIN_SUCCESS:
            updatedState.error = null;
            updatedState.loading = false;
            updatedState.user = action.payload.data;
            break;
        case types.LOGIN_FAILED:
            updatedState.loading = false;
            updatedState.error =
                action.error &&
                action.error.error &&
                action.error.error.message;
            break;
        case types.LOGOUT:
            updatedState.user = null;
            break;
        case types.LOGIN_TOGGLE_MODAL:
            updatedState.showModal = !updatedState.showModal;
            break;
        case types.SET_USER:
            updatedState.user = action.payload;
            break;
        case types.CLEAR_ERRORS:
            updatedState.error = null;
            break;
        case types.FORGOT_START:
            updatedState.forgotLoading = true;
            updatedState.forgotSuccess = false;
            break;
        case types.FORGOT_SUCCESS:
            updatedState.forgotLoading = false;
            updatedState.forgotSuccess = true;
            break;
        case types.FORGOT_FAILED:
            updatedState.forgotLoading = false;
            updatedState.forgotError =
                action.error &&
                action.error.error &&
                action.error.error.message;
            break;
        case types.FORGOT_CLEAR_SUCCESS:
            updatedState.forgotSuccess = false;
            break;
        case types.FORGOT_CLEAR_ERRORS:
            updatedState.forgotError = null;
            break;
        case types.SUMMARY_START:
            updatedState.loadingSummary = true;
            break;
        case types.SUMMARY_FAILED:
            updatedState.loadingSummary = false;
            break;
        case types.SUMMARY_SUCCESS:
            updatedState.loadingSummary = false;
            if (action.payload.data) {
                updatedState.summary = action.payload.data;
            } else {
                updatedState.summary = [];
            }
            break;

        default:
            break;
    }

    return updatedState;
};
