export const DATE_MIN_DATE = '2022-05-01';

export const BRAND = {
    kens: 'kens',
    sbr: 'sbr',
    kogi: 'kogi',
    sales: 'sales',
};

export const BRAND_LABEL = {
    [BRAND.kens]: "Ken's",
    [BRAND.sbr]: 'SBR',
    [BRAND.kogi]: 'Kogi',
    [BRAND.sales]: 'Sales Portal',
};

export const REPORT = {
    // user reports
    brand: 'brand',
    brandDate: 'brandDate',
    brandMonth: 'brandMonth',
    traffic: 'traffic',
    trafficMonth: 'trafficMonth',
    campaign: 'campaign',
    state: 'state',
    stateMonth: 'stateMonth',
    city: 'city',
    cityMonth: 'cityMonth',
    // page reports
    firstLandingPage: 'firstLandingPage',
    landingPage: 'landingPage',
    productPage: 'productPage',
    recipePage: 'recipePage',
    plusOnePage: 'plusOnePage',
    // event reports
    sampleEvent: 'sampleEvent',
    sampleEventDate: 'sampleEventDate',
    contactEvent: 'contactEvent',
    contactEventDate: 'contactEventDate',
    // exploration reports
    sampleRequestId: 'sampleRequestId',
};

export const BRAND_LINKS_MAP = {
    [BRAND.kens]: process.env.REACT_APP_PUBLIC_KENS_URL,
    [BRAND.sbr]: process.env.REACT_APP_PUBLIC_SBR_URL,
    [BRAND.kogi]: process.env.REACT_APP_PUBLIC_KOGI_URL,
};

export const PAGES = {
    core: 'core',
    page: 'page',
    paid: 'paid',
};

export const DEFAULT_HELP_INFO = [
    {
        text: 'All data is from GA4 (does not support Universal Analytics).',
    },
];
