import { takeLatest, put, call, select } from 'redux-saga/effects';
import get from 'lodash/get';
import ApiClient from '../../helpers/api';
import { actionTypes as types } from './constants';

const loadCollections = function* (action) {
    try {
        const user = yield select((state) => state.login.user);
        const apiData = yield call(
            [ApiClient, ApiClient.post],
            '/api/collections',
            { userGroup: get(user, ['group', '_id']) }
        );
        yield put({ type: types.GET_COLLECTIONS_SUCCESS, payload: apiData });
    } catch (error) {
        console.error('[loadCollections] error: ', error);
        yield put({ type: types.GET_COLLECTIONS_FAILED, error });
    }
};

const createCollection = function* (action) {
    try {
        const user = yield select((state) => state.login.user);
        const apiData = yield call(
            [ApiClient, ApiClient.post],
            '/api/collection',
            {
                ...action.payload,
                type: 'create',
                userGroup: get(user, ['group', '_id']),
            }
        );
        yield put({
            type: types.CREATE_SINGLE_COLLECTION_SUCCESS,
            payload: apiData,
        });
    } catch (error) {
        console.error('[createCollection] error: ', error);
        yield put({ type: types.CREATE_SINGLE_COLLECTION_FAILED, error });
    }
    yield put({ type: types.CLEAR_SINGLE_COLLECTION });
};

const updateCollection = function* (action) {
    try {
        const user = yield select((state) => state.login.user);
        const apiData = yield call(
            [ApiClient, ApiClient.post],
            '/api/collection',
            {
                ...action.payload,
                type: 'update',
                userGroup: get(user, ['group', '_id']),
            }
        );
        yield put({
            type: types.UPDATE_SINGLE_COLLECTION_SUCCESS,
            payload: apiData,
        });
    } catch (error) {
        console.error('[updateCollection] error: ', error);
        yield put({ type: types.UPDATE_SINGLE_COLLECTION_FAILED, error });
    }
    yield put({ type: types.CLEAR_SINGLE_COLLECTION });
};

const removeCollection = function* (action) {
    try {
        const apiData = yield call(
            [ApiClient, ApiClient.post],
            '/api/collection',
            {
                ...action.payload,
                type: 'remove',
            }
        );
        yield put({
            type: types.REMOVE_SINGLE_COLLECTION_SUCCESS,
            payload: { ...apiData, ...action.payload },
        });
    } catch (error) {
        console.error('[removeCollection] error: ', error);
        yield put({ type: types.REMOVE_SINGLE_COLLECTION_FAILED, error });
    }
};

const fetchAudienceSagas = function* (action) {
    try {
        const apiData = yield call([ApiClient, ApiClient.get], '/api/audience');
        yield put({
            type: types.GET_AUDIENCE_SUCCESS,
            payload: apiData,
        });
    } catch (error) {
        console.error('[fetchAudienceSagas] error: ', error);
        yield put({ type: types.GET_AUDIENCE_FAILED, error });
    }
};

export default [
    takeLatest(types.GET_COLLECTIONS_START, loadCollections),
    takeLatest(types.CREATE_SINGLE_COLLECTION_START, createCollection),
    takeLatest(types.UPDATE_SINGLE_COLLECTION_START, updateCollection),
    takeLatest(types.REMOVE_SINGLE_COLLECTION_START, removeCollection),
    takeLatest(types.GET_AUDIENCE_START, fetchAudienceSagas),
];
