import { combineReducers } from 'redux';

import { CLIENTS } from '../constants';
import kensMetricsReducer from '../clients/kens/store/reducer';
import salesMetricsReducer from '../clients/sales/store/reducer';

export default combineReducers({
    [CLIENTS.kens]: kensMetricsReducer,
    [CLIENTS.sales]: salesMetricsReducer,
});
