export const actionTypes = {
    GET_PRODUCTS_START: 'GET_PRODUCTS_START',
    GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
    GET_PRODUCTS_FAILED: 'GET_PRODUCTS_FAILED',

    GET_SALES_LEADS_START: 'GET_SALES_LEADS_START',
    GET_SALES_LEADS_SUCCESS: 'GET_SALES_LEADS_SUCCESS',
    GET_SALES_LEADS_FAILED: 'GET_SALES_LEADS_FAILED',

    GET_SALES_LEADS_HISTORY_START: 'GET_SALES_LEADS_HISTORY_START',
    GET_SALES_LEADS_HISTORY_SUCCESS: 'GET_SALES_LEADS_HISTORY_SUCCESS',
    GET_SALES_LEADS_HISTORY_FAILED: 'GET_SALES_LEADS_HISTORY_FAILED',

    GET_SALES_LEADS_INFLOW_START: 'GET_SALES_LEADS_INFLOW_START',
    GET_SALES_LEADS_INFLOW_SUCCESS: 'GET_SALES_LEADS_INFLOW_SUCCESS',
    GET_SALES_LEADS_INFLOW_FAILED: 'GET_SALES_LEADS_INFLOW_FAILED',

    GET_ARCHIVED_SALES_LEADS_START: 'GET_ARCHIVED_SALES_LEADS_START',
    GET_ARCHIVED_SALES_LEADS_SUCCESS: 'GET_ARCHIVED_SALES_LEADS_SUCCESS',
    GET_ARCHIVED_SALES_LEADS_FAILED: 'GET_ARCHIVED_SALES_LEADS_FAILED',

    GET_SALES_LEAD_START: 'GET_SALES_LEAD_START',
    GET_SALES_LEAD_SUCCESS: 'GET_SALES_LEAD_SUCCESS',
    GET_SALES_LEAD_FAILED: 'GET_SALES_LEAD_FAILED',

    UPDATE_SALES_LEAD_START: 'UPDATE_SALES_LEAD_START',
    UPDATE_SALES_LEAD_SUCCESS: 'UPDATE_SALES_LEAD_SUCCESS',
    UPDATE_SALES_LEAD_FAILED: 'UPDATE_SALES_LEAD_FAILED',
    UPDATE_SALES_LEAD_CLEAR: 'UPDATE_SALES_LEAD_CLEAR',

    GET_MANAGERS_START: 'GET_MANAGERS_START',
    GET_MANAGERS_SUCCESS: 'GET_MANAGERS_SUCCESS',
    GET_MANAGERS_FAILED: 'GET_MANAGERS_FAILED',

    ARCHIVE_SALES_LEAD_START: 'ARCHIVE_SALES_LEAD_START',
    ARCHIVE_SALES_LEAD_SUCCESS: 'ARCHIVE_SALES_LEAD_SUCCESS',
    ARCHIVE_SALES_LEAD_FAILED: 'ARCHIVE_SALES_LEAD_FAILED',
    ARCHIVE_SALES_LEAD_CLEAR: 'ARCHIVE_SALES_LEAD_CLEAR',

    UPDATE_ARCHIVE_SALES_LEAD_START: 'UPDATE_ARCHIVE_SALES_LEAD_START',
    UPDATE_ARCHIVE_SALES_LEAD_SUCCESS: 'UPDATE_ARCHIVE_SALES_LEAD_SUCCESS',
    UPDATE_ARCHIVE_SALES_LEAD_FAILED: 'UPDATE_ARCHIVE_SALES_LEAD_FAILED',

    SEND_LEAD_START: 'SEND_LEAD_START',
    SEND_LEAD_SUCCESS: 'SEND_LEAD_SUCCESS',
    SEND_LEAD_FAILED: 'SEND_LEAD_FAILED',
    SEND_LEAD_CLEAR: 'SEND_LEAD_CLEAR',
};

export const PHASE_STATUSES = [
    {
        label: 'New Lead Pre-qualification',
        value: 'New Lead Pre-qualification',
    },
    {
        label: 'Phase 1 - Assigned Leads',
        value: 'Phase 1 - Assigned Leads',
    },
    {
        label: 'Phase 2A - Leads Assigned to Core',
        value: 'Phase 2A - Leads Assigned to Core',
    },
    {
        label: 'Phase 2B - Leads Waiting for Followup',
        value: 'Phase 2B - Leads Waiting for Followup',
    },
    {
        label: 'Phase 3 - Closed Out Leads',
        value: 'Phase 3 - Closed Out Leads',
    },
];
