export const actionTypes = {
    GET_COLLECTIONS_START: 'GET_COLLECTIONS_START',
    GET_COLLECTIONS_SUCCESS: 'GET_COLLECTIONS_SUCCESS',
    GET_COLLECTIONS_FAILED: 'GET_COLLECTIONS_FAILED',

    GET_SINGLE_COLLECTION_START: 'GET_SINGLE_COLLECTION_START',
    GET_SINGLE_COLLECTION_SUCCESS: 'GET_SINGLE_COLLECTION_SUCCESS',
    GET_SINGLE_COLLECTION_FAILED: 'GET_SINGLE_COLLECTION_FAILED',

    CREATE_SINGLE_COLLECTION_START: 'CREATE_SINGLE_COLLECTION_START',
    CREATE_SINGLE_COLLECTION_SUCCESS: 'CREATE_SINGLE_COLLECTION_SUCCESS',
    CREATE_SINGLE_COLLECTION_FAILED: 'CREATE_SINGLE_COLLECTION_FAILED',

    UPDATE_SINGLE_COLLECTION_START: 'UPDATE_SINGLE_COLLECTION_START',
    UPDATE_SINGLE_COLLECTION_SUCCESS: 'UPDATE_SINGLE_COLLECTION_SUCCESS',
    UPDATE_SINGLE_COLLECTION_FAILED: 'UPDATE_SINGLE_COLLECTION_FAILED',

    REMOVE_SINGLE_COLLECTION_START: 'REMOVE_SINGLE_COLLECTION_START',
    REMOVE_SINGLE_COLLECTION_SUCCESS: 'REMOVE_SINGLE_COLLECTION_SUCCESS',
    REMOVE_SINGLE_COLLECTION_FAILED: 'REMOVE_SINGLE_COLLECTION_FAILED',

    GET_AUDIENCE_START: 'GET_AUDIENCE_START',
    GET_AUDIENCE_SUCCESS: 'GET_AUDIENCE_SUCCESS',
    GET_AUDIENCE_FAILED: 'GET_AUDIENCE_FAILED',

    CLEAR_SINGLE_COLLECTION: 'CLEAR_SINGLE_COLLECTION',

    UPDATE_SINGLE_COLLECTION_CLEAR: 'UPDATE_SINGLE_COLLECTION_CLEAR',
};
