export const actionTypes = {
    GET_RECIPES_BUILDERS_CUSTOMERS_START:
        'GET_RECIPES_BUILDERS_CUSTOMERS_START',
    GET_RECIPES_BUILDERS_CUSTOMERS_SUCCESS:
        'GET_RECIPES_BUILDERS_CUSTOMERS_SUCCESS',
    GET_RECIPES_BUILDERS_CUSTOMERS_FAILED:
        'GET_RECIPES_BUILDERS_CUSTOMERS_FAILED',

    GET_RECIPES_BUILDERS_CUSTOMER_START: 'GET_RECIPES_BUILDERS_CUSTOMER_START',
    GET_RECIPES_BUILDERS_CLEAR_CUSTOMER_DETAILS_STATE:
        'GET_RECIPES_BUILDERS_CLEAR_CUSTOMER_DETAILS_STATE',
    GET_RECIPES_BUILDERS_CUSTOMER_SUCCESS:
        'GET_RECIPES_BUILDERS_CUSTOMER_SUCCESS',
    GET_RECIPES_BUILDERS_CUSTOMER_FAILED:
        'GET_RECIPES_BUILDERS_CUSTOMER_FAILED',

    UPDATE_RECIPES_BUILDERS_CUSTOMER_START:
        'UPDATE_RECIPES_BUILDERS_CUSTOMER_START',
    UPDATE_RECIPES_BUILDERS_CUSTOMER_SUCCESS:
        'UPDATE_RECIPES_BUILDERS_CUSTOMER_SUCCESS',
    UPDATE_RECIPES_BUILDERS_CUSTOMER_FAILED:
        'UPDATE_RECIPES_BUILDERS_CUSTOMER_FAILED',
    UPDATE_RECIPES_BUILDERS_CUSTOMER_CLEAR:
        'UPDATE_RECIPES_BUILDERS_CUSTOMER_CLEAR',

    REMOVE_RECIPES_BUILDERS_CUSTOMER_START:
        'REMOVE_RECIPES_BUILDERS_CUSTOMER_START',
    REMOVE_RECIPES_BUILDERS_CUSTOMER_SUCCESS:
        'REMOVE_RECIPES_BUILDERS_CUSTOMER_SUCCESS',
    REMOVE_RECIPES_BUILDERS_CUSTOMER_FAILED:
        'REMOVE_RECIPES_BUILDERS_CUSTOMER_FAILED',
    REMOVE_RECIPES_BUILDERS_CUSTOMER_CLEAR:
        'REMOVE_RECIPES_BUILDERS_CUSTOMER_CLEAR',
};
