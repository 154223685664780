import _cloneDeep from 'lodash/cloneDeep';

import { addReportsToStore } from '../../../helpers';
import { actionTypes as types } from './constants';
import { PAGES } from '../constants';

const basicPageState = {
    loading: false,
    error: null,
    filters: null,
};

const initialState = {
    [PAGES.core]: {
        ...basicPageState,
    },
    [PAGES.page]: {
        ...basicPageState,
    },
    [PAGES.paid]: {
        ...basicPageState,
    },
    onDemandState: {
        loading: {},
        error: {},
    },
    dataStore: [],
};

export default (state = initialState, action) => {
    const updatedState = _cloneDeep(state);

    const pageState = updatedState[action.page];

    switch (action.type) {
        case types.SET_ANALYTICS_KENS_FILTERS:
            pageState.filters = action.payload;

            break;

        case types.GET_ANALYTICS_KENS_METRICS_START:
            pageState.loading = true;
            pageState.error = null;

            break;

        case types.GET_ANALYTICS_KENS_METRICS_SUCCESS:
            pageState.loading = false;

            if (action.response && action.response.data) {
                updatedState.dataStore = addReportsToStore(
                    action.response.data,
                    action.filters,
                    updatedState.dataStore
                );
            }

            break;

        case types.GET_ANALYTICS_KENS_METRICS_FAILED:
            pageState.loading = false;
            pageState.error = action.payload;

            break;

        case types.GET_ANALYTICS_KENS_METRICS_CLEAR_ERROR:
            pageState.error = null;

            break;

        case types.GET_ANALYTICS_KENS_ONDMND_START:
            pageState.loading = true;
            updatedState.onDemandState.loading[action.key] = true;
            updatedState.onDemandState.error[action.key] = null;

            break;

        case types.GET_ANALYTICS_KENS_ONDMND_SUCCESS:
            pageState.loading = false;
            updatedState.onDemandState.loading[action.key] = false;

            if (action.response && action.response.data) {
                updatedState.dataStore = addReportsToStore(
                    action.response.data,
                    action.filters,
                    updatedState.dataStore
                );
            }

            break;

        case types.GET_ANALYTICS_KENS_ONDMND_FAILED:
            pageState.loading = false;
            updatedState.onDemandState.loading[action.key] = false;
            updatedState.onDemandState.error[action.key] = action.payload;

            break;

        default:
            break;
    }

    return updatedState;
};
