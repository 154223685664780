import { nfd as NFD } from 'unorm';
import { uniq, sumBy, get } from 'lodash';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import isMatch from 'date-fns/isMatch';
import isValid from 'date-fns/isValid';

export const downloadFromArrayBuffer = ({ buffer, fileName }) => {
    if (buffer) {
        const byteArray = new Uint8Array(buffer);
        const link = document.createElement('a');
        link.href = URL.createObjectURL(
            new Blob([byteArray], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
        );
        link.download = fileName || 'New File';
        link.click();
    }
    return;
};

export const stringNormalizer = (str) => {
    if (str && typeof str === 'string') {
        // for IE11/Edge String.prototype.normalize is `undefined` for that reason we need `unorm` package
        return NFD(decodeURIComponent(str)).replace(/[\u0300-\u036f]/g, '');
    }

    return str;
};

export const startCase = (str) => {
    if (str && typeof str === 'string') {
        return str
            .trim()
            .split(' ')
            .map((w) => w && w[0].toUpperCase() + w.slice(1))
            .join(' ');
    }
};

export const triggerGAEvent = ({ action, category, label, value, cb }) => {
    if (window.dataLayer) {
        window.dataLayer.push({
            event: 'CustomEvent',
            eventAction: action,
            eventCategory: category,
            eventLabel: label,
            eventValue: value,
            eventCallback: cb,
        });
    } else if (typeof cb === 'function') cb();
};

export const prepareGTMDataset = (dataProp) => {
    const dataset = {};
    if (dataProp) {
        dataset[dataProp] = true;
    }
    return dataset;
};

export const prepareUserGroups = (userGroup) => {
    const publicGroupId = process.env.REACT_APP_PUBLIC_GROUP_ID;
    if (userGroup) {
        // add `Public` group by default to all other groups to display public assets too
        return uniq([userGroup, publicGroupId]);
    }
    // set `Public` group by default if user is not logged in
    return [publicGroupId];
};

export const prepareFiltersBrandName = (brand) => {
    // default to `Kogi` brand
    if (typeof brand !== 'string') return 'Kogi';
    switch (brand) {
        case 'kogi':
            return 'Kogi';
        case 'sbr':
            return 'SBR';
        case 'kens':
            return "Ken's";
        case 'salad':
        case 'salad bar':
        case 'salad-bar':
            return 'Salad Bar';
        default:
            return 'Kogi';
    }
};

export const getAppItemsCount = (summary, route) => {
    if (summary.length > 0) {
        const filteredSummary = summary.filter(
            (s) =>
                s.route.indexOf(route) > -1 ||
                (s.parentRoutes &&
                    s.parentRoutes.findIndex((element) =>
                        element.includes(route)
                    ) > -1)
        );
        if (filteredSummary.length > 0) {
            const count = sumBy(filteredSummary, 'count');
            return count;
        } else {
            return 1;
        }
    }
    return 0;
};

export const formatNumberToStr = (
    str,
    minFracDig = 2,
    maxFracDig = 2,
    abs = false
) => {
    const strNum = parseFloat(str);

    let res;

    // If client's browser has Intl object (not mobile browser)
    if (Intl) {
        res = new Intl.NumberFormat('US', {
            minimumFractionDigits: minFracDig,
            maximumFractionDigits: maxFracDig,
        }).format(Math.abs(strNum));
    } else {
        if (str === '0') {
            str = '0.00';
        }

        const numbParts = [];

        const [leftDeciPart, rightDeciPart] = str.split('.');

        if (parseInt(Math.abs(leftDeciPart), 10) / 1000 >= 1) {
            const thousands = leftDeciPart.slice(0, leftDeciPart.length - 3);

            const lessThanThousands = leftDeciPart.slice(-3);

            numbParts.push(`${thousands},${lessThanThousands}`);
        } else {
            numbParts.push(Math.abs(leftDeciPart));
        }

        const fixedRightDeciPart = (+`0.${rightDeciPart}`).toFixed(minFracDig);

        numbParts.push(fixedRightDeciPart.split('.')[1]);

        res = numbParts.join('.');
    }

    if (abs) {
        return `${res}`;
    }

    return strNum < 0 ? `- ${res}` : `${res}`;
};

export const formatPlainCurrency = (numberStr) => {
    return `$${numberStr}`;
};

export const getDxpUrl = () => {
    return `${process.env.REACT_APP_DXP_HTTP_PROTOCOL}://${process.env.REACT_APP_DXP_HOST}`;
};

export const getPortalUrl = () => {
    return get(window, 'location.origin', '');
};

export const getDateStr = (date, formatStr) => {
    return format(date, formatStr);
};

export const getDate = (dateStr, formatStr) => {
    return parse(dateStr, formatStr, new Date());
};

export const convertDateStr = (dateStr, fromFormatStr, toFormatStr) => {
    let result = dateStr;

    try {
        const date = getDate(dateStr, fromFormatStr);

        result = getDateStr(date, toFormatStr);
    } catch (error) {}

    return result;
};

export const isValidDateStr = (dateStr, formatStr) => {
    return isMatch(dateStr, formatStr);
};

export const isValidDate = (date) => {
    return isValid(date);
};

export const getFieldValidationClass = (name, formState) => {
    const isDirty = get(formState.dirtyFields, name);

    const isError = get(formState.errors, name);

    return isError ? 'is-invalid' : isDirty && !isError ? 'is-valid' : '';
};
