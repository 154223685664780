export const actionTypes = {
    SET_ANALYTICS_KENS_FILTERS: 'SET_ANALYTICS_KENS_FILTERS',
    GET_ANALYTICS_KENS_METRICS_START: 'GET_ANALYTICS_KENS_METRICS_START',
    GET_ANALYTICS_KENS_METRICS_SUCCESS: 'GET_ANALYTICS_KENS_METRICS_SUCCESS',
    GET_ANALYTICS_KENS_METRICS_FAILED: 'GET_ANALYTICS_KENS_METRICS_FAILED',
    GET_ANALYTICS_KENS_METRICS_CLEAR_ERROR:
        'GET_ANALYTICS_KENS_METRICS_CLEAR_ERROR',
    GET_ANALYTICS_KENS_ONDMND_START: 'GET_ANALYTICS_KENS_ONDMND_START',
    GET_ANALYTICS_KENS_ONDMND_SUCCESS: 'GET_ANALYTICS_KENS_ONDMND_SUCCESS',
    GET_ANALYTICS_KENS_ONDMND_FAILED: 'GET_ANALYTICS_KENS_ONDMND_FAILED',
};
