export const actionTypes = {
    GET_LOOKUP_DATA_START: 'GET_LOOKUP_DATA_START',
    GET_LOOKUP_DATA_SUCCESS: 'GET_LOOKUP_DATA_SUCCESS',
    GET_LOOKUP_DATA_FAILED: 'GET_LOOKUP_DATA_FAILED',

    GET_PRODUCTS_START: 'GET_PRODUCTS_START',
    GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
    GET_PRODUCTS_FAILED: 'GET_PRODUCTS_FAILED',

    SET_SELECTED_FILTERS: 'SET_SELECTED_FILTERS',
    CLEAR_SELECTED_FILTERS: 'CLEAR_SELECTED_FILTERS',
    SET_DISABLE_BRAND_NAME: 'SET_DISABLE_BRAND_NAME',

    SET_SELECTED_LEAD_FILTERS: 'SET_SELECTED_LEAD_FILTERS',
    CLEAR_SELECTED_LEAD_FILTERS: 'CLEAR_SELECTED_LEAD_FILTERS',

    GET_LEAD_LOOKUP_DATA_START: 'GET_LEAD_LOOKUP_DATA_START',
    GET_LEAD_LOOKUP_DATA_SUCCESS: 'GET_LEAD_LOOKUP_DATA_SUCCESS',
    GET_LEAD_LOOKUP_DATA_FAILED: 'GET_LEAD_LOOKUP_DATA_FAILED',
};

export const defaultFilters = {
    assetFilters: {
        salesPortalStatus: ['Active'],
        brandName: ['Kogi', 'SBR', "Ken's", 'Salad Bar'],
    },
    filters: {
        Brand: {
            brandName: ['Kogi', 'SBR', "Ken's", 'Salad Bar'],
            'identifiers.brandName': ['Kogi', 'SBR', "Ken's", 'Salad Bar'],
        },
    },
};

export const defaultLeadFilters = {
    assetFilters: {
        // brandName: ['kogi', 'sbr', 'kfs'],
        phase: [
            'New Lead Pre-qualification',
            'Phase 1 - Assigned Leads',
            'Phase 2A - Leads Assigned to Core',
            'Phase 2B - Leads Waiting for Followup',
            'Phase 3 - Closed Out Leads',
        ],
    },
    filters: {
        phaseStatus: [
            'New Lead Pre-qualification',
            'Phase 1 - Assigned Leads',
            'Phase 2A - Leads Assigned to Core',
            'Phase 2B - Leads Waiting for Followup',
            'Phase 3 - Closed Out Leads',
        ],
    },
};

export const PHASE_LEAD_PRE_QUALIFICATION = 'New Lead Pre-qualification';
export const PHASE_LEAD_1 = 'Phase 1 - Assigned Leads';
export const PHASE_LEAD_2A = 'Phase 2A - Leads Assigned to Core';
export const PHASE_LEAD_2B = 'Phase 2B - Leads Waiting for Followup';

export const defaultFormValues = {
    brandName: [
        { name: 'Kogi', value: 'Kogi' },
        { name: 'SBR', value: 'SBR' },
        { name: "Ken's", value: "Ken's" },
        { name: 'Salad Bar', value: 'Salad Bar' },
    ],
    flavors: [],
    marketingClaims: [],
    packaging: [],
    productLine: [],
    productStorage: [],
    products: [],
};

export const defaultLeadFormValues = {
    brandName: [],
    phase: [],
    salesManager: [],
    date: [],
};
