import { actionTypes as types } from './constants';

export const addToCart = (assets) => ({
    type: types.ADD_ITEM,
    payload: { assets }
});

export const removeFromCart = (assetId) => ({
    type: types.REMOVE_ITEM,
    payload: { assetId }
});

export const cleanCart = () => ({
    type: types.CLEAN
});

export const setCartItems = (assets) => ({
    type: types.SET_ITEMS,
    payload: { assets },
});

export const findRelatedAssets = (searchTerm) => ({
    type: types.FIND_RELATED_START,
    payload: searchTerm
});

export const sendAssets = (payload) => ({
    type: types.SEND_ASSETS_START,
    payload
});

export const sendAssetsClear = () => ({
    type: types.SEND_ASSETS_CLEAR
});
