import Qs from 'qs';
import { takeLatest, put, call } from 'redux-saga/effects';

import ApiClient from '../../helpers/api';
import {
    actionTypes as types,
    PHASE_LEAD_PRE_QUALIFICATION,
} from './constants';

const loadLookupData = function* () {
    try {
        const query = Qs.stringify({
            dataset: ['packagings', 'flavors', 'productlines'],
        });

        const apiData = yield call(
            [ApiClient, ApiClient.get],
            `/api/dxp-data?${query}`
        );

        const result = {
            brandName: [
                { name: 'Kogi', value: 'Kogi' },
                { name: 'SBR', value: 'SBR' },
                { name: "Ken's", value: "Ken's" },
                { name: 'Salad Bar', value: 'Salad Bar' },
            ],
            marketingClaims: [
                { name: 'Fat Free', value: 'Yes', key: 'fatFree' },
                { name: 'Gluten Free', value: 'Yes', key: 'gluten' },
                { name: 'Kosher', value: 'Yes', key: 'kosher' },
                { name: 'Kosher Dairy', value: 'Yes', key: 'kosherDairy' },
                { name: 'Lite', value: 'Yes', key: 'lite' },
                { name: 'No HFCS', value: 'No', key: 'hfcs' },
                { name: 'No Added MSG', value: 'No', key: 'msg' },
                { name: 'No EDTA', value: 'No', key: 'edta' },
                {
                    name: 'No Artificial Flavors',
                    value: 'No',
                    key: 'artificialFlavors',
                },
                {
                    name: 'No Artificial Colors',
                    value: 'No',
                    key: 'syntheticColors',
                },
                {
                    name: 'No Artificial Preservatives',
                    value: 'No',
                    key: 'artificialPreservatives',
                },
            ],
            productStorage: [
                {
                    name: 'Shelf Stable',
                    value: 'Dry storage at ambient temperature (50 - 80F). Do not freeze.',
                },
                {
                    name: 'Refrigerated',
                    value: 'Product requires refrigerated storage and transport (38-45F). Do not freeze.',
                },
            ],
        };

        if (apiData.data && Array.isArray(apiData.data)) {
            for (const ds of apiData.data) {
                let type = ds.dataset;

                switch (type) {
                    case 'packagings':
                        type = 'packaging';
                        break;
                    case 'productlines':
                        type = 'productLine';
                        break;
                    default:
                        break;
                }

                if (ds.data.some(({ value }) => !!value)) {
                    result[type] = ds.data;
                } else {
                    result[type] = ds.data.map((ent) => ({
                        ...ent,
                        value: ent.name,
                    }));
                }
            }
        }

        yield put({ type: types.GET_LOOKUP_DATA_SUCCESS, payload: result });
    } catch (error) {
        console.error('[loadLookupData] error:', JSON.stringify(error));
        yield put({ type: types.GET_LOOKUP_DATA_FAILED, error });
    }
};

const loadLeadLookupData = function* (action) {
    try {
        const apiData = yield call([ApiClient, ApiClient.get], '/api/managers');

        const result = {
            brandName: [
                { name: 'Kogi', value: 'kogi' },
                { name: 'SBR', value: 'sbr' },
                { name: "Ken's", value: 'kfs' },
            ],
            phase: [
                {
                    name: 'New Lead',
                    value: 'New Lead Pre-qualification',
                },
                {
                    value: 'Phase 1 - Assigned Leads',
                    name: 'Phase 1',
                },
                {
                    value: 'Phase 2A - Leads Assigned to Core',
                    name: 'Phase 2A',
                },
                {
                    value: 'Phase 2B - Leads Waiting for Followup',
                    name: 'Phase 2B',
                },
                {
                    value: 'Phase 3 - Closed Out Leads',
                    name: 'Phase 3',
                },
            ],
            validInvalid: [
                { name: 'Invalid', value: 'Invalid' },
                { name: 'Valid', value: 'Valid' },
            ],
        };

        if (action.payload.isCore) {
            result.phase = result.phase.filter(
                (e) => e.value !== PHASE_LEAD_PRE_QUALIFICATION
            );
        }

        if (apiData.data && Array.isArray(apiData.data)) {
            const SALES_MANAGERS_GROUP_ID =
                process.env.REACT_APP_SALES_MANAGERS_GROUP_ID;

            const sm = apiData.data
                .filter((ent) => ent.group === SALES_MANAGERS_GROUP_ID)
                .map((ent) => ({
                    name: ent.label,
                    value: ent.value,
                }));
            result.salesManagers = sm;
        }

        yield put({
            type: types.GET_LEAD_LOOKUP_DATA_SUCCESS,
            payload: result,
        });
    } catch (error) {
        console.error('[loadLookupData] error:', JSON.stringify(error));
        yield put({ type: types.GET_LEAD_LOOKUP_DATA_FAILED, error });
    }
};

// we need to load product in separate call, cuz there're a lot of data and we don't want to block main lookup data req.
const loadProducts = function* (action) {
    try {
        const apiData = yield call(
            [ApiClient, ApiClient.get],
            '/api/all-products'
        );
        yield put({ type: types.GET_PRODUCTS_SUCCESS, payload: apiData });
    } catch (error) {
        console.error('[fetchAllProducts] error: ', error);
        yield put({ type: types.GET_PRODUCTS_FAILED, error });
    }
};

export default [
    takeLatest(types.GET_PRODUCTS_START, loadProducts),
    takeLatest(types.GET_LOOKUP_DATA_START, loadLookupData),
    takeLatest(types.GET_LEAD_LOOKUP_DATA_START, loadLeadLookupData),
];
