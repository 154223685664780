import { takeLatest, put, call, debounce } from 'redux-saga/effects';
import ApiClient from '../../helpers/api';
import { actionTypes as types } from './constants';

import Qs from 'qs';
import { get } from 'lodash';

const login = function* (action) {
    try {
        const apiResponse = yield ApiClient.post('/api/login', action.payload);
        yield put({ type: types.LOGIN_SUCCESS, payload: apiResponse });
    } catch (error) {
        yield put({ type: types.LOGIN_FAILED, error });
    }
};

const forgot = function* (action) {
    try {
        const apiResponse = yield ApiClient.post('/api/forgot', action.payload);
        yield put({ type: types.FORGOT_SUCCESS, payload: apiResponse });
    } catch (error) {
        yield put({ type: types.FORGOT_FAILED, error });
    }
};

const summary = function* (action) {
    try {
        const url = `/api/summary/?${Qs.stringify({
            userGroup: get(action.payload, ['group', '_id']),
        })}`;
        const apiData = yield call([ApiClient, ApiClient.get], url);

        yield put({
            type: types.SUMMARY_SUCCESS,
            payload: apiData,
        });
    } catch (err) {
        console.error('[fetchSummaryResults] error: ', JSON.stringify(err));
        yield put({ type: types.SUMMARY_FAILED, message: err.message });
    }
};

export default [
    takeLatest(types.LOGIN_START, login),
    takeLatest(types.FORGOT_START, forgot),
    debounce(750, types.SUMMARY_START, summary),
];
