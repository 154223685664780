import _cloneDeep from 'lodash/cloneDeep';

import { actionTypes as types } from './constants';

const initialState = {
    items: null,
    error: null,
    loading: false,

    details: null,
    errorDetails: null,
    loadingDetails: false,

    errorUpdate: null,
    successUpdate: null,
    loadingUpdate: false,

    errorRemove: null,
    successRemove: null,
    loadingRemove: false,
};

export default (state = initialState, action) => {
    const updatedState = _cloneDeep(state);

    switch (action.type) {
        // items
        case types.GET_WINGMAN_LOCATIONS_START:
            updatedState.loading = true;
            break;
        case types.GET_WINGMAN_LOCATIONS_SUCCESS:
            updatedState.loading = false;
            updatedState.items =
                action.payload.data && action.payload.data.length > 0
                    ? action.payload.data
                    : [];
            break;
        case types.GET_WINGMAN_LOCATIONS_FAILED:
            updatedState.loading = false;
            updatedState.error = action.error;
            break;

        // details
        case types.GET_WINGMAN_LOCATION_START:
            updatedState.loadingDetails = true;
            updatedState.errorUpdate = false;
            updatedState.errorDetails = false;
            updatedState.successUpdate = false;
            break;
        case types.GET_WINGMAN_CLEAR_LOCATIONS_DETAILS_STATE:
            updatedState.errorDetails = false;
            updatedState.successUpdate = false;
            break;
        case types.GET_WINGMAN_LOCATION_SUCCESS:
            updatedState.loadingDetails = false;
            updatedState.details = action.payload.data;
            break;
        case types.GET_WINGMAN_LOCATION_FAILED:
            updatedState.loadingDetails = false;
            updatedState.errorDetails = action.error;
            break;

        // update
        case types.UPDATE_WINGMAN_LOCATION_CLEAR:
            updatedState.successUpdate = false;
            break;
        case types.UPDATE_WINGMAN_LOCATION_START:
            updatedState.loadingUpdate = true;
            updatedState.errorUpdate = false;
            updatedState.successUpdate = false;
            break;
        case types.UPDATE_WINGMAN_LOCATION_SUCCESS:
            updatedState.loadingUpdate = false;
            updatedState.errorUpdate = false;
            updatedState.successUpdate = true;

            const udpatedItem = action.payload.data;
            updatedState.details = udpatedItem;

            // check if items list exists
            if (updatedState.items) {
                const targetIndex = updatedState.items.findIndex(
                    ({ id }) => id === udpatedItem.id
                );
                if (targetIndex > -1) {
                    // update item in items list
                    updatedState.items[targetIndex] = udpatedItem;
                } else {
                    // add item to items list
                    updatedState.items.push(udpatedItem);
                }
            }

            break;
        case types.UPDATE_WINGMAN_LOCATION_FAILED:
            updatedState.loadingUpdate = false;
            updatedState.errorUpdate = action.error;
            break;

        // remove
        case types.REMOVE_WINGMAN_LOCATION_START:
            updatedState.loadingRemove = true;
            break;
        case types.REMOVE_WINGMAN_LOCATION_SUCCESS:
            updatedState.loadingRemove = false;
            updatedState.errorRemove = false;
            updatedState.successRemove = true;
            // remove from the list
            updatedState.items = updatedState.items.filter(
                ({ id }) => id !== action.payload.itemId
            );
            // remove from details
            if (
                updatedState.details &&
                updatedState.details.id === action.payload.itemId
            ) {
                updatedState.details = null;
            }
            break;
        case types.REMOVE_WINGMAN_LOCATION_FAILED:
            updatedState.loadingRemove = false;
            updatedState.errorRemove = action.error;
            break;
        case types.REMOVE_WINGMAN_LOCATION_CLEAR:
            updatedState.successRemove = false;
            updatedState.errorRemove = false;
            break;

        default:
            break;
    }

    return updatedState;
};
