export const actionTypes = {
    GET_DISPENSER_ORDERS_START: 'GET_DISPENSER_ORDERS_START',
    GET_DISPENSER_ORDERS_SUCCESS: 'GET_DISPENSER_ORDERS_SUCCESS',
    GET_DISPENSER_ORDERS_FAILED: 'GET_DISPENSER_ORDERS_FAILED',

    UPDATE_DISPENSER_ORDER_START: 'UPDATE_DISPENSER_ORDER_START',
    UPDATE_DISPENSER_ORDER_SUCCESS: 'UPDATE_DISPENSER_ORDER_SUCCESS',
    UPDATE_DISPENSER_ORDER_FAILED: 'UPDATE_DISPENSER_ORDER_FAILED',
    UPDATE_DISPENSER_ORDER_CLEAR: 'UPDATE_DISPENSER_ORDER_CLEAR',
};
