import React, {
    memo,
    useMemo,
    useState,
    useEffect,
    useCallback,
    Suspense
} from 'react';
import { connect } from 'react-redux';
import ClassNames from 'classnames';
import { Link } from 'react-router-dom';

import './header.scss';

import kensLogo from '../../assets/img/kens-logo.png';
import sbrLogo from '../../assets/img/sbr-logo.png';
import kogiLogo from '../../assets/img/kogi-logo.png';
import aramarkLogo from '../../assets/img/aramark-logo.svg';
import seLogo from '../../assets/img/server-essential-logo.png';

import Navigation from '../navigation/Navigation';

import lazy from '../../helpers/lazy';
import { useMobile, useQuery } from '../../hooks';
import { toggleRWVisibility } from '../right-wrapper/actions';

const SearchForm = lazy(() => import('./SearchForm'));

const Header = ({ location, cartItemsLength, orderItemsLength, toggleRWVisibility }) => {
    const isMobile = useMobile();
    const query = useQuery();
    const isPrint = query.has('print'); // if need to prepare page for pdf
    const [isSearchActive, setSearchActive] = useState(true);
    const [isMobileMenuActive, setMobileMenuActive] = useState(false);

    useEffect(() => {
        setSearchActive(false);
    }, [location]);

    const enableOrderTab = useMemo(
        () => !!location.pathname.match(/order-supplies/g), [location.pathname]
    );
    const isAramarkPage = useMemo(
        () => !!location.pathname.match(/aramark/g), [location.pathname]
    );
    const itemsLength = useMemo(
        () => enableOrderTab ? orderItemsLength : cartItemsLength,
        [enableOrderTab, cartItemsLength, orderItemsLength]
    );
    const handleSearchClick = useCallback(() => setSearchActive(prev => !prev), []);
    const handleCartClick = useCallback(() => {
        if (enableOrderTab && orderItemsLength) {
            toggleRWVisibility('order-supply');
        } else if (cartItemsLength) {
            toggleRWVisibility('cart');
        }
    }, [cartItemsLength, orderItemsLength, enableOrderTab]
    );

    const mobileNavClassnames = ClassNames('mobile-nav-wrapper', {
        'visible': isMobileMenuActive
    });
    const searchIconClassnames = ClassNames('search-icon', {
        'close': isSearchActive
    });
    const logotype = (
        <Link className="logo" to="/">
            <img className="kens-logo" src={kensLogo} alt="Kens Logotype" />
            <img src={sbrLogo} alt="SBR Logotype" />
            {!isAramarkPage ? <img className="kogi-logo" src={kogiLogo} alt="KOGI Logotype" /> : null}

        </Link>
    );
    const aramarkLogos = (
        <Link className="logo right" to="/">
            <img src={aramarkLogo} alt="Aramark Logo" />
            <img src={seLogo} alt="Server Essentials Logo" />
        </Link>
    );

    return (
        <header id="no-print" className={isPrint ? 'print' : ''}>
            {!isMobile && (
                <div className="header-wrapper desktop">
                    {logotype}
                    {!isAramarkPage ? (
                        <>
                            <div className="main-nav">
                                <Navigation />
                                <div
                                    onClick={handleSearchClick}
                                    className={searchIconClassnames}
                                    data-gtm-search-icon-link-a="true"
                                ></div>
                                <div className="cart-icon" onClick={handleCartClick} style={{ marginTop: '1px' }}>
                                    <span>{itemsLength}</span>
                                </div>
                            </div>
                            <Suspense fallback={null}>
                                {isSearchActive && <SearchForm />}
                            </Suspense>
                        </>
                    ) : aramarkLogos
                    }
                </div>
            )}
            {isMobile && (
                <div className="header-wrapper mobile">
                    <div className="header-mobile">
                        <div className="logos-wrapper">
                            {logotype}
                            {isAramarkPage && aramarkLogos}
                        </div>
                        {!isPrint && (
                            !isAramarkPage ? (
                                <div>
                                    <button className="menu-btn" onClick={() => setMobileMenuActive(prev => !prev)}>
                                        <span></span><span></span><span></span>
                                    </button>
                                    <div
                                        onClick={handleSearchClick}
                                        className={searchIconClassnames}
                                        data-gtm-search-icon-link-a="true"
                                    />
                                    <div className="cart-icon" onClick={handleCartClick}>
                                        <span>{itemsLength}</span>
                                    </div>
                                    <Suspense fallback={null}>
                                        {isSearchActive && <SearchForm />}
                                    </Suspense>
                                </div>
                            ) : (
                                    <div className="cart-icon" onClick={handleCartClick}>
                                        <span>{itemsLength}</span>
                                    </div>
                            )
                        )}
                    </div>
                    <div className={mobileNavClassnames}>
                        <Navigation closeCallback={() => setMobileMenuActive(false)} />
                    </div>
                </div>
            )}
        </header>
    );
};

const mapStateToProps = ({ router, cart, order }) => ({
    location: router.location,
    cartItemsLength: cart.items.length,
    orderItemsLength: order.items.length,
});

export default connect(mapStateToProps, { toggleRWVisibility })(memo(Header));
