import _get from 'lodash/get';

const ADMIN_GROUP_ID = process.env.REACT_APP_ADMIN_GROUP_ID;

class AuthHelper {
    static getUser() {
        let user = null;
        // check if user data exists in permanent storage first
        user = typeof localStorage === 'object' && localStorage.getItem('user');

        if (!user) {
            // than in session storage
            user =
                typeof sessionStorage === 'object' &&
                sessionStorage.getItem('user');
        }

        if (user) {
            user = JSON.parse(user);
        }

        return user;
    }

    static setUser(user) {
        const userData = JSON.stringify(user);
        let storage = null;

        if (user.keepMe && typeof localStorage === 'object') {
            storage = localStorage;
        } else if (typeof sessionStorage === 'object') {
            storage = sessionStorage;
        }

        if (storage) {
            storage.setItem('user', userData);
        }
    }

    static removeUser() {
        if (typeof localStorage === 'object' && localStorage.getItem('user')) {
            localStorage.removeItem('user');
        }

        if (
            typeof sessionStorage === 'object' &&
            sessionStorage.getItem('user')
        ) {
            sessionStorage.removeItem('user');
        }
    }

    static isAdmin(user) {
        return _get(user, 'group._id') === ADMIN_GROUP_ID;
    }
}

export default AuthHelper;
