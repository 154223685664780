import humanizeDuration from 'humanize-duration';
import sub from 'date-fns/sub';
import differenceInDays from 'date-fns/differenceInDays';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isArray from 'lodash/isArray';
import * as Utils from '../../../helpers/utils';

import { CLIENT_BRANDS, COMPARE_TO, GA_DATE_FORMAT } from './constants';

export const getDateStr = (date, formatStr = GA_DATE_FORMAT) => {
    return Utils.getDateStr(date, formatStr);
};

export const getDate = (dateStr, formatStr = GA_DATE_FORMAT) => {
    return Utils.getDate(dateStr, formatStr);
};

export const convertDateStr = (dateStr, fromFormatStr, toFormatStr) => {
    return Utils.convertDateStr(dateStr, fromFormatStr, toFormatStr);
};

export const isValidDateStr = (dateStr, formatStr = GA_DATE_FORMAT) => {
    return Utils.isValidDateStr(dateStr, formatStr);
};

export const isValidDate = (date) => {
    return Utils.isValidDate(date);
};

export const convertFormValuesToFilters = (formValues, clientKey) => {
    const request = {};

    // date range 1
    const range1StartDate = getDate(formValues.dateRange.startDate);

    const range1EndDate = getDate(formValues.dateRange.endDate);

    request.dateRanges = [
        {
            startDate: formValues.dateRange.startDate,
            endDate: formValues.dateRange.endDate,
        },
    ];

    // date range 2
    let range2StartDate, range2EndDate;

    if (formValues.compareTo === COMPARE_TO.period) {
        // 'period'
        range2EndDate = sub(range1StartDate, { days: 1 });

        const diffDays = differenceInDays(range1EndDate, range1StartDate);

        range2StartDate = sub(range2EndDate, { days: diffDays });
    } else if (formValues.compareTo === COMPARE_TO.year) {
        // 'year'
        range2StartDate = sub(range1StartDate, { years: 1 });

        range2EndDate = sub(range1EndDate, { years: 1 });
    }

    if (range2StartDate && range2EndDate) {
        request.dateRanges.push({
            startDate: getDateStr(range2StartDate),
            endDate: getDateStr(range2EndDate),
        });
    }

    request.brands =
        formValues.brands && formValues.brands.length
            ? formValues.brands
            : CLIENT_BRANDS[clientKey];

    const dimensions = Object.keys(formValues.dimensions || {}).reduce(
        (memo, current) => {
            if (formValues.dimensions[current]) {
                const nextFilter = {
                    [current]: formValues.dimensions[current],
                };

                memo = { ...memo, ...nextFilter };
            }

            return memo;
        },
        {}
    );

    if (Object.keys(dimensions).length) {
        request.dimensions = dimensions;
    }

    return request;
};

export const getPrevMetricKey = (metricKey, range = 1) => {
    return metricKey + '_' + range;
};

const getCustomMetricKey = (metricKey, range = 1, suffix) => {
    const prevMetricKey = getPrevMetricKey(metricKey, range);

    return prevMetricKey + suffix;
};

export const getDiffMetricKey = (metricKey, range) => {
    return getCustomMetricKey(metricKey, range, '_diff');
};

export const getDiffPercentMetricKey = (metricKey, range) => {
    return getCustomMetricKey(metricKey, range, '_diff_p');
};

export const getDuration = (value, args = {}) => {
    const { units = 's' } = args;

    const _value = units === 's' ? value * 1000 : value;

    const customHumanizer = humanizeDuration.humanizer({
        round: true,
        language: 'shortEn',
        delimiter: ' ',
        languages: {
            shortEn: {
                y: () => 'y',
                mo: () => 'mo',
                w: () => 'w',
                d: () => 'd',
                h: () => 'h',
                m: () => 'm',
                s: () => 's',
                ms: () => 'ms',
            },
        },
    });

    return customHumanizer(_value);
};

export const formatNumToRead = (value) => {
    const COUNT_FORMATS = [
        {
            // 0 - 999
            letter: '',
            limit: 1e3,
        },
        {
            // 1,000 - 999,999
            letter: 'K',
            limit: 1e6,
        },
        {
            // 1,000,000 - 999,999,999
            letter: 'M',
            limit: 1e9,
        },
        {
            // 1,000,000,000 - 999,999,999,999
            letter: 'B',
            limit: 1e12,
        },
        {
            // 1,000,000,000,000 - 999,999,999,999,999
            letter: 'T',
            limit: 1e15,
        },
    ];

    const format = COUNT_FORMATS.find((format) => value < format.limit);

    value = (1000 * value) / format.limit;

    value = Math.round(value * 10) / 10; // keep one decimal number, only if needed

    return value + format.letter;
};

export const getCellLink = (info, brandAccessor, linksMap = {}) => {
    const brandKey = info.row.values[brandAccessor];

    return linksMap[brandKey] && linksMap[brandKey] + info.value;
};

/*
    store:
    {
        [{ filters: {}, _meta: {}, reports: { reportKey1: data, reportKey2: data } }],
        [{ filters: {}, _meta: {}, reports: { reportKey1: data, reportKey2: data } }],
    }
*/
export const getRerpotsFilterStore = (filters, store = []) => {
    const _store = cloneDeep(store);

    const targetFilterStore = _store.find((filterStore) => {
        return isEqual(filterStore.filters, filters);
    });

    return targetFilterStore;
};

export const getReportsFromStore = (reportKey, filters, store) => {
    const reportKeysList = isArray(reportKey) ? reportKey : [reportKey];

    const filterStore = getRerpotsFilterStore(filters, store);

    const _meta = get(filterStore, '_meta');

    const notFound = [];

    const reports = reportKeysList.reduce((memo, currentKey) => {
        const targetReport = get(filterStore, ['reports', currentKey]);

        if (targetReport) {
            memo[currentKey] = targetReport;
        } else {
            notFound.push(currentKey);
        }

        return memo;
    }, {});

    return { data: { ...reports, _meta }, notFound };
};

export const addReportsToStore = (reportData, filters, store = []) => {
    const _store = cloneDeep(store);

    const { _meta, ...reports } = reportData;

    const targetFilterStoreIndex = _store.findIndex((filterStore) => {
        return isEqual(filterStore.filters, filters);
    });

    if (targetFilterStoreIndex > -1) {
        _store[targetFilterStoreIndex].reports = {
            ..._store[targetFilterStoreIndex].reports,
            ...reports,
        };
    } else {
        _store.push({ filters, _meta, reports });
    }

    return _store;
};

export const genericFormatter = (map = {}) => {
    const formatter = (key) => {
        return map[key] || key;
    };

    return formatter;
};
