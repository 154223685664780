import { BRAND as KENS_BRAND } from './clients/kens/constants';
import { BRAND as SALES_BRAND } from './clients/sales/constants';

// 2023-06-01
export const GA_DATE_FORMAT = 'yyyy-MM-dd';

export const UI_DATE_FORMAT = 'd MMM yyyy';

export const CHART_DATE_FORMAT = 'M/d/yy';

export const MAX_DATE_RANGE_DAYS = 366;

// 1 May
export const FISCAL_YEAR_START_DATE = '05-01';

export const CLIENTS = {
    // kens, sbr, kogi
    kens: 'kens',
    // sales portal
    sales: 'sales',
};

export const CLIENT_BRANDS = {
    [CLIENTS.kens]: [KENS_BRAND.kens, KENS_BRAND.sbr, KENS_BRAND.kogi],
    [CLIENTS.sales]: [SALES_BRAND.sales],
};

export const METRICS = {
    users: 'totalUsers',
    sessions: 'sessions',
    timeOnSite: 'averageSessionDuration',
    pageViews: 'screenPageViews',
    count: 'eventCount',
};

export const METRICS_CLASS_NAMES = {
    [METRICS.users]: 'users',
    [METRICS.sessions]: 'sessions',
    [METRICS.timeOnSite]: 'time_on_site',
    [METRICS.pageViews]: 'page_views',
    [METRICS.count]: 'count_col',
};

export const DIMENSIONS = {
    channel: 'sessionDefaultChannelGroup',
    creative: 'sessionGoogleAdsCreativeId',
    device: 'deviceCategory',
    state: 'region',
    city: 'city',
    landingPage: 'landingPagePlusQueryString',
    page: 'pagePathPlusQueryString',
    date: 'date',
    month: 'month',
    pagePath: 'pagePath',
    signedUserType: 'signedInWithUserId',
    sessionSource: 'sessionSource',
    sessionMedium: 'sessionMedium',
    sessionCampaignId: 'sessionCampaignId',
    sessionCampaignName: 'sessionCampaignName',
    sessionManualAdContent: 'sessionManualAdContent',
    sessionManualTerm: 'sessionManualTerm',
    sampleRequestId: 'customEvent:request_sample_id',
};

// https://ga-dev-tools.google/ga4/dimensions-metrics-explorer/#deviceCategory
export const DEVICE_CATEGORIES = {
    desktop: 'Desktop',
    tablet: 'Tablet',
    mobile: 'Mobile',
};

export const TRAFFIC_GROUPS = {
    unpaid: 'Unpaid',
    paid: 'Paid',
};

export const CHART = {
    period1: {
        color: '#8884d8',
        dataKey: 'period 1',
    },
    period2: {
        color: '#82ca9d',
        dataKey: 'period 2',
    },
};

export const COMPARE_TO = {
    period: 'period',
    year: 'year',
};

export const SIGNED_USER_TYPES_LABELS = {
    public: 'Public',
    login: 'Login',
};

export const CUSTOM_KEYS = {
    traffic: '_traffic',
};
