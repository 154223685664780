import { takeLatest, put, call, select } from 'redux-saga/effects';
import { get, isEmpty } from 'lodash';
import ApiClient from '../../helpers/api'
import { prepareUserGroups } from '../../helpers/utils';
import { actionTypes as types } from './constants';
import { defaultFilters } from '../filters/constants';


// const fetchProductDetailsByIds = function* (ids) {
//     const mergedData = {};
//     const details = yield call(
//         [ApiClient, ApiClient.post], '/api/products-details', { ids: uniq(ids) }
//     );
//
//     if (!isEmpty(details.data)) {
//         const combinedSearchData = yield all(
//             details.data.map(({ code }) => call(
//                 [ApiClient, ApiClient.post], '/api/search', {
//                     ...defaultFilters,
//                     assetFilters: {
//                         ...defaultFilters.assetFilters,
//                         salesPortalStatus: [ 'Active' ],
//                     },
//                     searchTerm: code.replace(/\s\s+/g, ' ').trim(),
//                 }
//             ))
//         );
//
//         combinedSearchData.forEach((item, i) => {
//             if (!isEmpty(item.data)) {
//                 const entity = head(item.data);
//                 Object.keys(entity).forEach((key) => {
//                     if (mergedData[key]) {
//                         mergedData[key]['assets'] = uniqBy([
//                             ...mergedData[key]['assets'],
//                             ...entity[key]['assets']
//                         ], 'id');
//                     } else {
//                         mergedData[key] = entity[key];
//                     }
//                 });
//             }
//         });
//     }
//
//     yield put({ type: types.FIND_RELATED_SUCCESS, payload: { data: [ mergedData ]}});
// }

const findRelatedAssets = function* (action) {
    try {
        let products = [];
        const state = yield select();
        const { id } = action.payload;
        const assetEntity = state.cart.items.find((ent) => ent.id === id);
        const searchTerm = get(assetEntity.info, 'name', '');
        const userGroups = prepareUserGroups( get(state.login.user, 'group._id') );
        const customFilters = {
            ...defaultFilters,
            filters: {
                ...defaultFilters.filters,
                'salesPortal.audience': userGroups,
            },
            assetFilters: {
                ...defaultFilters.assetFilters,
                salesPortalStatus: [ 'Active' ],
                userGroups,
            }
        };

        if (!isEmpty(assetEntity.info.attachedTo)) {
            if (assetEntity.info.attachedTo.code) { // Product source item entity
                products.push(assetEntity.info.attachedTo.id);
            } else if (!isEmpty(assetEntity.info.attachedTo.relatedProducts)) { // other source item entities
                products = [...products, ...assetEntity.info.attachedTo.relatedProducts];
            }
        } else if ( // there's no attached source item to the asset
            !isEmpty(assetEntity.info.schemaData) &&
            !isEmpty(assetEntity.info.schemaData.products)
        ) {
            products = [...products, ...assetEntity.info.schemaData.products];
        }

        if (!isEmpty(products)) {
            customFilters.assetFilters = {
                ...customFilters.assetFilters,
                products,
            };
            customFilters.filters = {
                ...customFilters.filters,
                Product: {
                    _id: products,
                    relatedProducts: products
                }
            };
        } else {
            customFilters.searchTerm = searchTerm.replace(/\s\s+/g, ' ').trim();
        }

        const apiData = yield call([ApiClient, ApiClient.post], '/api/search', customFilters);
        yield put({ type: types.FIND_RELATED_SUCCESS, payload: apiData });
    } catch (err) {
        console.error('[findRelatedAssets] error:', JSON.stringify(err));
        yield put({ type: types.FIND_RELATED_FAILED, message: err.message });
    }
}

const sendAssets = function* (action) {
    try {
        const apiResponse = yield call([ApiClient, ApiClient.post], '/api/send', action.payload);
        yield put({ type: types.SEND_ASSETS_SUCCESS, payload: apiResponse });
    }
    catch (err) {
        console.error('[sendAssets] error:', JSON.stringify(err));
        yield put({ type: types.SEND_ASSETS_FAILED, error: err });
    }
};

export default [
    takeLatest(types.SEND_ASSETS_START, sendAssets),
    takeLatest(types.FIND_RELATED_START, findRelatedAssets)
];
