import React, { Component } from 'react';

import { LazyLoadingError } from '../../helpers/lazy';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, isLazyLoadingError: false };
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            isLazyLoadingError: error instanceof LazyLoadingError,
        };
    }

    componentDidCatch(error, errorInfo) {
        const { location = {}, navigator = {}, screen = {} } = window || {};

        const {
            href,
            origin,
            protocol,
            host,
            hostname,
            port,
            pathname,
            search,
            hash,
        } = location;

        const { userAgent, platform, language, vendor, onLine, cookieEnabled } =
            navigator;

        const { width: screenWidth, height: screenHeight, colorDepth } = screen;

        const { innerWidth: viewportWidth, innerHeight: viewportHeight } =
            window || {};

        const referrer = document.referrer;

        const dateString = new Date().toLocaleString();

        fetch('/api/log-client-error', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                env: process.env.REACT_APP_ENVIRONMENT,
                context: this.props.context,
                isLazyLoadingError: this.state.isLazyLoadingError,
                location: {
                    href,
                    origin,
                    protocol,
                    host,
                    hostname,
                    port,
                    pathname,
                    search,
                    hash,
                },
                browser: {
                    userAgent,
                    platform,
                    language,
                    vendor,
                    onLine,
                    cookieEnabled,
                },
                screen: {
                    screenWidth,
                    screenHeight,
                    colorDepth,
                },
                viewport: {
                    viewportWidth,
                    viewportHeight,
                },
                referrer,
                dateString,
                error: error.toString(),
                errorInfo: errorInfo.componentStack,
            }),
        });
    }

    render() {
        if (this.state.hasError) {
            let message =
                "Oops! Something unexpected happened. We're already working on it. Please try to reload the page.";

            if (this.state.isLazyLoadingError) {
                message =
                    'The app has been updated. Please reload the page to get the latest version.';
            }

            return (
                <div
                    style={{
                        textAlign: 'center',
                        border: '1px solid #ccc',
                        padding: '10px',
                        borderRadius: '5px',
                        backgroundColor: this.state.isLazyLoadingError
                            ? '#7fca8554'
                            : '#f9f9f9',
                        color: '#333',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <p>{message}</p>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '10px',
                        }}
                    >
                        <button
                            onClick={() => window.location.reload()}
                            style={{
                                border: 'none',
                                borderRadius: '5px',
                                backgroundColor: '#007BFF',
                                color: '#fff',
                                cursor: 'pointer',
                                width: '150px',
                                height: '40px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginRight: '10px',
                            }}
                        >
                            Reload
                        </button>
                        {window.location.pathname !== '/' && (
                            <button
                                onClick={() => (window.location.href = '/')}
                                style={{
                                    border: 'none',
                                    borderRadius: '5px',
                                    backgroundColor: '#28a745',
                                    color: '#fff',
                                    cursor: 'pointer',
                                    width: '150px',
                                    height: '40px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                Go to Home
                            </button>
                        )}
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
