import { takeLatest, put, call } from 'redux-saga/effects';

import ApiClient from '../../helpers/api'
import { actionTypes as types } from './constants';

const createExcelFile = function* (action) {
    try {
        const apiResponse = yield call([ApiClient, ApiClient.post], '/api/process-fields', action.payload);
        yield put({ type: types.CREATE_EXCEL_SUCCESS, payload: apiResponse });
    }
    catch (err) {
        console.error('[createExcelFile] error:', JSON.stringify(err));
        yield put({ type: types.CREATE_EXCEL_FAILED, error: err });
    }
};

export default [
    takeLatest(types.CREATE_EXCEL_START, createExcelFile)
];
