export const BRAND = {
    sales: 'sales',
};

export const BRAND_LABEL = {
    [BRAND.sales]: 'Sales Portal',
};

export const REPORT = {
    // userReport
    plainUser: 'plainUser',
    plainUserDate: 'plainUserDate',
    userGroup: 'userGroup',
    userGroupMonth: 'userGroupMonth',
    dxpUsers: 'dxpUsers',
    dxpUsersMonth: 'dxpUsersMonth',
    // page reports
    pagePath: 'pagePath',
};

export const DATE_MIN_DATE = '2023-01-01';

export const DIMENSIONS = {
    userGroup: 'customUser:user_group', // custom dimension
    dxpUserId: 'customUser:dxp_user_id', // custom dimension
};

export const CUSTOM_KEYS = {
    salesDownload: '_downloadEvent',
    salesEmail: '_emailEvent',
    userFullName: '_userFullName',
};

export const PAGES = {
    sales: 'sales',
};
