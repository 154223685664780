import Qs from 'qs';
import { takeLatest, put, call } from 'redux-saga/effects';

import ApiClient from '../../../../../../helpers/api';
import { actionTypes as types } from './constants';

const getComplaintLookupData = function* () {
    try {
        const query = Qs.stringify({
            dataset: ['packagings'],
        });

        const apiData = yield call(
            [ApiClient, ApiClient.get],
            `/api/dxp-data?${query}`
        );

        const result = {};

        if (apiData.data && Array.isArray(apiData.data)) {
            for (const ds of apiData.data) {
                let type = ds.dataset;

                switch (type) {
                    case 'packagings':
                        type = 'packagings';
                        break;
                    default:
                        break;
                }

                if (ds.data.some(({ value }) => !!value)) {
                    result[type] = ds.data;
                } else {
                    result[type] = ds.data.map((ent) => ({
                        ...ent,
                        value: ent.name,
                    }));
                }
            }
        }

        yield put({
            type: types.GET_CUSTOMER_COMPLAINT_LOOKUP_DATA_SUCCESS,
            payload: result,
        });
    } catch (error) {
        console.error('[getComplaintLookupData] error:', JSON.stringify(error));
        yield put({
            type: types.GET_CUSTOMER_COMPLAINT_LOOKUP_DATA_FAILED,
            error,
        });
    }
};

const submitComplaintAssets = function* (action) {
    try {
        const apiResponse = yield call(
            [ApiClient, ApiClient.putFile],
            '/api/customer-complaint/assets',
            action.payload
        );

        yield put({
            type: types.SUBMIT_CUSTOMER_COMPLAINT_ASSETS_SUCCESS,
            payload: apiResponse,
        });
        try {
            yield call(action.callback, apiResponse);
        } catch (err) {
            console.error(
                '[submitComplaintAssets callback error] ->',
                JSON.stringify(err)
            );
            yield put({
                type: types.SUBMIT_CUSTOMER_COMPLAINT_FAILED,
                error: err,
            });
        }
    } catch (err) {
        console.error('[submitComplaintAssets] ->', JSON.stringify(err));
        yield put({
            type: types.SUBMIT_CUSTOMER_COMPLAINT_ASSETS_FAILED,
            error: err,
        });
    }
};

const submitComplaint = function* (action) {
    try {
        const apiResponse = yield call(
            [ApiClient, ApiClient.post],
            '/api/customer-complaint',
            action.payload
        );
        yield put({
            type: types.SUBMIT_CUSTOMER_COMPLAINT_SUCCESS,
            payload: apiResponse,
        });
    } catch (err) {
        console.error('[submitComplaint] ->', JSON.stringify(err));
        yield put({ type: types.SUBMIT_CUSTOMER_COMPLAINT_FAILED, error: err });
    }
};

export default [
    takeLatest(
        types.GET_CUSTOMER_COMPLAINT_LOOKUP_DATA_START,
        getComplaintLookupData
    ),
    takeLatest(
        types.SUBMIT_CUSTOMER_COMPLAINT_ASSETS_START,
        submitComplaintAssets
    ),
    takeLatest(types.SUBMIT_CUSTOMER_COMPLAINT_START, submitComplaint),
];
