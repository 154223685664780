import { useState, useEffect } from 'react';

export const useMobile = (maxWidth = 1000) => {
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const listener = (arg) => setIsMobile(arg.matches);
        const mql = window.matchMedia(`(max-width: ${maxWidth}px)`);
        listener(mql);
        mql.addListener(listener);
        return () => mql.removeListener(listener);
    }, [maxWidth]);
    return isMobile;
};
