import get from 'lodash/get';
import clone from 'lodash/clone';
import { actionTypes as types } from './constants';

const initialState = {
    items: [],
    error: null,
    process: false,
    sourceItems: [],
    sourceItemsLoading: false,
    showSuccessMessage: false,
    somethingWentWrong: false,
    // aramark profit centers
    aramark: {
        profitCenters: [],
        profitCentersLoading: false,
        profitCentersError: false,
    },
};

export default (state = initialState, action) => {
    const updatedState = clone(state);

    switch (action.type) {
        case types.ADD_ORDER_ITEM:
            const { assets } = action.payload;
            for (const asset of assets) {
                if (
                    !(
                        updatedState.items.findIndex(
                            (it) => it.id === asset.id
                        ) >= 0
                    )
                ) {
                    updatedState.items = updatedState.items.concat(asset);
                }
            }
            break;
        case types.REMOVE_ORDER_ITEM:
            const { assetId } = action.payload;
            updatedState.items = updatedState.items.filter(
                (it) => it.id !== assetId
            );
            break;
        case types.PROCESS_ORDER_START:
            updatedState.error = null;
            updatedState.process = true;
            updatedState.somethingWentWrong = false;
            updatedState.showSuccessMessage = false;
            break;
        case types.PROCESS_ORDER_SUCCESS:
            updatedState.process = false;
            updatedState.showSuccessMessage = true;
            break;
        case types.PROCESS_ORDER_FAILED:
            updatedState.process = false;
            updatedState.error = action.error;
            updatedState.somethingWentWrong = true;
            break;
        case types.CLEAR_ORDER:
            const { clearOrderItems } = action.payload;
            return clearOrderItems
                ? {
                      ...initialState,
                      sourceItems: updatedState.sourceItems,
                  }
                : {
                      ...initialState,
                      items: updatedState.items,
                      sourceItems: updatedState.sourceItems,
                  };
        case types.GET_SQUEEZE_BOTTLES_START:
            updatedState.error = null;
            updatedState.sourceItemsLoading = true;
            break;
        case types.GET_SQUEEZE_BOTTLES_SUCCESS:
            updatedState.sourceItemsLoading = false;
            updatedState.sourceItems = get(action.payload.data, 'data', []);
            break;
        case types.GET_SQUEEZE_BOTTLES_FAILED:
            updatedState.sourceItemsLoading = false;
            updatedState.error = action.error;
            break;
        case types.GET_DISPENSERS_START:
            updatedState.error = null;
            updatedState.sourceItemsLoading = true;
            break;
        case types.GET_DISPENSERS_SUCCESS:
            updatedState.sourceItemsLoading = false;
            updatedState.sourceItems = get(action.payload.data, 'data', []);
            break;
        case types.GET_DISPENSERS_FAILED:
            updatedState.sourceItemsLoading = false;
            updatedState.error = action.error;
            break;

        case types.GET_ARAMARK_PROFIT_CENTERS_START:
            updatedState.aramark.profitCentersError = null;
            updatedState.aramark.profitCentersLoading = true;
            break;
        case types.GET_ARAMARK_PROFIT_CENTERS_SUCCESS:
            updatedState.aramark.profitCentersLoading = false;
            updatedState.aramark.profitCenters = get(
                action.payload,
                'data',
                []
            );
            break;
        case types.GET_ARAMARK_PROFIT_CENTERS_FAILED:
            updatedState.aramark.profitCentersLoading = false;
            updatedState.aramark.profitCentersError = action.error;
            break;
        default:
            break;
    }

    return updatedState;
};
