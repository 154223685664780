import _clone from 'lodash/clone';
import { actionTypes as types } from './constants';

const initialState = {
    list: [],
    loading: false,
    // recentlySPList: [],
    // recentlySPLoading: false,
};

export default (state = initialState, action) => {

    const updatedState = _clone(state);

    switch (action.type) {
        case types.FETCH_SEARCH_RESULTS_START:
            updatedState.loading = true;
            break;
        case types.FETCH_SEARCH_RESULTS_SUCCESS:
            if (action.payload.data && action.processGroups) {
                const results = [];
                action.payload.data.forEach(d =>
                    Object.keys(d)[0] && results.push(d));
                updatedState.list = results;
            } else if (action.payload.data) {
                updatedState.list = action.payload.data;
            } else {
                updatedState.list = [];
            }
            updatedState.loading = false;
            break;
        case types.FETCH_SEARCH_RESULTS_FAILED:
            updatedState.loading = false;
            break;
        // case types.FETCH_RECENTLY_SP_RESULTS_START:
        //     updatedState.recentlySPLoading = true;
        //     break;
        // case types.FETCH_RECENTLY_SP_RESULTS_SUCCESS:
        //     if (action.payload.data) {
        //         const results = [];
        //         action.payload.data.forEach(d =>
        //             Object.keys(d)[0] && results.push(d));
        //         updatedState.recentlySPList = results;
        //     } else {
        //         updatedState.recentlySPList = [];
        //     }
        //     updatedState.recentlySPLoading = false;
        //     break;
        // case types.FETCH_RECENTLY_SP_RESULTS_FAILED:
        //     updatedState.recentlySPLoading = false;
        //     break;
        default:
            break;
    }

    return updatedState;
}
