import { get, isEmpty, cloneDeep } from 'lodash';
import { actionTypes as types } from './constants';

const initialState = {
    list: [],
    single: {},
    error: null,
    loading: false,
    audience: [],
    audienceLoading: false,
    updateSuccess: false,
};

export default (state = initialState, action) => {
    const updatedState = cloneDeep(state);

    switch (action.type) {
        case types.GET_COLLECTIONS_START:
        case types.UPDATE_SINGLE_COLLECTION_START:
            updatedState.error = null;
            updatedState.loading = true;
            break;
        case types.REMOVE_SINGLE_COLLECTION_START:
        case types.CREATE_SINGLE_COLLECTION_START:
            updatedState.error = null;
            updatedState.loading = true;
            updatedState.single = {};
            break;
        case types.GET_COLLECTIONS_SUCCESS:
            updatedState.loading = false;
            updatedState.list = get(action.payload.data, 'data', []);
            break;
        case types.CREATE_SINGLE_COLLECTION_SUCCESS:
            updatedState.loading = false;
            if (!isEmpty(action.payload.data)) {
                updatedState.single = action.payload.data;
                updatedState.list = [...updatedState.list, action.payload.data];
            }
            break;
        case types.REMOVE_SINGLE_COLLECTION_SUCCESS:
            updatedState.loading = false;
            if (!isEmpty(action.payload.id)) {
                updatedState.single = {};
                updatedState.list = updatedState.list.filter(
                    ({ id }) => id !== action.payload.id
                );
            }
            break;
        case types.UPDATE_SINGLE_COLLECTION_SUCCESS:
            updatedState.loading = false;
            if (!isEmpty(action.payload.data)) {
                updatedState.list = updatedState.list.map((ent) =>
                    ent.id === action.payload.data.id
                        ? action.payload.data
                        : ent
                );
            }
            updatedState.updateSuccess = true;
            break;
        case types.GET_COLLECTIONS_FAILED:
        case types.UPDATE_SINGLE_COLLECTION_FAILED:
            updatedState.error = action.error;
            updatedState.loading = false;
            updatedState.updateSuccess = false;
            break;
        case types.UPDATE_SINGLE_COLLECTION_CLEAR:
            updatedState.loading = false;
            updatedState.updateSuccess = false;
            break;
        case types.REMOVE_SINGLE_COLLECTION_FAILED:
        case types.CREATE_SINGLE_COLLECTION_FAILED:
            updatedState.error = action.error;
            updatedState.loading = false;
            updatedState.single = {};
            break;
        case types.CLEAR_SINGLE_COLLECTION:
            updatedState.single = {};
            break;
        case types.GET_AUDIENCE_START:
            updatedState.audienceLoading = true;
            break;
        case types.GET_AUDIENCE_FAILED:
            updatedState.audienceLoading = false;
            break;
        case types.GET_AUDIENCE_SUCCESS:
            updatedState.audience = action.payload.data;
            updatedState.audienceLoading = false;
            break;
        default:
            break;
    }

    return updatedState;
};
