import { actionTypes as types } from './constants';

const initialState = {
    tab: 'cart',
    visible: false,
};

export default (state = initialState, action) => {
    const { tab } = action;
    switch (action.type) {
        case types.TOGGLE_VISIBILITY:
            if (tab) {
                return { ...state, tab, visible: !state.visible };
            }
            return { ...state, visible: !state.visible };
        case types.SET_TAB:
            return { ...state, tab };
        case types.HIDE_TAB:
            if (state.tab === tab) {
                return { ...state, tab, visible: false };
            }
            return state;
        case types.HIDE_ALL_TABS:
            return { ...state, visible: false };
        default:
            return state;
    }
};
