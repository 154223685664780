export const actionTypes = {
    GET_USERS_START: 'GET_USERS_START',
    GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
    GET_USERS_FAILED: 'GET_USERS_FAILED',

    GET_USER_START: 'GET_USER_START',
    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    GET_USER_FAILED: 'GET_USER_FAILED',

    UPDATE_USER_START: 'UPDATE_USER_START',
    UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILED: 'UPDATE_USER_FAILED',
    UPDATE_USER_CLEAR: 'UPDATE_USER_CLEAR',

    REMOVE_USER_START: 'REMOVE_USER_START',
    REMOVE_USER_SUCCESS: 'REMOVE_USER_SUCCESS',
    REMOVE_USER_FAILED: 'REMOVE_USER_FAILED',
    REMOVE_USER_CLEAR: 'REMOVE_USER_CLEAR',

    GET_GROUPS_START: 'GET_GROUPS_START',
    GET_GROUPS_SUCCESS: 'GET_GROUPS_SUCCESS',
    GET_GROUPS_FAILED: 'GET_GROUPS_FAILED',

    GET_ROLES_START: 'GET_ROLES_START',
    GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
    GET_ROLES_FAILED: 'GET_ROLES_FAILED',

    GET_ADDRESS_BOOKS_START: 'GET_ADDRESS_BOOKS_START',
    GET_ADDRESS_BOOKS_SUCCESS: 'GET_ADDRESS_BOOKS_SUCCESS',
    GET_ADDRESS_BOOKS_FAILED: 'GET_ADDRESS_BOOKS_FAILED',

    CREATE_CORPORATE_ADDRESS_BOOK_START: 'CREATE_CORPORATE_ADDRESS_BOOK_START',
    CREATE_CORPORATE_ADDRESS_BOOK_SUCCESS:
        'CREATE_CORPORATE_ADDRESS_BOOK_SUCCESS',
    CREATE_CORPORATE_ADDRESS_BOOK_FAILED:
        'CREATE_CORPORATE_ADDRESS_BOOK_FAILED',

    SEND_USER_WELCOME_EMAIL_START: 'SEND_USER_WELCOME_EMAIL_START',
    SEND_USER_WELCOME_EMAIL_SUCCESS: 'SEND_USER_WELCOME_EMAIL_SUCCESS',
    SEND_USER_WELCOME_EMAIL_FAILED: 'SEND_USER_WELCOME_EMAIL_FAILED',
};
