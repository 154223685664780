export const actionTypes = {
    LOGIN_START: 'LOGIN_START',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILED: 'LOGIN_FAILED',

    LOGOUT: 'LOGOUT',

    LOGIN_TOGGLE_MODAL: 'LOGIN_TOGGLE_MODAL',

    SET_USER: 'SET_USER',

    CLEAR_ERRORS: 'CLEAR_ERRORS',

    FORGOT_START: 'FORGOT_START',
    FORGOT_SUCCESS: 'FORGOT_SUCCESS',
    FORGOT_FAILED: 'FORGOT_FAILED',
    FORGOT_CLEAR_SUCCESS: 'FORGOT_CLEAR_SUCCESS',
    FORGOT_CLEAR_ERRORS: 'FORGOT_CLEAR_ERRORS',

    SUMMARY_START: 'SUMMARY_START',
    SUMMARY_FAILED: 'SUMMARY_FAILED',
    SUMMARY_SUCCESS: 'SUMMARY_SUCCESS',
};
