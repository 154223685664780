import React from 'react';
import { Route } from 'react-router';

import { WaitingComponent } from '../../../helpers/waitingComponent';
import lazy from '../../../helpers/lazy';

const SpecSheetsSelector = lazy(() => import('./'));
const SpecSheetsResults = lazy(() => import('./Results'));

const Router = ({ path }) => (
    <Route
        path={`${path}/specification-sheets`}
        render={({ match: { url } }) => (
            <>
                <Route
                    exact
                    path={`${url}/`}
                    component={WaitingComponent(SpecSheetsSelector)}
                />
                <Route
                    exact
                    path={`${url}/:brand(kogi|kens|sbr|recently|salad|all)`}
                    component={WaitingComponent(SpecSheetsResults)}
                />
            </>
        )}
    />
);

export default Router;
