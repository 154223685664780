import { takeLatest, put, call } from 'redux-saga/effects';
import get from 'lodash/get';

import ApiClient from '../../helpers/api';
import { actionTypes as types } from './constants';

const processOrder = function* (action) {
    try {
        const isAramarkOrder =
            get(action, 'payload.orderSupplyType') ===
            'aramark-dispenser-orders';

        const apiResponse = yield call(
            [ApiClient, ApiClient.post],
            isAramarkOrder
                ? '/api/process-aramark-order'
                : '/api/process-order',
            action.payload
        );
        yield put({ type: types.PROCESS_ORDER_SUCCESS, payload: apiResponse });
    } catch (err) {
        console.error('[processOrder] error:', JSON.stringify(err));
        yield put({ type: types.PROCESS_ORDER_FAILED, error: err });
    }
};

const loadSqueezeBottles = function* (action) {
    try {
        const apiData = yield call(
            [ApiClient, ApiClient.get],
            '/api/squeeze-bottles'
        );
        yield put({
            type: types.GET_SQUEEZE_BOTTLES_SUCCESS,
            payload: apiData,
        });
    } catch (error) {
        console.error('[loadSqueezeBottles] error: ', error);
        yield put({ type: types.GET_SQUEEZE_BOTTLES_FAILED, error });
    }
};

const loadDispensers = function* (action) {
    try {
        const apiData = yield call(
            [ApiClient, ApiClient.get],
            '/api/dispensers'
        );
        yield put({ type: types.GET_DISPENSERS_SUCCESS, payload: apiData });
    } catch (error) {
        console.error('[loadDispensers] error: ', error);
        yield put({ type: types.GET_DISPENSERS_FAILED, error });
    }
};

const loadAramarkProfitCenters = function* (action) {
    try {
        const apiData = yield call(
            [ApiClient, ApiClient.get],
            '/api/aramark/profit-centers'
        );
        yield put({
            type: types.GET_ARAMARK_PROFIT_CENTERS_SUCCESS,
            payload: apiData,
        });
    } catch (error) {
        console.error('[loadAramarkProfitCenters] error: ', error);
        yield put({ type: types.GET_ARAMARK_PROFIT_CENTERS_FAILED, error });
    }
};

export default [
    takeLatest(types.PROCESS_ORDER_START, processOrder),
    takeLatest(types.GET_SQUEEZE_BOTTLES_START, loadSqueezeBottles),
    takeLatest(types.GET_DISPENSERS_START, loadDispensers),
    takeLatest(
        types.GET_ARAMARK_PROFIT_CENTERS_START,
        loadAramarkProfitCenters
    ),
];
