import _clone from 'lodash/clone';
import { actionTypes as types } from './constants';

const initialState = {
    list: [],
    loading: false
};

export default (state = initialState, action) => {

    const updatedState = _clone(state);

    switch (action.type) {
        case types.FETCH_TOP_RESULTS_START:
            updatedState.loading = true;
            break;
        case types.FETCH_TOP_RESULTS_SUCCESS:
            if (action.payload.data) {
                const results = [];
                action.payload.data.forEach(d =>
                    Object.keys(d)[0] && results.push(d));
                updatedState.list = results;
            } else {
                updatedState.list = [];
            }
            updatedState.loading = false;
            break;
        case types.FETCH_TOP_RESULTS_FAILED:
            updatedState.loading = false;
            break;
        default:
            break;
    }

    return updatedState;
}
