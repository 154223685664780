import { actionTypes as types } from './constants';

export const login = ({ captcha, username, password, keepMe }) => ({
    type: types.LOGIN_START,
    payload: { captcha, username, password, keepMe },
});

export const logout = () => ({
    type: types.LOGOUT,
});

export const toggleLoginModal = () => ({
    type: types.LOGIN_TOGGLE_MODAL,
});

export const setUser = (user) => ({
    payload: user,
    type: types.SET_USER,
});

export const clearErrors = () => ({
    type: types.CLEAR_ERRORS,
});

export const forgot = ({ email, captcha, returnUrl, resetUrl }) => ({
    type: types.FORGOT_START,
    payload: { email, captcha, returnUrl, resetUrl },
});

export const clearForgotSuccess = () => ({
    type: types.FORGOT_CLEAR_SUCCESS,
});

export const clearForgotErrors = () => ({
    type: types.FORGOT_CLEAR_ERRORS,
});

export const summary = (user) => ({
    type: types.SUMMARY_START,
    payload: user,
});
