export const actionTypes = {
    GET_SQUEEZE_BOTTLE_ORDERS_START: 'GET_SQUEEZE_BOTTLE_ORDERS_START',
    GET_SQUEEZE_BOTTLE_ORDERS_SUCCESS: 'GET_SQUEEZE_BOTTLE_ORDERS_SUCCESS',
    GET_SQUEEZE_BOTTLE_ORDERS_FAILED: 'GET_SQUEEZE_BOTTLE_ORDERS_FAILED',

    UPDATE_SQUEEZE_BOTTLE_ORDER_START: 'UPDATE_SQUEEZE_BOTTLE_ORDER_START',
    UPDATE_SQUEEZE_BOTTLE_ORDER_SUCCESS: 'UPDATE_SQUEEZE_BOTTLE_ORDER_SUCCESS',
    UPDATE_SQUEEZE_BOTTLE_ORDER_FAILED: 'UPDATE_SQUEEZE_BOTTLE_ORDER_FAILED',
    UPDATE_SQUEEZE_BOTTLE_ORDER_CLEAR: 'UPDATE_SQUEEZE_BOTTLE_ORDER_CLEAR',
};
