export const actionTypes = {
    GET_WINGMAN_STUDENTS_START:
        'GET_WINGMAN_STUDENTS_START',
    GET_WINGMAN_STUDENTS_SUCCESS:
        'GET_WINGMAN_STUDENTS_SUCCESS',
    GET_WINGMAN_STUDENTS_FAILED:
        'GET_WINGMAN_STUDENTS_FAILED',

    GET_WINGMAN_STUDENT_START: 'GET_WINGMAN_STUDENT_START',
    GET_WINGMAN_CLEAR_STUDENTS_DETAILS_STATE:
        'GET_WINGMAN_CLEAR_STUDENTS_DETAILS_STATE',
    GET_WINGMAN_STUDENT_SUCCESS:
        'GET_WINGMAN_STUDENT_SUCCESS',
    GET_WINGMAN_STUDENT_FAILED:
        'GET_WINGMAN_STUDENT_FAILED',

    UPDATE_WINGMAN_STUDENT_START:
        'UPDATE_WINGMAN_STUDENT_START',
    UPDATE_WINGMAN_STUDENT_SUCCESS:
        'UPDATE_WINGMAN_STUDENT_SUCCESS',
    UPDATE_WINGMAN_STUDENT_FAILED:
        'UPDATE_WINGMAN_STUDENT_FAILED',
    UPDATE_WINGMAN_STUDENT_CLEAR:
        'UPDATE_WINGMAN_STUDENT_CLEAR',

    REMOVE_WINGMAN_STUDENT_START:
        'REMOVE_WINGMAN_STUDENT_START',
    REMOVE_WINGMAN_STUDENT_SUCCESS:
        'REMOVE_WINGMAN_STUDENT_SUCCESS',
    REMOVE_WINGMAN_STUDENT_FAILED:
        'REMOVE_WINGMAN_STUDENT_FAILED',
    REMOVE_WINGMAN_STUDENT_CLEAR:
        'REMOVE_WINGMAN_STUDENT_CLEAR',
};
