import _cloneDeep from 'lodash/cloneDeep';
import { actionTypes as types } from './constants';

const initialState = {
    items: [],
    error: null,
    loading: true,
    errorUpdate: null,
    successUpdate: null,
    loadingUpdate: false,
    errorRemove: null,
    successRemove: null,
    loadingRemove: false,
    details: null,
    errorDetails: null,
    successDetails: null,
    loadingDetails: false,
};

export default (state = initialState, action) => {
    const updatedState = _cloneDeep(state);

    switch (action.type) {
        case types.GET_ARAMARK_PCS_START:
            updatedState.loading = true;
            break;

        case types.GET_ARAMARK_PCS_SUCCESS:
            updatedState.loading = false;
            updatedState.items =
                action.payload.data && action.payload.data.length > 0
                    ? action.payload.data
                    : [];
            break;

        case types.GET_ARAMARK_PCS_FAILED:
            updatedState.loading = false;
            updatedState.error = action.error;
            break;

        case types.GET_ARAMARK_PC_START:
            updatedState.loadingDetails = true;
            updatedState.errorUpdate = false;
            updatedState.errorDetails = false;
            updatedState.successUpdate = false;
            break;

        case types.GET_ARAMARK_PC_SUCCESS:
            updatedState.loadingDetails = false;
            updatedState.details = action.payload.data;
            break;

        case types.GET_ARAMARK_PC_FAILED:
            updatedState.loadingDetails = false;
            updatedState.errorDetails = action.error;
            break;

        case types.UPDATE_ARAMARK_PC_START:
            updatedState.loadingUpdate = true;
            break;

        case types.UPDATE_ARAMARK_PC_SUCCESS:
            updatedState.loadingUpdate = false;
            updatedState.errorUpdate = false;
            updatedState.successUpdate = true;
            updatedState.details = action.payload.data;
            break;

        case types.UPDATE_ARAMARK_PC_FAILED:
            updatedState.loadingUpdate = false;
            updatedState.errorUpdate = action.error;
            break;

        case types.REMOVE_ARAMARK_PC_START:
            updatedState.loadingRemove = true;
            break;

        case types.REMOVE_ARAMARK_PC_SUCCESS:
            const { itemId } = action.payload;
            updatedState.loadingRemove = false;
            updatedState.errorRemove = false;
            updatedState.successRemove = true;
            updatedState.items = updatedState.items.filter(
                (it) => it.id !== itemId
            );
            break;

        case types.REMOVE_ARAMARK_PC_FAILED:
            updatedState.loadingRemove = false;
            updatedState.errorRemove = action.error;
            break;

        case types.REMOVE_ARAMARK_PC_CLEAR:
            updatedState.successRemove = false;
            updatedState.errorRemove = false;
            break;

        default:
            break;
    }

    return updatedState;
};
