export const actionTypes = {
    SET_ANALYTICS_SALES_FILTERS: 'SET_ANALYTICS_SALES_FILTERS',
    GET_ANALYTICS_SALES_METRICS_START: 'GET_ANALYTICS_SALES_METRICS_START',
    GET_ANALYTICS_SALES_METRICS_SUCCESS: 'GET_ANALYTICS_SALES_METRICS_SUCCESS',
    GET_ANALYTICS_SALES_METRICS_FAILED: 'GET_ANALYTICS_SALES_METRICS_FAILED',
    GET_ANALYTICS_SALES_METRICS_CLEAR_ERROR:
        'GET_ANALYTICS_SALES_METRICS_CLEAR_ERROR',
    GET_ANALYTICS_SALES_ONDMND_START: 'GET_ANALYTICS_SALES_ONDMND_START',
    GET_ANALYTICS_SALES_ONDMND_SUCCESS: 'GET_ANALYTICS_SALES_ONDMND_SUCCESS',
    GET_ANALYTICS_SALES_ONDMND_FAILED: 'GET_ANALYTICS_SALES_ONDMND_FAILED',
};
