import React from 'react';
import { Route } from 'react-router';

import { WaitingComponent } from '../../../helpers/waitingComponent';
import lazy from '../../../helpers/lazy';

const ProductHistoryResults = lazy(() => import('./Results'));
const ProductHistoryDetails = lazy(() => import('./Details'));

const Router = ({ path }) => {
    return (
        <Route
            path={`${path}/product-history`}
            render={({ match: { url } }) => (
                <>
                    <Route
                        exact
                        path={`${url}/`}
                        component={WaitingComponent(ProductHistoryResults)}
                    />
                    <Route
                        exact
                        key="productHistoryDetails"
                        path={`${url}/:productId`}
                        component={WaitingComponent(ProductHistoryDetails)}
                    />
                </>
            )}
        />
    );
};

export default Router;
