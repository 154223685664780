import { takeLatest, put, call } from 'redux-saga/effects';

import ApiClient from '../../../../helpers/api';
import { actionTypes as types } from './constants';

const getUsers = function* (action) {
    try {
        const data = yield call([ApiClient, ApiClient.get], '/api/users');
        yield put({
            type: types.GET_USERS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        console.error('[getUsers] error: ', error);
        yield put({ type: types.GET_USERS_FAILED, error });
    }
};

const getUser = function* (action) {
    try {
        const { userId } = action.payload;
        const data = yield call(
            [ApiClient, ApiClient.get],
            `/api/users/${userId}`
        );
        yield put({
            type: types.GET_USER_SUCCESS,
            payload: data,
        });
    } catch (error) {
        console.error('[getUser] error: ', error);
        yield put({ type: types.GET_USER_FAILED, error });
    }
};

const updateUser = function* (action) {
    try {
        const { userId, data } = action.payload;

        const apiData = yield call(
            [ApiClient, ApiClient.put],
            `/api/users/${userId}`,
            {
                ...data,
            }
        );
        yield put({ type: types.UPDATE_USER_SUCCESS, payload: apiData });
    } catch (error) {
        console.error('[updateUser] error: ', error);
        yield put({ type: types.UPDATE_USER_FAILED, error });
    }
};

const removeUser = function* (action) {
    try {
        const { userId } = action.payload;
        const data = yield call(
            [ApiClient, ApiClient.delete],
            `/api/users/${userId}`
        );
        yield put({
            type: types.REMOVE_USER_SUCCESS,
            payload: { ...data, userId },
        });
    } catch (error) {
        console.error('[removeUser] error: ', error);
        yield put({ type: types.REMOVE_USER_FAILED, error });
    }
};

const getGroups = function* (action) {
    try {
        const apiData = yield call([ApiClient, ApiClient.get], '/api/audience');
        yield put({
            type: types.GET_GROUPS_SUCCESS,
            payload: apiData,
        });
    } catch (error) {
        console.error('[getGroups] error: ', error);
        yield put({ type: types.GET_GROUPS_FAILED, error });
    }
};

const getRoles = function* (action) {
    try {
        const apiData = yield call([ApiClient, ApiClient.get], '/api/roles');
        yield put({
            type: types.GET_ROLES_SUCCESS,
            payload: apiData,
        });
    } catch (error) {
        console.error('[getRoles] error: ', error);
        yield put({ type: types.GET_ROLES_FAILED, error });
    }
};

const getCorporateAddressBooks = function* (action) {
    try {
        const apiData = yield call(
            [ApiClient, ApiClient.get],
            '/api/corporate-address-books'
        );
        yield put({
            type: types.GET_ADDRESS_BOOKS_SUCCESS,
            payload: apiData,
        });
    } catch (error) {
        console.error('[getCorporateAddressBooks] error: ', error);
        yield put({ type: types.GET_ADDRESS_BOOKS_FAILED, error });
    }
};

const createCorporateAddressBook = function* (action) {
    try {
        const data = action.payload;

        const apiData = yield call(
            [ApiClient, ApiClient.put],
            `/api/corporate-address-books`,
            {
                ...data,
            }
        );
        yield put({
            type: types.CREATE_CORPORATE_ADDRESS_BOOK_SUCCESS,
            payload: apiData,
        });
    } catch (error) {
        console.error('[createCorporateAddressBook] error: ', error);
        yield put({ type: types.CREATE_CORPORATE_ADDRESS_BOOK_FAILED, error });
    }
};

const sendUserWelcomeEmail = function* (action) {
    try {
        const apiData = yield call(
            [ApiClient, ApiClient.post],
            '/api/users/send-welcome-email',
            {...action.payload}
        );
        yield put({ type: types.SEND_USER_WELCOME_EMAIL_SUCCESS, payload: apiData });
    } catch (error) {
        console.error('[sendUserWelcomeEmail] error: ', error);
        yield put({ type: types.SEND_USER_WELCOME_EMAIL_FAILED, error });
    }
};

export default [
    takeLatest(types.GET_USERS_START, getUsers),
    takeLatest(types.GET_USER_START, getUser),
    takeLatest(types.UPDATE_USER_START, updateUser),
    takeLatest(types.REMOVE_USER_START, removeUser),
    takeLatest(types.GET_GROUPS_START, getGroups),
    takeLatest(types.GET_ROLES_START, getRoles),
    takeLatest(types.GET_ADDRESS_BOOKS_START, getCorporateAddressBooks),
    takeLatest(
        types.CREATE_CORPORATE_ADDRESS_BOOK_START,
        createCorporateAddressBook
    ),
    takeLatest(
        types.SEND_USER_WELCOME_EMAIL_START,
        sendUserWelcomeEmail
    ),
];
