import { takeLatest, put, call } from 'redux-saga/effects';
import moment from 'moment';

import ApiClient from '../../../helpers/api';
import { actionTypes as types, PHASE_STATUSES } from './constants';

const loadSalesLeads = function* (action) {
    try {
        const { userId, filters, page, groupData } = action;

        const requestUrl = userId
            ? `/api/sample-requests?userId=${userId}`
            : '/api/sample-requests';

        const apiData = yield call([ApiClient, ApiClient.post], requestUrl, {
            filters,
            isArchived: false,
            page,
            groupData,
        });

        yield put({
            type: types.GET_SALES_LEADS_SUCCESS,
            payload: apiData,
            groupData,
        });
    } catch (error) {
        console.error('[loadSalesLeads] error: ', error);
        yield put({ type: types.GET_SALES_LEADS_FAILED, error });
    }
};

const loadSalesLeadsHistory = function* (action) {
    try {
        const { filters } = action;

        const apiData = yield call(
            [ApiClient, ApiClient.post],
            '/api/sample-requests/history',
            {
                filters,
                isArchived: false,
            }
        );

        yield put({
            type: types.GET_SALES_LEADS_HISTORY_SUCCESS,
            payload: apiData,
        });
    } catch (error) {
        console.error('[loadSalesLeadsHistory] error: ', error);
        yield put({ type: types.GET_SALES_LEADS_HISTORY_FAILED, error });
    }
};

const loadSalesLeadsInflow = function* (action) {
    try {
        const { filters } = action;

        const apiData = yield call(
            [ApiClient, ApiClient.post],
            '/api/sample-requests/inflow-data',
            {
                filters,
            }
        );

        yield put({
            type: types.GET_SALES_LEADS_INFLOW_SUCCESS,
            payload: apiData,
        });
    } catch (error) {
        console.error('[loadSalesLeadsInflow] error: ', error);
        yield put({ type: types.GET_SALES_LEADS_INFLOW_FAILED, error });
    }
};

const loadArchivedSalesLeads = function* (action) {
    try {
        const { userId, filters, page, groupData } = action;

        const requestUrl = userId
            ? `/api/sample-requests?userId=${userId}`
            : '/api/sample-requests';

        const apiData = yield call([ApiClient, ApiClient.post], requestUrl, {
            filters,
            isArchived: true,
            page,
            groupData,
        });

        yield put({
            type: types.GET_ARCHIVED_SALES_LEADS_SUCCESS,
            payload: apiData,
            groupData,
        });
    } catch (error) {
        console.error('[loadArchivedSalesLeads] error: ', error);
        yield put({ type: types.GET_ARCHIVED_SALES_LEADS_FAILED, error });
    }
};

const loadSalesLead = function* (action) {
    try {
        const { id } = action;
        const apiData = yield call(
            [ApiClient, ApiClient.get],
            `/api/sample-requests/${id}`
        );

        if (
            apiData.data.phaseStatus === PHASE_STATUSES[2].value &&
            moment(apiData.data.timeModified) <
                moment().subtract(30, 'days').startOf('day')
        ) {
            apiData.data.phaseStatus = PHASE_STATUSES[3].value;
        }
        yield put({ type: types.GET_SALES_LEAD_SUCCESS, payload: apiData });
    } catch (error) {
        console.error('[loadSalesLead] error: ', error);
        yield put({ type: types.GET_SALES_LEAD_FAILED, error });
    }
};

const updateSalesLead = function* (action) {
    try {
        const {
            id,
            phaseStatus,
            userName,
            accountType,
            salesManagerId,
            dvpOrRegionalSalesManagerId,
            coreMarketLeaderId,
            priorityLead,
            marketingMessage,
            leadHistory,
            phase1,
            phase2,
            phase3,
            phase4,
        } = action.payload;
        const apiData = yield call(
            [ApiClient, ApiClient.put],
            `/api/sample-requests/${id}`,
            {
                phaseStatus,
                userName,
                accountType,
                salesManagerId,
                dvpOrRegionalSalesManagerId,
                coreMarketLeaderId,
                priorityLead,
                marketingMessage,
                leadHistory,
                phase1,
                phase2,
                phase3,
                phase4,
            }
        );
        yield put({ type: types.UPDATE_SALES_LEAD_SUCCESS, payload: apiData });
    } catch (error) {
        console.error('[updateSalesLead] error: ', error);
        yield put({ type: types.UPDATE_SALES_LEAD_FAILED, error });
    }
};

const archiveSalesLead = function* (action) {
    try {
        const { id, userName } = action.payload;
        const apiData = yield call(
            [ApiClient, ApiClient.post],
            `/api/sample-requests/${id}/archive`,
            {
                userName,
            }
        );
        yield put({ type: types.ARCHIVE_SALES_LEAD_SUCCESS, payload: apiData });
    } catch (error) {
        console.error('[updateSalesLead] error: ', error);
        yield put({ type: types.ARCHIVE_SALES_LEAD_FAILED, error });
    }
};

const updateAndArchiveSalesLead = function* (action) {
    try {
        const {
            id,
            phaseStatus,
            userName,
            accountType,
            salesManagerId,
            priorityLead,
            marketingMessage,
            leadHistory,
            phase1,
            phase2,
            phase3,
            phase4,

            // can be set on this stage if sales manager is a core market leader
            coreMarketLeaderId,
        } = action.payload;

        const updateApiData = yield call(
            [ApiClient, ApiClient.put],
            `/api/sample-requests/${id}`,
            {
                phaseStatus,
                userName,
                accountType,
                salesManagerId,
                priorityLead,
                marketingMessage,
                leadHistory,
                phase1,
                phase2,
                phase3,
                phase4,

                coreMarketLeaderId,
            }
        );

        const archiveApiData = yield call(
            [ApiClient, ApiClient.post],
            `/api/sample-requests/${id}/archive`,
            {
                userName,
            }
        );
        yield put({
            type: types.UPDATE_ARCHIVE_SALES_LEAD_SUCCESS,
            payload: { update: updateApiData, archive: archiveApiData },
        });
    } catch (error) {
        console.error('[updateArchiveSalesLead] error: ', error);
        yield put({ type: types.UPDATE_ARCHIVE_SALES_LEAD_FAILED, error });
    }
};

const fetchManagers = function* (action) {
    try {
        const apiData = yield call([ApiClient, ApiClient.get], '/api/managers');

        yield put({ type: types.GET_MANAGERS_SUCCESS, payload: apiData });
    } catch (error) {
        console.error('[loadSalesLead] error: ', error);
        yield put({ type: types.GET_MANAGERS_FAILED, error });
    }
};

const sendSalesLead = function* (action) {
    try {
        if (action.payload.update) {
            const {
                id,
                phaseStatus,
                userName,
                accountType,
                salesManagerId,
                dvpOrRegionalSalesManagerId,
                coreMarketLeaderId,
                priorityLead,
                marketingMessage,
                leadHistory,
                phase1,
                phase2,
                phase3,
                phase4,
            } = action.payload.update;
            const apiData = yield call(
                [ApiClient, ApiClient.put],
                `/api/sample-requests/${id}`,
                {
                    phaseStatus,
                    userName,
                    accountType,
                    salesManagerId,
                    dvpOrRegionalSalesManagerId,
                    coreMarketLeaderId,
                    priorityLead,
                    marketingMessage,
                    leadHistory,
                    phase1,
                    phase2,
                    phase3,
                    phase4,
                }
            );
            yield put({
                type: types.UPDATE_SALES_LEAD_SUCCESS,
                payload: apiData,
            });
            delete action.payload.update;
        }

        const apiResponse = yield call(
            [ApiClient, ApiClient.post],
            '/api/send-lead',
            action.payload
        );
        yield put({ type: types.SEND_LEAD_SUCCESS, payload: apiResponse });
    } catch (err) {
        console.error('[sendSalesLead] error:', JSON.stringify(err));
        yield put({ type: types.SEND_LEAD_FAILED, error: err });
    }
};

const loadProducts = function* (action) {
    try {
        const apiData = yield call(
            [ApiClient, ApiClient.get],
            '/api/all-products'
        );
        yield put({ type: types.GET_PRODUCTS_SUCCESS, payload: apiData });
    } catch (error) {
        console.error('[fetchAllProducts] error: ', error);
        yield put({ type: types.GET_PRODUCTS_FAILED, error });
    }
};

export default [
    takeLatest(types.GET_SALES_LEADS_START, loadSalesLeads),
    takeLatest(types.GET_SALES_LEADS_HISTORY_START, loadSalesLeadsHistory),
    takeLatest(types.GET_SALES_LEADS_INFLOW_START, loadSalesLeadsInflow),
    takeLatest(types.GET_ARCHIVED_SALES_LEADS_START, loadArchivedSalesLeads),
    takeLatest(types.GET_SALES_LEAD_START, loadSalesLead),
    takeLatest(types.UPDATE_SALES_LEAD_START, updateSalesLead),
    takeLatest(types.ARCHIVE_SALES_LEAD_START, archiveSalesLead),
    takeLatest(
        types.UPDATE_ARCHIVE_SALES_LEAD_START,
        updateAndArchiveSalesLead
    ),
    takeLatest(types.GET_MANAGERS_START, fetchManagers),
    takeLatest(types.SEND_LEAD_START, sendSalesLead),
    takeLatest(types.GET_PRODUCTS_START, loadProducts),
];
