import _cloneDeep from 'lodash/cloneDeep';
import { actionTypes as types } from './constants';

const initialState = {
    contacts: [],
    corporateContacts: [],
    error: null,
    loading: true,
    errorUpdate: null,
    successUpdate: null,
    loadingUpdate: false,
};

export default (state = initialState, action) => {
    const updatedState = _cloneDeep(state);

    switch (action.type) {
        case types.GET_CONTACTS_START:
            updatedState.loading = true;
            break;
        case types.GET_CONTACTS_SUCCESS:
            updatedState.loading = false;
            updatedState.contacts =
                action.payload.data && action.payload.data.contacts.length > 0
                    ? action.payload.data.contacts
                    : [];
            break;
        case types.GET_CONTACTS_FAILED:
            updatedState.loading = false;
            updatedState.error = action.error;
            break;
        case types.GET_CORPORATE_CONTACTS_SUCCESS:
            updatedState.loading = false;
            updatedState.corporateContacts =
                action.payload.data && action.payload.data.contacts.length > 0
                    ? action.payload.data.contacts
                    : [];
            break;
        case types.UPDATE_CONTACTS_START:
            updatedState.loadingUpdate = true;
            break;
        case types.UPDATE_CONTACTS_SUCCESS:
            updatedState.loadingUpdate = false;
            updatedState.successUpdate = true;
            updatedState.contacts = action.payload.data.contacts;
            break;
        case types.UPDATE_CORPORATE_CONTACTS_SUCCESS:
            updatedState.loadingUpdate = false;
            updatedState.successUpdate = true;
            updatedState.corporateContacts = action.payload.data.contacts;
            break;
        case types.UPDATE_CONTACTS_FAILED:
            updatedState.loadingUpdate = false;
            updatedState.errorUpdate = action.error;
            break;
        case types.UPDATE_CONTACTS_CLEAR:
            updatedState.successUpdate = false;
            break;

        default:
            break;
    }

    return updatedState;
};
