import { useMemo, useState, useEffect } from 'react';

export const useCheckedCounter = (checkedItems, withoutSubs) => {
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        let count = 0;

        if (withoutSubs) {
            for (const ciKey in checkedItems) {
                for (const id in checkedItems[ciKey]) {
                    if (checkedItems[ciKey][id]) {
                        count++;
                    }
                }
            }
        } else {
            for (const ciKey in checkedItems) {
                for (const ciSubKey in checkedItems[ciKey]['subs']) {
                    for (const id in checkedItems[ciKey]['subs'][ciSubKey][
                        'subs'
                    ]) {
                        const ent =
                            checkedItems[ciKey]['subs'][ciSubKey]['subs'][id];
                        if (ent) {
                            if (typeof ent === 'object') {
                                for (const subId in ent) {
                                    if (subId !== 'values' && ent[subId]) {
                                        count++;
                                    }
                                }
                            } else {
                                count++;
                            }
                        }
                    }
                }
            }
        }

        setCounter(count);
    }, [checkedItems]);

    return useMemo(() => counter);
};
