export const actionTypes = {
    ADD_ITEM: 'ADD_ITEM',
    REMOVE_ITEM: 'REMOVE_ITEM',
    CLEAN: 'CLEAN',
    SET_ITEMS: 'SET_ITEMS',

    FIND_RELATED_START: 'FIND_RELATED_START',
    FIND_RELATED_SUCCESS: 'FIND_RELATED_SUCCESS',
    FIND_RELATED_FAILED: 'FIND_RELATED_FAILED',

    SEND_ASSETS_START: 'SEND_ASSETS_START',
    SEND_ASSETS_SUCCESS: 'SEND_ASSETS_SUCCESS',
    SEND_ASSETS_FAILED: 'SEND_ASSETS_FAILED',
    SEND_ASSETS_CLEAR: 'SEND_ASSETS_CLEAR'
};
