import React from 'react';
import { Route } from 'react-router';
import get from 'lodash/get';

import { WaitingComponent } from '../../../helpers/waitingComponent';
import AuthHelper from '../../../helpers/auth';
import lazy from '../../../helpers/lazy';

const KensmMetricsHomePage = lazy(() =>
    import('./clients/kens/pages/MetricsHomePage')
);
const KensCoreMetricsPage = lazy(() =>
    import('./clients/kens/pages/CoreMetricsPage')
);
const KensPageLevelMetricsPage = lazy(() =>
    import('./clients/kens/pages/PageLevelMetricsPage')
);
const KensPaidTrafficMetricsPage = lazy(() =>
    import('./clients/kens/pages/PaidTrafficMetricsPage')
);

const SalesMetricsPage = lazy(() =>
    import('./clients/sales/pages/SalesMetricsPage')
);

const Router = ({ path }) => {
    const user = AuthHelper.getUser();

    const groupid = get(user, 'group._id');

    const isAdmin = process.env.REACT_APP_ADMIN_GROUP_ID === groupid;

    const isAgency = process.env.REACT_APP_AD_AGENCY_GROUP_ID === groupid;

    return (
        <Route
            path={`${path}/analytics`}
            render={({ match: { url } }) => (
                <>
                    {isAdmin || isAgency ? (
                        <>
                            <Route
                                exact
                                path={`${url}/`}
                                component={WaitingComponent(
                                    KensmMetricsHomePage
                                )}
                            />
                            <Route
                                exact
                                path={`${url}/site-metrics`}
                                component={WaitingComponent(
                                    KensCoreMetricsPage
                                )}
                            />
                            <Route
                                exact
                                path={`${url}/page-level-metrics`}
                                component={WaitingComponent(
                                    KensPageLevelMetricsPage
                                )}
                            />
                            <Route
                                exact
                                path={`${url}/paid-traffic-metrics`}
                                component={WaitingComponent(
                                    KensPaidTrafficMetricsPage
                                )}
                            />
                        </>
                    ) : null}
                    {isAdmin ? (
                        <Route
                            exact
                            path={`${url}/sales`}
                            component={WaitingComponent(SalesMetricsPage)}
                        />
                    ) : null}
                </>
            )}
        />
    );
};

export default Router;
