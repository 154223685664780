import { takeLatest, put, call } from 'redux-saga/effects';

import ApiClient from '../../../../helpers/api';
import { convertFormValuesToFilters } from '../helpers';

const getAnalyticsData = function* (action) {
    try {
        const payload = {
            client: action.client,
            reports: action.reports,
            filters: convertFormValuesToFilters(action.filters, action.client),
        };

        const apiData = yield call(
            [ApiClient, ApiClient.post],
            '/api/analytics',
            { ...payload }
        );

        yield put({
            type: action.successType,
            response: apiData,
            filters: action.filters,
            key: action.key,
            page: action.page,
        });
    } catch (error) {
        console.error('[getAnalyticsData] error: ', error);

        yield put({
            type: action.errorType,
            payload: 'Something went wrong...',
            key: action.key,
            page: action.page,
        });
    }
};

export default [
    takeLatest(
        ({ type }) => type.match(/^GET_ANALYTICS_.*_START$/),
        getAnalyticsData
    ),
];
