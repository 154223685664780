export const actionTypes = {
    GET_WINGMAN_SALESFORMS_START:
        'GET_WINGMAN_SALESFORMS_START',
    GET_WINGMAN_SALESFORMS_SUCCESS:
        'GET_WINGMAN_SALESFORMS_SUCCESS',
    GET_WINGMAN_SALESFORMS_FAILED:
        'GET_WINGMAN_SALESFORMS_FAILED',

    GET_WINGMAN_SALESFORM_START: 'GET_WINGMAN_SALESFORM_START',
    GET_WINGMAN_CLEAR_SALESFORMS_DETAILS_STATE:
        'GET_WINGMAN_CLEAR_SALESFORMS_DETAILS_STATE',
    GET_WINGMAN_SALESFORM_SUCCESS:
        'GET_WINGMAN_SALESFORM_SUCCESS',
    GET_WINGMAN_SALESFORM_FAILED:
        'GET_WINGMAN_SALESFORM_FAILED',

    UPDATE_WINGMAN_SALESFORM_START:
        'UPDATE_WINGMAN_SALESFORM_START',
    UPDATE_WINGMAN_SALESFORM_SUCCESS:
        'UPDATE_WINGMAN_SALESFORM_SUCCESS',
    UPDATE_WINGMAN_SALESFORM_FAILED:
        'UPDATE_WINGMAN_SALESFORM_FAILED',
    UPDATE_WINGMAN_SALESFORM_CLEAR:
        'UPDATE_WINGMAN_SALESFORM_CLEAR',

    REMOVE_WINGMAN_SALESFORM_START:
        'REMOVE_WINGMAN_SALESFORM_START',
    REMOVE_WINGMAN_SALESFORM_SUCCESS:
        'REMOVE_WINGMAN_SALESFORM_SUCCESS',
    REMOVE_WINGMAN_SALESFORM_FAILED:
        'REMOVE_WINGMAN_SALESFORM_FAILED',
    REMOVE_WINGMAN_SALESFORM_CLEAR:
        'REMOVE_WINGMAN_SALESFORM_CLEAR',
};
