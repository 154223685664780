import { takeLatest, put, call } from 'redux-saga/effects';

import ApiClient from '../../../../helpers/api';
import { actionTypes as types } from './constants';

const getItems = function* () {
    try {
        const data = yield call(
            [ApiClient, ApiClient.get],
            '/api/aramark/profit-centers'
        );
        yield put({
            type: types.GET_ARAMARK_PCS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        console.error('[getItems] error: ', error);
        yield put({ type: types.GET_ARAMARK_PCS_FAILED, error });
    }
};

const getItem = function* (action) {
    try {
        const { itemId } = action.payload;
        const data = yield call(
            [ApiClient, ApiClient.get],
            `/api/aramark/profit-centers/${itemId}`
        );
        yield put({
            type: types.GET_ARAMARK_PC_SUCCESS,
            payload: data,
        });
    } catch (error) {
        console.error('[getItem] error: ', error);
        yield put({ type: types.GET_ARAMARK_PC_FAILED, error });
    }
};

const updateItem = function* (action) {
    try {
        const { itemId, data } = action.payload;

        const apiData = yield call(
            [ApiClient, ApiClient.put],
            `/api/aramark/profit-centers/${itemId}`,
            {
                ...data,
            }
        );
        yield put({ type: types.UPDATE_ARAMARK_PC_SUCCESS, payload: apiData });
    } catch (error) {
        console.error('[updateItem] error: ', error);
        yield put({ type: types.UPDATE_ARAMARK_PC_FAILED, error });
    }
};

const removeItem = function* (action) {
    try {
        const { itemId } = action.payload;
        const data = yield call(
            [ApiClient, ApiClient.delete],
            `/api/aramark/profit-centers/${itemId}`
        );
        yield put({
            type: types.REMOVE_ARAMARK_PC_SUCCESS,
            payload: { ...data, itemId },
        });
    } catch (error) {
        console.error('[removeItem] error: ', error);
        yield put({ type: types.REMOVE_ARAMARK_PC_FAILED, error });
    }
};

export default [
    takeLatest(types.GET_ARAMARK_PCS_START, getItems),
    takeLatest(types.GET_ARAMARK_PC_START, getItem),
    takeLatest(types.UPDATE_ARAMARK_PC_START, updateItem),
    takeLatest(types.REMOVE_ARAMARK_PC_START, removeItem),
];
