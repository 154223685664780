import { takeLatest, put, call } from 'redux-saga/effects';

import ApiClient from '../../../helpers/api';
import { actionTypes as types } from './constants';

const getProducts = function* (action) {
    try {
        const data = yield call([ApiClient, ApiClient.get], '/api/products');
        yield put({
            type: types.GET_PRODUCTS_HISTORY_SUCCESS,
            payload: data,
        });
    } catch (error) {
        console.error('[getProducts] error: ', error);
        yield put({ type: types.GET_PRODUCTS_HISTORY_FAILED, error });
    }
};

const getProduct = function* (action) {
    try {
        const { productId } = action.payload;
        const data = yield call(
            [ApiClient, ApiClient.get],
            `/api/products/${productId}`
        );
        yield put({
            type: types.GET_PRODUCT_HISTORY_SUCCESS,
            payload: data,
        });
    } catch (error) {
        console.error('[getProduct] error: ', error);
        yield put({ type: types.GET_PRODUCT_HISTORY_FAILED, error });
    }
};

const getProductHistory = function* (action) {
    try {
        const { productId } = action.payload;
        const data = yield call(
            [ApiClient, ApiClient.get],
            `/api/products/${productId}/history`
        );
        yield put({
            type: types.GET_PRODUCT_HISTORY_DETAILS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        console.error('[getProductHistory] error: ', error);
        yield put({ type: types.GET_PRODUCT_HISTORY_DETAILS_FAILED, error });
    }
};

const getProductHistoryExport = function* (action) {
    try {
        const { dateRange } = action.payload;
        const data = yield call(
            [ApiClient, ApiClient.post],
            '/api/products/history/export',
            { dateRange }
        );
        yield put({
            type: types.GET_PRODUCT_HISTORY_EXPORT_SUCCESS,
            payload: data,
        });
    } catch (error) {
        console.error('[getProductHistoryExport] error: ', error);
        yield put({ type: types.GET_PRODUCT_HISTORY_EXPORT_FAILED, error });
    }
};

export default [
    takeLatest(types.GET_PRODUCTS_HISTORY_START, getProducts),
    takeLatest(types.GET_PRODUCT_HISTORY_START, getProduct),
    takeLatest(types.GET_PRODUCT_HISTORY_DETAILS_START, getProductHistory),
    takeLatest(types.GET_PRODUCT_HISTORY_EXPORT_START, getProductHistoryExport),
];
