export const actionTypes = {
    GET_CONTACTS_START: 'GET_CONTACTS_START',
    GET_CONTACTS_SUCCESS: 'GET_CONTACTS_SUCCESS',
    GET_CONTACTS_FAILED: 'GET_CONTACTS_FAILED',

    UPDATE_CONTACTS_START: 'UPDATE_CONTACTS_START',
    UPDATE_CONTACTS_SUCCESS: 'UPDATE_CONTACTS_SUCCESS',
    UPDATE_CONTACTS_FAILED: 'UPDATE_CONTACTS_FAILED',
    UPDATE_CONTACTS_CLEAR: 'UPDATE_CONTACTS_CLEAR',
};
