import { put, call, debounce, select } from 'redux-saga/effects';
import Qs from 'qs';
import { get } from 'lodash';

import ApiClient from '../../helpers/api'

import { actionTypes as types } from './constants';

const search = function* (action) {
    try {
        const user = yield select((state) => state.login.user);
        const url = `/api/search/?${Qs.stringify({
            keys: ['assets'],
            searchTerm: action.payload,
            userGroup: get(user, ['group', '_id']),
        })}`;
        const apiData = yield call([ApiClient, ApiClient.get], url);
        yield put({ type: types.SEARCH_SUCCESS, payload: apiData });
    } catch (err) {
        console.error('[search] error: ', JSON.stringify(err));
        yield put({ type: types.SEARCH_FAILED, message: err.message });
    }
}

export default [
    debounce(750, types.SEARCH_START, search)
];
