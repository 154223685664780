import { takeLatest, put, call } from 'redux-saga/effects';

import ApiClient from '../../../../../helpers/api';
import { APP_PATH_KEY } from '../../constants';
import { actionTypes as types } from './constants';

const getItems = function* (action) {
    try {
        const { user } = action.payload;

        const data = yield call(
            [ApiClient, ApiClient.post],
            `/api/${APP_PATH_KEY}/students`,
            { user }
        );
        yield put({
            type: types.GET_WINGMAN_STUDENTS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        console.error('[getItems] error: ', error);
        yield put({ type: types.GET_WINGMAN_STUDENTS_FAILED, error });
    }
};

const getItem = function* (action) {
    try {
        const { itemId, user } = action.payload;
        const data = yield call(
            [ApiClient, ApiClient.post],
            `/api/${APP_PATH_KEY}/students/${itemId}`,
            { user }
        );
        yield put({
            type: types.GET_WINGMAN_STUDENT_SUCCESS,
            payload: data,
        });
    } catch (error) {
        console.error('[getItem] error: ', error);
        yield put({ type: types.GET_WINGMAN_STUDENT_FAILED, error });
    }
};

const updateItem = function* (action) {
    try {
        const { itemId, fields } = action.payload;

        const apiResponse = yield call(
            [ApiClient, ApiClient.put],
            `/api/${APP_PATH_KEY}/students/${itemId}`,
            fields
        );

        yield put({
            type: types.UPDATE_WINGMAN_STUDENT_SUCCESS,
            payload: apiResponse,
        });
    } catch (error) {
        console.error('[updateItem] error: ', error);
        yield put({
            type: types.UPDATE_WINGMAN_STUDENT_FAILED,
            error,
        });
    }
};

const removeItem = function* (action) {
    try {
        const { itemId } = action.payload;

        const data = yield call(
            [ApiClient, ApiClient.delete],
            `/api/${APP_PATH_KEY}/students/${itemId}`
        );
        yield put({
            type: types.REMOVE_WINGMAN_STUDENT_SUCCESS,
            payload: { ...data, itemId },
        });
    } catch (error) {
        console.error('[removeItem] error: ', error);
        yield put({
            type: types.REMOVE_WINGMAN_STUDENT_FAILED,
            error,
        });
    }
};

export default [
    takeLatest(types.GET_WINGMAN_STUDENTS_START, getItems),
    takeLatest(types.GET_WINGMAN_STUDENT_START, getItem),
    takeLatest(types.UPDATE_WINGMAN_STUDENT_START, updateItem),
    takeLatest(types.REMOVE_WINGMAN_STUDENT_START, removeItem),
];
