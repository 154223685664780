import { takeLatest, put, call } from 'redux-saga/effects';
import ApiClient from '../../helpers/api';
import { actionTypes as types } from './constants';

const fetchSearchResults = function* (action) {
    try {
        const apiData = yield call([ApiClient, ApiClient.post], '/api/search', action.payload);
        yield put({ type: types.FETCH_SEARCH_RESULTS_SUCCESS, payload: apiData, processGroups: action.processGroups });
    } catch (err) {
        console.error('[fetchSearchResults] error: ', JSON.stringify(err));
        yield put({ type: types.FETCH_SEARCH_RESULTS_FAILED, message: err.message });
    }
};

export default [
    takeLatest(types.FETCH_SEARCH_RESULTS_START, fetchSearchResults),
];
