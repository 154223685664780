import _clone from 'lodash/clone';
import { actionTypes as types } from './constants';

const initialState = {
    error: null,
    loading: false,
    success: false,
};

export default (state = initialState, action) => {
    const updatedState = _clone(state);

    switch (action.type) {
        case types.RESET_START:
            updatedState.loading = true;
            updatedState.success = false;
            break;
        case types.RESET_SUCCESS:
            updatedState.loading = false;
            updatedState.success = true;
            break;
        case types.RESET_FAILED:
            updatedState.loading = false;
            updatedState.error =
                action.error &&
                action.error.error &&
                action.error.error.message;
            break;
        case types.CLEAR_SUCCESS:
            updatedState.success = false;
            break;
        case types.CLEAR_ERRORS:
            updatedState.error = null;
            break;
        default:
            break;
    }

    return updatedState;
};
