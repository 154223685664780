import _cloneDeep from 'lodash/cloneDeep';
import { actionTypes as types } from './constants';

const initialState = {
    products: [],
    error: null,
    loading: true,
    productDetails: null,
    errorDetails: null,
    successDetails: null,
    loadingDetails: false,
    history: [],
    errorHistory: null,
    loadingHistory: false,
    export: {
        loading: false,
        error: null,
        data: null,
    },
};

export default (state = initialState, action) => {
    const updatedState = _cloneDeep(state);

    switch (action.type) {
        case types.GET_PRODUCTS_HISTORY_START:
            updatedState.loading = true;
            break;
        case types.GET_PRODUCTS_HISTORY_SUCCESS:
            updatedState.loading = false;
            updatedState.products =
                action.payload.data &&
                action.payload.data.data &&
                action.payload.data.data.length > 0
                    ? action.payload.data.data
                    : [];
            break;
        case types.GET_PRODUCTS_HISTORY_FAILED:
            updatedState.loading = false;
            updatedState.error = action.error;
            break;
        case types.GET_PRODUCT_HISTORY_START:
            updatedState.loadingDetails = true;
            updatedState.errorDetails = false;
            break;
        case types.GET_PRODUCT_HISTORY_SUCCESS:
            updatedState.loadingDetails = false;
            updatedState.productDetails = action.payload.data;
            break;
        case types.GET_PRODUCT_HISTORY_FAILED:
            updatedState.loadingDetails = false;
            updatedState.errorDetails = action.error;
            break;

        case types.GET_PRODUCT_HISTORY_DETAILS_START:
            updatedState.loadingHistory = true;
            updatedState.errorHistory = false;
            break;

        case types.GET_PRODUCT_HISTORY_DETAILS_SUCCESS:
            updatedState.loadingHistory = false;
            updatedState.history = action.payload.data;
            break;

        case types.GET_PRODUCT_HISTORY_DETAILS_FAILED:
            updatedState.loadingHistory = false;
            updatedState.errorHistory = action.error;
            break;

        case types.GET_PRODUCT_HISTORY_EXPORT_START:
            updatedState.export.loading = true;
            updatedState.export.error = false;
            break;

        case types.GET_PRODUCT_HISTORY_EXPORT_SUCCESS:
            updatedState.export.loading = false;
            updatedState.export.data = action.payload.data;
            break;

        case types.GET_PRODUCT_HISTORY_EXPORT_FAILED:
            updatedState.export.loading = false;
            updatedState.export.error = action.error;
            break;

        case types.CLEAR_PRODUCT_HISTORY_EXPORT:
            updatedState.export.loading = false;
            updatedState.export.error = null;
            updatedState.export.data = null;
            break;

        default:
            break;
    }

    return updatedState;
};
