import { takeLatest, put, call } from 'redux-saga/effects';

import ApiClient from '../../../../helpers/api';
import { actionTypes as types } from './constants';

const getSqueezeBottleOrders = function* (action) {
    try {
        const data = yield call(
            [ApiClient, ApiClient.post],
            '/api/squeeze-bottle-orders',
            {
                ...action.payload,
            }
        );

        yield put({
            type: types.GET_SQUEEZE_BOTTLE_ORDERS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        console.error('[getSqueezeBottleOrders] error: ', error);
        yield put({ type: types.GET_SQUEEZE_BOTTLE_ORDERS_FAILED, error });
    }
};

const updateSqueezeBottleOrder = function* (action) {
    try {
        const data = action.payload;

        const apiData = yield call(
            [ApiClient, ApiClient.put],
            `/api/squeeze-bottle-orders`,
            {
                ...data,
            }
        );
        yield put({
            type: types.UPDATE_SQUEEZE_BOTTLE_ORDER_SUCCESS,
            payload: apiData,
        });
    } catch (error) {
        console.error('[updateSqueezeBottleOrder] error: ', error);
        yield put({ type: types.UPDATE_SQUEEZE_BOTTLE_ORDER_FAILED, error });
    }
};

export default [
    takeLatest(types.GET_SQUEEZE_BOTTLE_ORDERS_START, getSqueezeBottleOrders),
    takeLatest(
        types.UPDATE_SQUEEZE_BOTTLE_ORDER_START,
        updateSqueezeBottleOrder
    ),
];
