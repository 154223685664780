import 'react-app-polyfill/ie9';
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import TagManager from 'react-gtm-module';
import configureStore from './store';
import history from './history';
import App from './App';

const initialState = {};
const store = configureStore(initialState, history);

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
    auth: process.env.REACT_APP_GTM_AUTH,
};
TagManager.initialize(tagManagerArgs);

const urlSearchParams = new URLSearchParams(window.location.search);
const isPrint = urlSearchParams.has('print'); // if need to prepare page for pdf

if (isPrint) {
    // don't render ReCaptcha
    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <App />
            </ConnectedRouter>
        </Provider>,
        document.getElementById('root')
    );
} else {
    ReactDOM.render(
        <Provider store={store}>
            <GoogleReCaptchaProvider
                scriptProps={{ appendTo: 'body', async: true }}
                reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            >
                <ConnectedRouter history={history}>
                    <App />
                </ConnectedRouter>
            </GoogleReCaptchaProvider>
        </Provider>,
        document.getElementById('root')
    );
}
