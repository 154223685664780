import { put, takeLatest } from 'redux-saga/effects';

import ApiClient from '../../helpers/api';

import { actionTypes as types } from './constants';

const reset = function* (action) {
    try {
        const apiResponse = yield ApiClient.post('/api/reset', action.payload);
        yield put({ type: types.RESET_SUCCESS, payload: apiResponse });
    } catch (error) {
        yield put({ type: types.RESET_FAILED, error });
    }
};

export default [takeLatest(types.RESET_START, reset)];
