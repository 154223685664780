import clone from 'lodash/clone';
import { actionTypes as types } from './constants';

const initialState = {
    items: [],
    file: {},
    loading: false,
    error: null,
};

export default (state = initialState, action) => {
    const updatedState = clone(state);

    switch (action.type) {
        case types.ADD_BUILDER_ITEM:
            const { assets } = action.payload;
            for (const asset of assets) {
                if (!(updatedState.items.findIndex(it => it.id === asset.id) >= 0)) {
                    updatedState.items = updatedState.items.concat(asset);
                }
            }
            break;
        case types.REMOVE_BUILDER_ITEM:
            const { assetId } = action.payload;
            updatedState.items = updatedState.items.filter(it => it.id !== assetId);
            break;
        case types.CLEAN_BUILDER:
            updatedState.file = {};
            updatedState.items = [];
            break;
        case types.CREATE_EXCEL_START:
            updatedState.loading = true;
            break;
        case types.CREATE_EXCEL_SUCCESS:
            updatedState.loading = false;
            updatedState.file = action.payload.data;
            break;
        case types.CREATE_EXCEL_FAILED:
            updatedState.loading = false;
            break;
        default:
            break;
    }

    return updatedState;
}
