import React from 'react';
import { Route } from 'react-router';

import { WaitingComponent } from '../../../helpers/waitingComponent';
import lazy from '../../../helpers/lazy';

const POSSelector = lazy(() => import('./'));
const POSResults = lazy(() => import('./Results'));

const Router = ({ path }) => (
    <Route
        path={`${path}/product-and-packaging-pos`}
        render={({ match: { url } }) => (
            <>
                <Route
                    exact
                    path={`${url}/`}
                    component={WaitingComponent(POSSelector)}
                />
                <Route
                    exact
                    path={`${url}/:brand(kogi|kens|sbr|recently|all|portion-control|essentials-signature|print-ready|other|in-design-product)`}
                    component={WaitingComponent(POSResults)}
                />
            </>
        )}
    />
);

export default Router;
