import get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import { actionTypes as types } from './constants';

const initialState = {
    orders: [],
    error: null,
    loading: true,
};

export default (state = initialState, action) => {
    const updatedState = _cloneDeep(state);

    switch (action.type) {
        case types.GET_ARAMARK_DISPENSER_ORDERS_START:
            updatedState.loading = true;
            break;
        case types.GET_ARAMARK_DISPENSER_ORDERS_SUCCESS:
            updatedState.loading = false;
            updatedState.orders = get(action.payload.data, 'data', []);
            break;
        case types.GET_ARAMARK_DISPENSER_ORDERS_FAILED:
            updatedState.loading = false;
            updatedState.error = action.error;
            break;

        default:
            break;
    }

    return updatedState;
};
