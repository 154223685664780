import { takeLatest, put, call } from 'redux-saga/effects';

import ApiClient from '../../../../helpers/api';
import { actionTypes as types } from './constants';

const getContacts = function* (action) {
    try {
        const { userId } = action;

        const apiData = yield call(
            [ApiClient, ApiClient.get],
            `/api/address-book?userId=${userId}`
        );
        yield put({
            type: types.GET_CONTACTS_SUCCESS,
            payload: apiData,
        });
    } catch (error) {
        console.error('[getContacts] error: ', error);
        yield put({ type: types.GET_CONTACTS_FAILED, error });
    }
};

const updateContacts = function* (action) {
    try {
        const { userId, contacts } = action.payload;
        const requestUrl = `/api/address-book/${userId}`;
        const apiData = yield call([ApiClient, ApiClient.post], requestUrl, {
            contacts,
        });
        yield put({
            type: types.UPDATE_CONTACTS_SUCCESS,
            payload: apiData,
        });
    } catch (error) {
        console.error('[updateContacts] error: ', error);
        yield put({ type: types.UPDATE_CONTACTS_FAILED, error });
    }
};

export default [
    takeLatest(types.GET_CONTACTS_START, getContacts),
    takeLatest(types.UPDATE_CONTACTS_START, updateContacts),
];
