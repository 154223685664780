import get from 'lodash/get';
import clone from 'lodash/clone';
import flatten from 'lodash/flatten';
import { actionTypes as types } from './constants';

const initialState = {
    items: [],
    relatedAssets: null,
    emailSending: false,
    relatedAssetsError: null,
    relatedAssetsLoading: false,
    emailShowSuccessMessage: false,
    emailSomethingWentWrong: false
};

export default (state = initialState, action) => {
    const updatedState = clone(state);

    switch (action.type) {
        case types.ADD_ITEM:
            const { assets } = action.payload;
            for (const asset of assets) {
                const alreadyInCart = updatedState.items.findIndex(it => it.id === asset.id) >= 0;
                if (!alreadyInCart) {
                    updatedState.items = updatedState.items.concat(asset);
                }
            }
            break;
        case types.REMOVE_ITEM:
            const { assetId } = action.payload;
            updatedState.items = updatedState.items.filter(it => it.id !== assetId);
            break;
        case types.CLEAN:
            updatedState.items = [];
            break;
        case types.SET_ITEMS:
            updatedState.items = get(action.payload, 'assets', []);
            break;
        case types.FIND_RELATED_START:
            updatedState.relatedAssets = null;
            updatedState.relatedAssetsError = null;
            updatedState.relatedAssetsLoading = true;
            break;
        case types.FIND_RELATED_SUCCESS:
            const respCheck = action.payload && action.payload.data &&
                Array.isArray(action.payload.data) && action.payload.data.length;
            updatedState.relatedAssetsLoading = false;
            if (respCheck) {
                const data = action.payload.data[0];
                const assets = Object.keys(data).map(k => data[k]['assets']);
                updatedState.relatedAssets = flatten(assets);
            }
            break;
        case types.FIND_RELATED_FAILED:
            updatedState.relatedAssets = null;
            updatedState.relatedAssetsLoading = false;
            if (action.error && !action.error.errors) {
                updatedState.relatedAssetsError = true;
            }
            break;
        case types.SEND_ASSETS_START:
            updatedState.emailSending = true;
            break;
        case types.SEND_ASSETS_SUCCESS:
            updatedState.emailSending = false;
            updatedState.emailShowSuccessMessage = true;
            break;
        case types.SEND_ASSETS_FAILED:
            updatedState.emailSending = false;
            if (action.error && !action.error.errors) {
                updatedState.emailSomethingWentWrong = true;
            }
            break;
        case types.SEND_ASSETS_CLEAR:
            updatedState.emailShowSuccessMessage = false;
            updatedState.emailSomethingWentWrong = false;
            break;
        default:
            break;
    }

    return updatedState;
}
