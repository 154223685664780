export const actionTypes = {
    GET_DATASETS_UOMS_START: 'GET_DATASETS_UOMS_START',
    GET_DATASETS_UOMS_SUCCESS: 'GET_DATASETS_UOMS_SUCCESS',
    GET_DATASETS_UOMS_FAILED: 'GET_DATASETS_UOMS_FAILED',

    GET_DATASETS_PACKAGINGS_START: 'GET_DATASETS_PACKAGINGS_START',
    GET_DATASETS_PACKAGINGS_SUCCESS: 'GET_DATASETS_PACKAGINGS_SUCCESS',
    GET_DATASETS_PACKAGINGS_FAILED: 'GET_DATASETS_PACKAGINGS_FAILED',

    GET_DATASETS_BRANDNAME_START: 'GET_DATASETS_BRANDNAME_START',
    GET_DATASETS_BRANDNAME_SUCCESS: 'GET_DATASETS_BRANDNAME_SUCCESS',
    GET_DATASETS_BRANDNAME_FAILED: 'GET_DATASETS_BRANDNAME_FAILED',

    GET_DATASETS_COURSES_START: 'GET_DATASETS_COURSES_START',
    GET_DATASETS_COURSES_SUCCESS: 'GET_DATASETS_COURSES_SUCCESS',
    GET_DATASETS_COURSES_FAILED: 'GET_DATASETS_COURSES_FAILED',

    GET_DATASETS_PRODUCTS_START: 'GET_DATASETS_PRODUCTS_START',
    GET_DATASETS_PRODUCTS_SUCCESS: 'GET_DATASETS_PRODUCTS_SUCCESS',
    GET_DATASETS_PRODUCTS_FAILED: 'GET_DATASETS_PRODUCTS_FAILED',

    GET_DATASETS_RECIPES_START: 'GET_DATASETS_RECIPES_START',
    GET_DATASETS_RECIPES_SUCCESS: 'GET_DATASETS_RECIPES_SUCCESS',
    GET_DATASETS_RECIPES_FAILED: 'GET_DATASETS_RECIPES_FAILED',

    GET_DATASETS_RECIPES_BUILDER_START: 'GET_DATASETS_RECIPES_BUILDER_START',
    GET_DATASETS_RECIPES_BUILDER_SUCCESS:
        'GET_DATASETS_RECIPES_BUILDER_SUCCESS',
    GET_DATASETS_RECIPES_BUILDER_FAILED: 'GET_DATASETS_RECIPES_BUILDER_FAILED',

    GET_DATASETS_RECIPES_BUILDER_CUSTOMERS_START:
        'GET_DATASETS_RECIPES_BUILDER_CUSTOMERS_START',
    GET_DATASETS_RECIPES_BUILDER_CUSTOMERS_SUCCESS:
        'GET_DATASETS_RECIPES_BUILDER_CUSTOMERS_SUCCESS',
    GET_DATASETS_RECIPES_BUILDER_CUSTOMERS_FAILED:
        'GET_DATASETS_RECIPES_BUILDER_CUSTOMERS_FAILED',

    GET_DATASETS_WINGMAN_LOCATIONS_START:
        'GET_DATASETS_WINGMAN_LOCATIONS_START',
    GET_DATASETS_WINGMAN_LOCATIONS_SUCCESS:
        'GET_DATASETS_WINGMAN_LOCATIONS_SUCCESS',
    GET_DATASETS_WINGMAN_LOCATIONS_FAILED:
        'GET_DATASETS_WINGMAN_LOCATIONS_FAILED',

    GET_DATASETS_PLUSONES_START: 'GET_DATASETS_PLUSONES_START',
    GET_DATASETS_PLUSONES_SUCCESS: 'GET_DATASETS_PLUSONES_SUCCESS',
    GET_DATASETS_PLUSONES_FAILED: 'GET_DATASETS_PLUSONES_FAILED',

    GET_DATASETS_USERS_START: 'GET_DATASETS_USERS_START',
    GET_DATASETS_USERS_SUCCESS: 'GET_DATASETS_USERS_SUCCESS',
    GET_DATASETS_USERS_FAILED: 'GET_DATASETS_USERS_FAILED',

    CLEAR_DATASETS: 'CLEAR_DATASETS',
};
