export const actionTypes = {
    GET_CUSTOMER_COMPLAINT_LOOKUP_DATA_START:
        'GET_CUSTOMER_COMPLAINT_LOOKUP_DATA_START',
    GET_CUSTOMER_COMPLAINT_LOOKUP_DATA_FAILED:
        'GET_CUSTOMER_COMPLAINT_LOOKUP_DATA_FAILED',
    GET_CUSTOMER_COMPLAINT_LOOKUP_DATA_SUCCESS:
        'GET_CUSTOMER_COMPLAINT_LOOKUP_DATA_SUCCESS',

    SUBMIT_CUSTOMER_COMPLAINT_ASSETS_START:
        'SUBMIT_CUSTOMER_COMPLAINT_ASSETS_START',
    SUBMIT_CUSTOMER_COMPLAINT_ASSETS_FAILED:
        'SUBMIT_CUSTOMER_COMPLAINT_ASSETS_FAILED',
    SUBMIT_CUSTOMER_COMPLAINT_ASSETS_SUCCESS:
        'SUBMIT_CUSTOMER_COMPLAINT_ASSETS_SUCCESS',
    SUBMIT_CUSTOMER_COMPLAINT_START: 'SUBMIT_CUSTOMER_COMPLAINT_START',
    SUBMIT_CUSTOMER_COMPLAINT_FAILED: 'SUBMIT_CUSTOMER_COMPLAINT_FAILED',
    SUBMIT_CUSTOMER_COMPLAINT_SUCCESS: 'SUBMIT_CUSTOMER_COMPLAINT_SUCCESS',
};
