export const actionTypes = {
    GET_RECIPES_BUILDERS_ITEMS_START: 'GET_RECIPES_BUILDERS_ITEMS_START',
    GET_RECIPES_BUILDERS_ITEMS_SUCCESS: 'GET_RECIPES_BUILDERS_ITEMS_SUCCESS',
    GET_RECIPES_BUILDERS_ITEMS_FAILED: 'GET_RECIPES_BUILDERS_ITEMS_FAILED',

    GET_RECIPES_BUILDERS_ITEM_START: 'GET_RECIPES_BUILDERS_ITEM_START',
    GET_RECIPES_BUILDERS_CLEAR_ITEM_DETAILS_STATE:
        'GET_RECIPES_BUILDERS_CLEAR_ITEM_DETAILS_STATE',
    GET_RECIPES_BUILDERS_ITEM_SUCCESS: 'GET_RECIPES_BUILDERS_ITEM_SUCCESS',
    GET_RECIPES_BUILDERS_ITEM_FAILED: 'GET_RECIPES_BUILDERS_ITEM_FAILED',

    UPDATE_RECIPES_BUILDERS_ITEM_START: 'UPDATE_RECIPES_BUILDERS_ITEM_START',
    UPDATE_RECIPES_BUILDERS_ITEM_SUCCESS:
        'UPDATE_RECIPES_BUILDERS_ITEM_SUCCESS',
    UPDATE_RECIPES_BUILDERS_ITEM_FAILED: 'UPDATE_RECIPES_BUILDERS_ITEM_FAILED',
    UPDATE_RECIPES_BUILDERS_ITEM_CLEAR: 'UPDATE_RECIPES_BUILDERS_ITEM_CLEAR',

    REMOVE_RECIPES_BUILDERS_ITEM_START: 'REMOVE_RECIPES_BUILDERS_ITEM_START',
    REMOVE_RECIPES_BUILDERS_ITEM_SUCCESS:
        'REMOVE_RECIPES_BUILDERS_ITEM_SUCCESS',
    REMOVE_RECIPES_BUILDERS_ITEM_FAILED: 'REMOVE_RECIPES_BUILDERS_ITEM_FAILED',
    REMOVE_RECIPES_BUILDERS_ITEM_CLEAR: 'REMOVE_RECIPES_BUILDERS_ITEM_CLEAR',

    EMAIL_RECIPES_BUILDERS_ITEM_START: 'EMAIL_RECIPES_BUILDERS_ITEM_START',
    EMAIL_RECIPES_BUILDERS_ITEM_SUCCESS: 'EMAIL_RECIPES_BUILDERS_ITEM_SUCCESS',
    EMAIL_RECIPES_BUILDERS_ITEM_FAILED: 'EMAIL_RECIPES_BUILDERS_ITEM_FAILED',
    EMAIL_RECIPES_BUILDERS_ITEM_CLEAR: 'EMAIL_RECIPES_BUILDERS_ITEM_CLEAR',
};
