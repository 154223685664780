import React from 'react';
import { Route } from 'react-router';

import { WaitingComponent } from '../../../helpers/waitingComponent';
import lazy from '../../../helpers/lazy';

const PocketBrochureResults = lazy(() => import('./Results'));

const Router = ({ path }) => (
    <Route
        path={`${path}/pocket-brochure`}
        render={({ match: { url } }) => (
            <>
                <Route
                    exact
                    path={`${url}/`}
                    component={WaitingComponent(PocketBrochureResults)}
                />
            </>
        )}
    />
);

export default Router;
