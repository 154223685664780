import _cloneDeep from 'lodash/cloneDeep';
import { actionTypes as types } from './constants';
import { isEmpty } from 'lodash';

const initialState = {
    count: 0,
    groups: {},
    pages: {},
    items: {},
    leadItems: [],
    archivedCount: 0,
    archivedGroups: {},
    archivedPages: {},
    archivedItems: {},
    archivedLeadItems: [],
    details: null,
    error: null,
    managers: [],
    loading: true,
    archiveResult: {},
    updateLeadLoading: false,
    updateLeadSuccess: false,
    archiveLeadSuccess: false,
    updateLeadError: false,
    archiveLeadLoading: false,
    managersLoading: false,
    emailSending: false,
    emailShowSuccessMessage: false,
    emailSomethingWentWrong: false,
    productsError: false,
    productsLoading: false,
    products: [],
    salesLeadsHistory: {
        loading: false,
        error: null,
        groups: {},
    },
    salesLeadsInflow: {
        loading: false,
        error: null,
        data: [],
    },
};

export default (state = initialState, action) => {
    const updatedState = _cloneDeep(state);

    switch (action.type) {
        case types.GET_PRODUCTS_START:
            updatedState.productsError = null;
            updatedState.productsLoading = true;
            break;
        case types.GET_PRODUCTS_SUCCESS:
            updatedState.productsLoading = false;
            updatedState.products = action.payload.data;
            break;
        case types.GET_PRODUCTS_FAILED:
            updatedState.productsError = action.error;
            updatedState.productsLoading = false;
            break;
        case types.GET_SALES_LEADS_START:
        case types.GET_ARCHIVED_SALES_LEADS_START:
        case types.GET_SALES_LEAD_START:
            updatedState.error = null;
            updatedState.loading = true;
            break;
        case types.UPDATE_SALES_LEAD_START:
            updatedState.error = null;
            updatedState.updateLeadLoading = true;
            break;
        case types.ARCHIVE_SALES_LEAD_START:
            updatedState.error = null;
            updatedState.archiveLeadLoading = true;
            break;
        case types.UPDATE_ARCHIVE_SALES_LEAD_START:
            updatedState.error = null;
            updatedState.updateLeadLoading = true;
            updatedState.archiveLeadLoading = true;
            break;
        case types.GET_SALES_LEADS_SUCCESS:
            updatedState.loading = false;

            if (action.groupData) {
                updatedState.groups = action.payload.data;
                let count = 0;
                for (const groupKey of Object.keys(updatedState.groups)) {
                    count += updatedState.groups[groupKey].length;
                }
                updatedState.count = count;
            } else {
                updatedState.leadItems = action.payload.data.data;

                updatedState.pages = action.payload.data.pages;
                updatedState.items = action.payload.data.items;
            }

            break;
        case types.GET_ARCHIVED_SALES_LEADS_SUCCESS:
            updatedState.loading = false;

            if (action.groupData) {
                updatedState.archivedGroups = action.payload.data;
                let count = 0;
                for (const groupKey of Object.keys(
                    updatedState.archivedGroups
                )) {
                    count += updatedState.archivedGroups[groupKey].length;
                }
                updatedState.archivedCount = count;
            } else {
                updatedState.archivedLeadItems = action.payload.data.data;

                updatedState.archivedPages = action.payload.data.pages;
                updatedState.archivedItems = action.payload.data.items;
            }

            break;
        case types.GET_SALES_LEAD_SUCCESS:
            updatedState.loading = false;
            if (action.payload && action.payload.data) {
                updatedState.details = action.payload.data;
            }
            break;
        case types.UPDATE_SALES_LEAD_SUCCESS:
            updatedState.updateLeadLoading = false;
            if (action.payload && action.payload.data) {
                updatedState.details = action.payload.data;
                updatedState.updateLeadSuccess = true;
            }
            break;
        case types.UPDATE_SALES_LEAD_CLEAR:
            updatedState.updateLeadSuccess = false;
            break;
        case types.ARCHIVE_SALES_LEAD_SUCCESS:
            updatedState.archiveLeadLoading = false;
            if (action.payload && action.payload.data) {
                updatedState.archiveResult = action.payload.data;
            }
            break;
        case types.ARCHIVE_SALES_LEAD_CLEAR:
            updatedState.archiveResult = {};
            break;
        case types.UPDATE_ARCHIVE_SALES_LEAD_SUCCESS:
            updatedState.updateLeadLoading = false;
            updatedState.archiveLeadLoading = false;

            if (
                action.payload &&
                action.payload.update &&
                action.payload.update.data &&
                action.payload.archive &&
                action.payload.archive.data
            ) {
                updatedState.updateLeadSuccess = true;
                updatedState.details = action.payload.update.data;
                updatedState.archiveResult = action.payload.archive.data;
                updatedState.archiveLeadSuccess = true;
            }
            break;
        case types.UPDATE_ARCHIVE_SALES_LEAD_CLEAR:
            updatedState.updateLeadSuccess = false;
            updatedState.archiveResult = {};
            break;
        case types.GET_SALES_LEADS_FAILED:
        case types.GET_ARCHIVED_SALES_LEADS_FAILED:
        case types.GET_SALES_LEAD_FAILED:
            updatedState.error = action.error;
            updatedState.loading = false;
            break;
        case types.UPDATE_SALES_LEAD_FAILED:
            updatedState.error = action.error;
            updatedState.updateLeadLoading = false;
            break;
        case types.ARCHIVE_SALES_LEAD_FAILED:
            updatedState.error = action.error;
            updatedState.archiveLeadLoading = false;
            break;
        case types.UPDATE_ARCHIVE_SALES_LEAD_FAILED:
            updatedState.error = action.error;
            updatedState.updateLeadLoading = false;
            updatedState.archiveLeadLoading = false;
            break;
        case types.GET_MANAGERS_START:
            updatedState.managersLoading = true;
            break;
        case types.GET_MANAGERS_SUCCESS:
            updatedState.managers = action.payload.data;
            updatedState.managersLoading = false;
            if (!isEmpty(action.payload)) {
                updatedState.managers = action.payload.data;
            }
            break;
        case types.GET_MANAGERS_FAILED:
            updatedState.managers = [];
            updatedState.managersLoading = false;
            break;
        case types.SEND_LEAD_START:
            updatedState.emailSending = true;
            break;
        case types.SEND_LEAD_SUCCESS:
            updatedState.emailSending = false;
            updatedState.emailShowSuccessMessage = true;
            break;
        case types.SEND_LEAD_FAILED:
            updatedState.emailSending = false;
            if (action.error && !action.error.errors) {
                updatedState.emailSomethingWentWrong = true;
            }
            break;
        case types.SEND_LEAD_CLEAR:
            updatedState.emailShowSuccessMessage = false;
            updatedState.emailSomethingWentWrong = false;
            break;

        case types.GET_SALES_LEADS_HISTORY_START:
            updatedState.salesLeadsHistory.error = null;
            updatedState.salesLeadsHistory.loading = true;
            break;
        case types.GET_SALES_LEADS_HISTORY_SUCCESS:
            updatedState.salesLeadsHistory.loading = false;
            updatedState.salesLeadsHistory.groups = action.payload.data;
            break;
        case types.GET_SALES_LEADS_HISTORY_FAILED:
            updatedState.salesLeadsHistory.error = action.error;
            updatedState.salesLeadsHistory.loading = false;
            break;

        case types.GET_SALES_LEADS_INFLOW_START:
            updatedState.salesLeadsInflow.error = null;
            updatedState.salesLeadsInflow.loading = true;
            break;
        case types.GET_SALES_LEADS_INFLOW_SUCCESS:
            updatedState.salesLeadsInflow.loading = false;
            updatedState.salesLeadsInflow.data = action.payload.data;
            break;
        case types.GET_SALES_LEADS_INFLOW_FAILED:
            updatedState.salesLeadsInflow.error = action.error;
            updatedState.salesLeadsInflow.loading = false;
            break;

        default:
            break;
    }

    return updatedState;
};
