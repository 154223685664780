export const actionTypes = {
    GET_WINGMAN_LOCATIONS_START:
        'GET_WINGMAN_LOCATIONS_START',
    GET_WINGMAN_LOCATIONS_SUCCESS:
        'GET_WINGMAN_LOCATIONS_SUCCESS',
    GET_WINGMAN_LOCATIONS_FAILED:
        'GET_WINGMAN_LOCATIONS_FAILED',

    GET_WINGMAN_LOCATION_START: 'GET_WINGMAN_LOCATION_START',
    GET_WINGMAN_CLEAR_LOCATIONS_DETAILS_STATE:
        'GET_WINGMAN_CLEAR_LOCATIONS_DETAILS_STATE',
    GET_WINGMAN_LOCATION_SUCCESS:
        'GET_WINGMAN_LOCATION_SUCCESS',
    GET_WINGMAN_LOCATION_FAILED:
        'GET_WINGMAN_LOCATION_FAILED',

    UPDATE_WINGMAN_LOCATION_START:
        'UPDATE_WINGMAN_LOCATION_START',
    UPDATE_WINGMAN_LOCATION_SUCCESS:
        'UPDATE_WINGMAN_LOCATION_SUCCESS',
    UPDATE_WINGMAN_LOCATION_FAILED:
        'UPDATE_WINGMAN_LOCATION_FAILED',
    UPDATE_WINGMAN_LOCATION_CLEAR:
        'UPDATE_WINGMAN_LOCATION_CLEAR',

    REMOVE_WINGMAN_LOCATION_START:
        'REMOVE_WINGMAN_LOCATION_START',
    REMOVE_WINGMAN_LOCATION_SUCCESS:
        'REMOVE_WINGMAN_LOCATION_SUCCESS',
    REMOVE_WINGMAN_LOCATION_FAILED:
        'REMOVE_WINGMAN_LOCATION_FAILED',
    REMOVE_WINGMAN_LOCATION_CLEAR:
        'REMOVE_WINGMAN_LOCATION_CLEAR',
};
