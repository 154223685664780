import { actionTypes as types } from './constants';

export const toggleRWVisibility = (tab) => ({
    tab,
    type: types.TOGGLE_VISIBILITY,
});

export const setTab = (tab) => {
    return {
        tab,
        type: types.SET_TAB,
    };
};

export const hideTab = (tab) => ({
    tab,
    type: types.HIDE_TAB,
});

export const hideAllTabs = (tab) => ({
    tab,
    type: types.HIDE_ALL_TABS,
});
