import _cloneDeep from 'lodash/cloneDeep';
import { actionTypes as types } from './constants';

const defaultValues = {
    loading: false,
    error: false,
    data: null,
};

const initialState = {
    uoms: {
        ...defaultValues,
    },
    packagings: {
        ...defaultValues,
    },
    brandName: {
        ...defaultValues,
    },
    courses: {
        ...defaultValues,
    },
    products: {
        ...defaultValues,
    },
    recipes: {
        ...defaultValues,
    },
    recipesBuilder: {
        ...defaultValues,
    },
    recipesBuilderCustomers: {
        ...defaultValues,
    },
    wingmanLocations: {
        ...defaultValues,
    },
    plusones: {
        ...defaultValues,
    },
    users: {
        ...defaultValues,
    },
};

export default (state = initialState, action) => {
    const updatedState = _cloneDeep(state);

    switch (action.type) {
        case types.GET_DATASETS_UOMS_START:
        case types.GET_DATASETS_PACKAGINGS_START:
        case types.GET_DATASETS_BRANDNAME_START:
        case types.GET_DATASETS_COURSES_START:
        case types.GET_DATASETS_PRODUCTS_START:
        case types.GET_DATASETS_RECIPES_START:
        case types.GET_DATASETS_RECIPES_BUILDER_START:
        case types.GET_DATASETS_RECIPES_BUILDER_CUSTOMERS_START:
        case types.GET_DATASETS_WINGMAN_LOCATIONS_START:
        case types.GET_DATASETS_PLUSONES_START:
        case types.GET_DATASETS_USERS_START:
            updatedState[action.dataset].loading = true;
            updatedState[action.dataset].error = null;
            break;

        case types.GET_DATASETS_UOMS_SUCCESS:
        case types.GET_DATASETS_PACKAGINGS_SUCCESS:
        case types.GET_DATASETS_BRANDNAME_SUCCESS:
        case types.GET_DATASETS_COURSES_SUCCESS:
        case types.GET_DATASETS_PRODUCTS_SUCCESS:
        case types.GET_DATASETS_RECIPES_SUCCESS:
        case types.GET_DATASETS_RECIPES_BUILDER_SUCCESS:
        case types.GET_DATASETS_RECIPES_BUILDER_CUSTOMERS_SUCCESS:
        case types.GET_DATASETS_WINGMAN_LOCATIONS_SUCCESS:
        case types.GET_DATASETS_PLUSONES_SUCCESS:
        case types.GET_DATASETS_USERS_SUCCESS:
            updatedState[action.dataset].loading = false;
            updatedState[action.dataset].data = action.data;
            break;

        case types.GET_DATASETS_UOMS_FAILED:
        case types.GET_DATASETS_BRANDNAME_FAILED:
        case types.GET_DATASETS_COURSES_FAILED:
        case types.GET_DATASETS_PRODUCTS_FAILED:
        case types.GET_DATASETS_RECIPES_FAILED:
        case types.GET_DATASETS_RECIPES_BUILDER_FAILED:
        case types.GET_DATASETS_RECIPES_BUILDER_CUSTOMERS_FAILED:
        case types.GET_DATASETS_WINGMAN_LOCATIONS_FAILED:
        case types.GET_DATASETS_PLUSONES_FAILED:
        case types.GET_DATASETS_USERS_FAILED:
            updatedState[action.dataset].loading = false;
            updatedState[action.dataset].error = action.error;
            break;

        case types.CLEAR_DATASETS:
            action.datasets.forEach((dataset) => {
                if (updatedState[dataset]) {
                    updatedState[dataset] = { ...defaultValues };
                }
            });
            break;

        default:
            break;
    }

    return updatedState;
};
