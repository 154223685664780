export const actionTypes = {
    GET_ARAMARK_PCS_START: 'GET_ARAMARK_PCS_START',
    GET_ARAMARK_PCS_SUCCESS: 'GET_ARAMARK_PCS_SUCCESS',
    GET_ARAMARK_PCS_FAILED: 'GET_ARAMARK_PCS_FAILED',

    GET_ARAMARK_PC_START: 'GET_ARAMARK_PC_START',
    GET_ARAMARK_PC_SUCCESS: 'GET_ARAMARK_PC_SUCCESS',
    GET_ARAMARK_PC_FAILED: 'GET_ARAMARK_PC_FAILED',

    UPDATE_ARAMARK_PC_START: 'UPDATE_ARAMARK_PC_START',
    UPDATE_ARAMARK_PC_SUCCESS: 'UPDATE_ARAMARK_PC_SUCCESS',
    UPDATE_ARAMARK_PC_FAILED: 'UPDATE_ARAMARK_PC_FAILED',

    REMOVE_ARAMARK_PC_START: 'REMOVE_ARAMARK_PC_START',
    REMOVE_ARAMARK_PC_SUCCESS: 'REMOVE_ARAMARK_PC_SUCCESS',
    REMOVE_ARAMARK_PC_FAILED: 'REMOVE_ARAMARK_PC_FAILED',
    REMOVE_ARAMARK_PC_CLEAR: 'REMOVE_ARAMARK_PC_CLEAR',
};
