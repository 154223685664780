import React from 'react';
import { Route } from 'react-router';

import '../search-results/search-results.scss';

import AramarkRouter from './aramark/router';
import POSRouter from './product-and-packaging-pos/router';
import ProductImagesRouter from './product-images/router';
import SpecSheetsRouter from './specification-sheets/router';
import RecipesPlusOnesRouter from './recipes-and-plus-ones/router';
import SalesSolutionsRouter from './sales-solutions/router';
import DashboardRouter from './dashboard/router';
import ExcelBuilderRouter from './excel-builder/router';
import PocketBrochureRouter from './pocket-brochure/router';
import SalesLeadsRouter from './sales-leads/router';
import ProductHistoryRouter from './product-history/router';
import AnalyticsRouter from './analytics/router';
import CustomerComplaintRouter from './customer-complaint/router';
import WingmanRouter from './wingman/router';

const AppsRouter = (props) => {
    return (
        <Route
            path="/apps"
            render={({ match: { url } }) => (
                <>
                    <AramarkRouter path={url} />
                    <POSRouter path={url} />
                    <SpecSheetsRouter path={url} />
                    <ProductImagesRouter path={url} />
                    <RecipesPlusOnesRouter path={url} />
                    <SalesSolutionsRouter path={url} />
                    <DashboardRouter path={url} />
                    <ExcelBuilderRouter path={url} />
                    <PocketBrochureRouter path={url} />
                    <SalesLeadsRouter path={url} />
                    <ProductHistoryRouter path={url} />
                    <AnalyticsRouter path={url} />
                    <CustomerComplaintRouter path={url} />
                    <WingmanRouter path={url} />
                </>
            )}
        />
    );
};

export default AppsRouter;
