import { takeLatest, put, call } from 'redux-saga/effects';

import ApiClient from '../../../../helpers/api';
import { actionTypes as types } from './constants';

const getAramarkDispenserOrders = function* (action) {
    try {
        const data = yield call(
            [ApiClient, ApiClient.post],
            '/api/aramark-dispenser-orders',
            {
                ...action.payload,
            }
        );

        yield put({
            type: types.GET_ARAMARK_DISPENSER_ORDERS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        console.error('[getAramarkDispenserOrders] error: ', error);
        yield put({ type: types.GET_ARAMARK_DISPENSER_ORDERS_FAILED, error });
    }
};

export default [
    takeLatest(
        types.GET_ARAMARK_DISPENSER_ORDERS_START,
        getAramarkDispenserOrders
    ),
];
