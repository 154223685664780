import get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import { actionTypes as types } from './constants';

const initialState = {
    users: [],
    error: null,
    loading: true,
    errorUpdate: null,
    successUpdate: null,
    loadingUpdate: false,
    errorRemove: null,
    successRemove: null,
    loadingRemove: false,
    userDetails: null,
    errorDetails: null,
    successDetails: null,
    loadingDetails: false,
    groups: [],
    errorGroups: null,
    successGroups: null,
    loadingGroups: false,
    roles: [],
    errorRoles: null,
    successRoles: null,
    loadingRoles: false,
    addressBooks: [],
    errorAddressBooks: null,
    successAddressBooks: null,
    loadingAddressBooks: false,
    errorCreateAddressBook: null,
    successCreateAddressBook: null,
    loadingCreateAddressBook: false,
    welcomeEmailLoading: false,
    welcomeEmailError: null,
    welcomeEmailSuccess: []
};

export default (state = initialState, action) => {
    const updatedState = _cloneDeep(state);

    switch (action.type) {
        case types.GET_USERS_START:
            updatedState.loading = true;
            break;
        case types.GET_USERS_SUCCESS:
            updatedState.loading = false;
            updatedState.users =
                action.payload.data && action.payload.data.length > 0
                    ? action.payload.data
                    : [];
            break;
        case types.GET_USERS_FAILED:
            updatedState.loading = false;
            updatedState.error = action.error;
            break;
        case types.GET_USER_START:
            updatedState.loadingDetails = true;
            updatedState.errorUpdate = false;
            updatedState.errorDetails = false;
            updatedState.successUpdate = false;
            break;
        case types.GET_USER_SUCCESS:
            updatedState.loadingDetails = false;
            updatedState.userDetails = action.payload.data;
            break;
        case types.GET_USER_FAILED:
            updatedState.loadingDetails = false;
            updatedState.errorDetails = action.error;
            break;
        case types.UPDATE_USER_START:
            updatedState.loadingUpdate = true;
            break;
        case types.UPDATE_USER_SUCCESS:
            updatedState.loadingUpdate = false;
            updatedState.errorUpdate = false;
            updatedState.successUpdate = true;
            updatedState.userDetails = action.payload.data;
            break;
        case types.UPDATE_USER_FAILED:
            updatedState.loadingUpdate = false;
            updatedState.errorUpdate = action.error;
            break;
        case types.UPDATE_USER_CLEAR:
            updatedState.successUpdate = false;
            break;
        case types.REMOVE_USER_START:
            updatedState.loadingRemove = true;
            break;
        case types.REMOVE_USER_SUCCESS:
            const { userId } = action.payload;
            updatedState.loadingRemove = false;
            updatedState.errorRemove = false;
            updatedState.successRemove = true;
            updatedState.users = updatedState.users.filter(
                (it) => it.id !== userId
            );
            break;
        case types.REMOVE_USER_FAILED:
            updatedState.loadingRemove = false;
            updatedState.errorRemove = action.error;
            break;
        case types.REMOVE_USER_CLEAR:
            updatedState.successRemove = false;
            updatedState.errorRemove = false;
            break;
        case types.GET_GROUPS_START:
            updatedState.loadingGroups = true;
            break;
        case types.GET_GROUPS_SUCCESS:
            updatedState.loadingGroups = false;
            updatedState.successGroups = true;
            updatedState.groups =
                action.payload.data && action.payload.data.length > 0
                    ? action.payload.data
                    : [];
            break;
        case types.GET_GROUPS_FAILED:
            updatedState.loadingGroups = false;
            updatedState.errorGroups = action.error;
            break;

        case types.GET_ROLES_START:
            updatedState.loadingRoles = true;
            break;
        case types.GET_ROLES_SUCCESS:
            updatedState.loadingRoles = false;
            updatedState.successRoles = true;
            updatedState.roles =
                action.payload.data && action.payload.data.length > 0
                    ? action.payload.data
                    : [];
            break;
        case types.GET_ROLES_FAILED:
            updatedState.loadingRoles = false;
            updatedState.errorRoles = action.error;
            break;

        case types.GET_ADDRESS_BOOKS_START:
            updatedState.loadingAddressBooks = true;
            break;
        case types.GET_ADDRESS_BOOKS_SUCCESS:
            updatedState.loadingAddressBooks = false;
            updatedState.successAddressBooks = true;
            updatedState.addressBooks = get(action.payload.data, 'data', []);
            break;
        case types.GET_ADDRESS_BOOKS_FAILED:
            updatedState.loadingAddressBooks = false;
            updatedState.errorAddressBooks = action.error;
            break;

        case types.CREATE_CORPORATE_ADDRESS_BOOK_START:
            updatedState.loadingCreateAddressBook = true;
            break;
        case types.CREATE_CORPORATE_ADDRESS_BOOK_SUCCESS:
            updatedState.loadingCreateAddressBook = false;
            updatedState.errorCreateAddressBook = false;
            updatedState.successCreateAddressBook = true;
            const newAddressBook = get(action.payload, 'data', []);
            newAddressBook.isNew = true;
            const updatedItems = updatedState.addressBooks.map((item) => {
                delete item.isNew;
                return item;
            });
            updatedState.addressBooks = [...updatedItems, newAddressBook];
            break;
        case types.CREATE_CORPORATE_ADDRESS_BOOK_FAILED:
            updatedState.loadingCreateAddressBook = false;
            updatedState.errorCreateAddressBook = action.error;
            break;

        case types.SEND_USER_WELCOME_EMAIL_START:
            updatedState.welcomeEmailLoading = true;
            updatedState.welcomeEmailError = null;
            break;
        case types.SEND_USER_WELCOME_EMAIL_SUCCESS:
            updatedState.welcomeEmailLoading = false;
            updatedState.welcomeEmailSuccess.push((action.payload.data));
            break;
        case types.SEND_USER_WELCOME_EMAIL_FAILED:
            updatedState.welcomeEmailLoading = false;
            updatedState.welcomeEmailError = action.error;
            break;

        default:
            break;
    }

    return updatedState;
};
