import _assign from 'lodash/assign';

class ApiClient {
    get(path, requestOptions = {}) {
        return this.request(path, 'GET', null, requestOptions);
    }

    delete(path, data) {
        return this.request(path, 'DELETE');
    }

    post(path, data) {
        return this.request(path, 'POST', data);
    }

    put(path, data) {
        return this.request(path, 'PUT', data);
    }

    postFile(path, fields) {
        const xhr = new XMLHttpRequest();

        xhr.open('POST', path);

        const formData = new FormData();
        fields.forEach((f) => {
            formData.append(f.name, f.data);
        });

        return new Promise((res, rej) => {
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    res(JSON.parse(xhr.responseText));
                } else if (!xhr.status > 299) {
                    rej(xhr);
                }
            };
            xhr.send(formData);
        });
    }

    putFile(path, fields) {
        const xhr = new XMLHttpRequest();

        xhr.open('PUT', path);

        const formData = new FormData();
        fields.forEach((f) => {
            formData.append(f.name, f.data);
        });

        return new Promise((res, rej) => {
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status >= 200 && xhr.status < 300) {
                        res(JSON.parse(xhr.responseText));
                    } else {
                        rej(
                            new Error(
                                `Request failed with status ${xhr.status}: ${xhr.statusText}`
                            )
                        );
                    }
                }
            };
            xhr.send(formData);
        });
    }

    request(path, method, data, requestOptions) {
        const options = {
            method: method || 'GET',
            // mode: 'no-cors',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        if (requestOptions) {
            _assign(options, requestOptions);
        }

        if (data) {
            options.body = JSON.stringify(data);
        }

        return fetch(path, options).then(filterError).then(parseJSON);
    }
}

const parseJSON = (res) => {
    return res.json();
};

const filterError = (res) => {
    if (res.status < 200 || res.status > 300) {
        const contentType = res.headers.get('Content-Type');

        if (!~contentType.indexOf('json')) {
            return Promise.reject(new Error(res));
        }

        return res
            .json()
            .then((json) => {
                return json;
            })
            .then((json) => {
                return Promise.reject(json);
            });
    }

    return res;
};

export default new ApiClient();
