import { lazy as lazyOriginal } from 'react';

/**
 * This module provides a wrapper around the lazy function from React, adding retry logic and a forced page reload mechanism.
 *
 * The main purpose of this module is to enhance the lazy loading of React components by attempting to load a component multiple times
 * before ultimately forcing a page reload if all attempts fail. This can be useful in scenarios where network issues or other transient
 * errors might prevent a component from loading successfully on the first try.
 *
 * The retry logic is implemented in the `componentLoader` function, which takes a lazy-loaded component and a number of attempts as arguments.
 * If the component fails to load, it will retry after a short delay. If all attempts are exhausted, the function will check if the page has
 * already been reloaded. If not, it will set a flag in local storage and reload the page. If the page has already been reloaded once, it will
 * clear the flag and reject the promise with the error.
 *
 * The `lazy` function is a wrapper around the original React lazy function, using the `componentLoader` function to add the retry and reload
 * logic.
 *
 * References:
 * - https://gist.github.com/Botfather/4cfc9c7ba363c892acced7cc44d9f7ff#file-componentloader-js
 * - https://gist.github.com/raphael-leger/4d703dea6c845788ff9eb36142374bdb#file-lazywithretry-js
 */

const RELOAD_FLAG_KEY = '_ksp_page_reloaded';
const RETRY_DELAY_MS = 1500;

export class LazyLoadingError extends Error {
    constructor(message) {
        super(message);
        this.name = 'LazyLoadingError';
        this.errorCode = 'LAZY_LOADING_ERROR';
    }
}

function componentLoader(lazyComponent, attemptsLeft) {
    return new Promise((resolve, reject) => {
        // Attempt to load the lazy component
        lazyComponent()
            .then(resolve) // Resolve the promise if successful
            .catch((error) => {
                // If no attempts are left
                if (attemptsLeft === 0) {
                    try {
                        // Check if localStorage is accessible and if the page has already been reloaded
                        if (
                            window.localStorage &&
                            !window.localStorage.getItem(RELOAD_FLAG_KEY)
                        ) {
                            // Set the reload flag and reload the page
                            window.localStorage.setItem(
                                RELOAD_FLAG_KEY,
                                'true'
                            );
                            window.location.reload();
                        } else {
                            // Clear the reload flag and reject the promise
                            if (window.localStorage) {
                                window.localStorage.removeItem(RELOAD_FLAG_KEY);
                            }
                            reject(
                                new LazyLoadingError(
                                    `Component failed to load after multiple attempts: ${error.message}`
                                )
                            );
                        }
                    } catch (e) {
                        // Handle the case where localStorage is not accessible
                        reject(
                            new LazyLoadingError(
                                `Component failed to load and localStorage is not accessible: ${error.message}`
                            )
                        );
                    }
                } else {
                    // Retry loading the component after a delay
                    setTimeout(() => {
                        componentLoader(lazyComponent, attemptsLeft - 1)
                            .then(resolve)
                            .catch(reject);
                    }, RETRY_DELAY_MS);
                }
            });
    });
}

export default function lazy(cb, numberOfAttempts = 3) {
    return lazyOriginal(() => componentLoader(cb, numberOfAttempts));
}
