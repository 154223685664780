import { get, cloneDeep } from 'lodash';
import { actionTypes as types } from './constants';
import { actionTypes as collectionTypes } from './../collections/constants';

const initialState = {
    context: {},
    conditions: {
        faq: false,
        email: false,
        collection: false,
        relatedAssets: false,
        removeCollection: false,
        excelFieldsSelection: false,
        excelNameSelection: false,
        sampleRequesTypeSelection: false,
        orderSupplyForm: false,
        orderSupplyAramarkForm: false,
        removeRecipesBuilder: false,
        removeRecipesBuilderCustomers: false,
        removeWingmanLocations: false,
        removeWingmanSales: false,
        removeWingmanStudents: false,
        emailRecipesBuilder: false,
        shareCollection: false,
    },
};

export default (state = initialState, action) => {
    const updatedState = cloneDeep(state);

    switch (action.type) {
        case types.TOGGLE_MODAL_VISIBILITY:
            const { type, context } = action.payload;
            updatedState.context = context || {};
            updatedState.conditions[type] = !updatedState.conditions[type];
            break;
        case collectionTypes.UPDATE_SINGLE_COLLECTION_SUCCESS:
            updatedState.context = get(action.payload, 'data', {});
            break;
        case collectionTypes.REMOVE_SINGLE_COLLECTION_START:
            updatedState.context = {};
            updatedState.conditions.collection = false;
            updatedState.conditions.removeCollection = false;
            break;
        default:
            break;
    }

    return updatedState;
};
