export const actionTypes = {
    GET_PRODUCTS_HISTORY_START: 'GET_PRODUCTS_HISTORY_START',
    GET_PRODUCTS_HISTORY_SUCCESS: 'GET_PRODUCTS_HISTORY_SUCCESS',
    GET_PRODUCTS_HISTORY_FAILED: 'GET_PRODUCTS_HISTORY_FAILED',

    GET_PRODUCT_HISTORY_START: 'GET_PRODUCT_HISTORY_START',
    GET_PRODUCT_HISTORY_SUCCESS: 'GET_PRODUCT_HISTORY_SUCCESS',
    GET_PRODUCT_HISTORY_FAILED: 'GET_PRODUCT_HISTORY_FAILED',

    GET_PRODUCT_HISTORY_DETAILS_START: 'GET_PRODUCT_HISTORY_DETAILS_START',
    GET_PRODUCT_HISTORY_DETAILS_SUCCESS: 'GET_PRODUCT_HISTORY_DETAILS_SUCCESS',
    GET_PRODUCT_HISTORY_DETAILS_FAILED: 'GET_PRODUCT_HISTORY_DETAILS_FAILED',

    GET_PRODUCT_HISTORY_EXPORT_START: 'GET_PRODUCT_HISTORY_EXPORT_START',
    GET_PRODUCT_HISTORY_EXPORT_SUCCESS: 'GET_PRODUCT_HISTORY_EXPORT_SUCCESS',
    GET_PRODUCT_HISTORY_EXPORT_FAILED: 'GET_PRODUCT_HISTORY_EXPORT_FAILED',
    CLEAR_PRODUCT_HISTORY_EXPORT: 'CLEAR_PRODUCT_HISTORY_EXPORT',
};
