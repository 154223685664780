export const actionTypes = {
    ADD_ORDER_ITEM: 'ADD_ORDER_ITEM',
    REMOVE_ORDER_ITEM: 'REMOVE_ORDER_ITEM',
    CLEAN_ORDER: 'CLEAN_ORDER',

    PROCESS_ORDER_START: 'PROCESS_ORDER_START',
    PROCESS_ORDER_SUCCESS: 'PROCESS_ORDER_SUCCESS',
    PROCESS_ORDER_FAILED: 'PROCESS_ORDER_FAILED',

    CLEAR_ORDER: 'CLEAR_ORDER',

    GET_SQUEEZE_BOTTLES_START: 'GET_SQUEEZE_BOTTLES_START',
    GET_SQUEEZE_BOTTLES_SUCCESS: 'GET_SQUEEZE_BOTTLES_SUCCESS',
    GET_SQUEEZE_BOTTLES_FAILED: 'GET_SQUEEZE_BOTTLES_FAILED',

    GET_DISPENSERS_START: 'GET_DISPENSERS_START',
    GET_DISPENSERS_SUCCESS: 'GET_DISPENSERS_SUCCESS',
    GET_DISPENSERS_FAILED: 'GET_DISPENSERS_FAILED',

    GET_ARAMARK_PROFIT_CENTERS_START: 'GET_ARAMARK_PROFIT_CENTERS_START',
    GET_ARAMARK_PROFIT_CENTERS_SUCCESS: 'GET_ARAMARK_PROFIT_CENTERS_SUCCESS',
    GET_ARAMARK_PROFIT_CENTERS_FAILED: 'GET_ARAMARK_PROFIT_CENTERS_FAILED',
};
